import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import "./assets/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./sharedComponents/Header";
import Sidebar from "./sharedComponents/Sidebar";
import Login from "./pages/Login";
import DuaSunnat from "./pages/DuaSunnat";
import HifzESurah from "./pages/HifzESurah";
import Masaail from "./pages/Masaail";
import Namaaz from "./pages/Namaaz";
import NoraaniQaaidah from "./pages/NoraaniQaaidah";
import Seerat from "./pages/Seerat";
import UmrahHajj from "./pages/UmrahHajj";
import Aqaaid from "./pages/Aqaaid";
import PushNotification from "./pages/PushNotification";
import GoogleAnalytics from "./pages/GoogleAnalytics";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Ramzan from "./pages/Ramzan";
import FirstYear from "./pages/SyllabusSixtyMin/FirstYear";
import SecondYear from "./pages/SyllabusSixtyMin/SecondYear";
import ThirdYear from "./pages/SyllabusSixtyMin/ThirdYear";
import SixthYear from "./pages/SyllabusSixtyMin/SixthYear";
import FourthYear from "./pages/SyllabusSixtyMin/FourthYear";
import FifthYear from "./pages/SyllabusSixtyMin/FifthYear";
import ShortCourse from "./pages/ShortCourse";
import QKEleven from "./pages/QuraanKareem/QKEleven";
import QKThirteen from "./pages/QuraanKareem/QKThirteen";
import QKFifteen from "./pages/QuraanKareem/QKFifteen";
import Surah_page from "./pages/QuraanKareem/Surah_page";
import Reciter from "./pages/QuraanKareem/Reciter";
// import Surah from "./pages/Surah";
import Surah from "./pages/QuraanKareem/Surah";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  let currentRoute = location.pathname;
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );
  // let logedIn = localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false

  useEffect(() => {
    if (!logedIn) {
      navigate("/");
    } else {
      navigate("/Dashboard");
    }
  }, [logedIn]);
  useEffect(() => {
    if (!logedIn && currentRoute != "/") {
      navigate("/");
    } else {
      navigate(currentRoute);
    }
  }, [currentRoute]);
  return (
    <div className="App">
       <ToastContainer/>
      {currentRoute !== "/" && (
        <>
          <Header setLoggedIn={setLoggedIn}/>
          <Sidebar />
        </>
      )}

      <Routes>
        <Route path="/" element={<Login setLoggedIn={setLoggedIn}  />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="DuaSunnat" element={<DuaSunnat />} />
        <Route path="HifzESurah" element={<HifzESurah />} />
        <Route path="Masaail" element={<Masaail />} />
        <Route path="Ramzan" element={<Ramzan />} />
        <Route path="Namaaz" element={<Namaaz />} />
        <Route path="NoraaniQaaidah" element={<NoraaniQaaidah />} />
        <Route path="Seerat" element={ <Seerat />} />
        <Route path="UmrahHajj" element={<UmrahHajj />} />
        <Route path="Aqaaid" element={<Aqaaid />} />
        <Route path="PushNotification" element={<PushNotification />} />
        <Route path="GoogleAnalytics" element={<GoogleAnalytics />} />
        <Route path="FirstYear" element={<FirstYear />} />
        <Route path="SecondYear" element={<SecondYear />} />
        <Route path="ThirdYear" element={<ThirdYear />} />
        <Route path="FourthYear" element={<FourthYear />} />
        <Route path="FifthYear" element={<FifthYear />} />
        <Route path="SixthYear" element={<SixthYear />} />
        <Route path="ShortCourse" element={<ShortCourse />} />
        <Route path="QKEleven" element={<QKEleven />} />
        <Route path="Surah" element={<Surah />} />
        <Route path="QKThirteen" element={<QKThirteen />} />
        <Route path="QKFifteen" element={<QKFifteen />} />
        <Route path="Surah_page" element={<Surah_page />} />
        <Route path="Reciter" element={<Reciter />} />
      </Routes>
    </div>
  );
}
// test
//test2
export default App;
