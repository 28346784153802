import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules

import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";
// import ApiConfig from "../api/ApiConfig";
import ApiConfig from "../../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  multipartPutCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponsePlane,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "../Pagination";
import FormLoader from "../FormLoader";
import HifzESurah from "../HifzESurah";
import NoraaniQaaidah from "../NoraaniQaaidah";
import DuaSunnat from "../DuaSunnat";
import Aqaaid from "../Aqaaid";
import Namaaz from "../Namaaz";
import Masaail from "../Masaail";
import Seerat from "../Seerat";

// import {
//   DeleteCallWithErrorResponse,
//   multipartPostCallWithErrorResponse,

//   PostCallWithAuthTokenWithErrorResponse,
//   putMultipartWithAuthCallWithErrorResponse,
//   simpleGetCallWithErrorResponse,
// } from "../api/ApiServices";

const Surah_page = () => {
  let juz_id;
  let textType;
  let juzName;

  if (typeof window !== 'undefined') {
    juz_id = localStorage.getItem('juz_id');
    textType = localStorage.getItem('textType');
    juzName = localStorage.getItem('juzName');
  }

  console.log("TExttype", textType);


  console.log("juz_id", juz_id);
  console.log("juz_id typeof", typeof (juz_id));
  const [juzList, setJuzList] = useState([]);
  console.log("juzListPage", juzList);
  const [surahList, setSurahList] = useState([]);
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (set) => {
    if (set === "edit") {
      setIsEdit("");
    }
    setShow(true);
    if (set === "add") {
      setIsEdit("");
      setAddjuz({
        juzId: Number(juz_id),
        textType: Number(textType),
        pageNo: 0,
        juzPageNo: 0,
        lines: [
          {
            lineNo: 0,
            surahId: 0,
            texts: [
              {
                text: "",
                waqf: "",
                verseNo: 0,
                textSequence: 1,
                verseNoArabic: "",
              },
            ],
          },
        ],
      })
    }
    setShow(true);
  };



  const [eventKey, setEventKey] = useState(1);
  const [eventtitle, setEventtitle] = useState("Page");

  const [juzPageNumber, setJuzPageNumber] = useState();

  const [view, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [Delete, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [pageline, setPageline] = useState(1);
  const [pageInner, setPageInner] = useState(1);
  const [isEdit, setIsEdit] = useState("");

  const [validated, setValidated] = useState(false);
  const [viewJuz, setViewJuz] = useState([]);
  console.log("viewJuz", viewJuz);

  console.log("isEdit", isEdit);

  const [lineIdsArray, setLineIdsArray] = useState([]);
  console.log("lineIdsArray", lineIdsArray);

  const [message, setMessage] = useState("");
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10); // Number of items per page

  // Assuming juzList is the complete list of data received from the server
  const paginatedData = juzList ? juzList.slice(currentPage * perPage, (currentPage + 1) * perPage) : [];

  const pageCount = Math.ceil(juzList?.length / perPage);


  // const [currentPage, setCurrentPage] = useState(0);

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  // const pageCount = Math.ceil(juzList?.length / 5);

  const [addjuz, setAddjuz] = useState({
    juzId: Number(juz_id),
    textType: Number(textType),
    pageNo: 0,
    juzPageNo: 0,
    lines: [
      {
        lineNo: 0,
        surahId: 0,
        texts: [
          {
            text: "",
            waqf: "",
            verseNo: 0,
            textSequence: 1,
            verseNoArabic: "",
          },
        ],
      },
    ],
  });
  console.log("addjuz,", addjuz)
  useEffect(() => {
    console.log('Updating state with localStorage values');
    setAddjuz((prevAddjuz) => ({
      ...prevAddjuz,
      juzId: Number(juz_id),
      textType: Number(textType),
    }));
  }, [juz_id, textType]);

  console.log("addjuz Object", addjuz);

  // Reciter List API
  const juzListApi = () => {
    simpleGetCallWithErrorResponse(
      ApiConfig.PAGE_LIST_BY_JUZ + `juzId=${juz_id}&textType=${textType}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setJuzList(res.json.data);
          // setLsatSabaq(res.json?.data.allSabaq.length);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const surahListApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_ALL_SURAH)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setSurahList(res.json.data);

          // setLsatSabaq(res.json?.data.allSabaq.length);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  useEffect(() => {
    juzListApi();
    surahListApi();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      console.log('addjuz state before API request: 1', addjuz);
      setValidated(true);
      addJuzPage(event);
    }

  };


  const addJuzPage = (event) => {
    event.preventDefault();
    handleClose();

    setAddjuz((prevAddJuz) => {
      const dynamicJuzPageNo = prevAddJuz?.juzPageNo;
      const dynamicJuzId = prevAddJuz.juzId || 0;
      const dynamicTextType = prevAddJuz.textType || 0;

      // Store values in separate variables
      const juzIdToUpdate = dynamicJuzId;
      const textTypeToUpdate = dynamicTextType;


      // Add prevPageDetails to the current addJuz state
      const updatedAddJuz = {
        ...prevAddJuz,
        juzId: juzIdToUpdate,
        textType: textTypeToUpdate,
        prevPageDetails: {
          juzPageNo: dynamicJuzPageNo,
          lineIds: lineIdsArray || [],
        },
      };

      console.log('updatedAddJuz', updatedAddJuz);

      isEdit === 'edit'
        ? putMultipartWithAuthCallWithErrorResponsePlane(
          ApiConfig.UPDATE_JUZ_PAGE,
          JSON.stringify(updatedAddJuz)
        )
          .then((res) => {
            console.log('Update JUZ PAGE API', res);
            if (res.json && res.json?.success === true) {
              ToastMsg('success', res.json.message);
              juzListApi();
            } else {
              ToastMsg('error', res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);
            ToastMsg('error', 'something went wrong');
          })
        : PostCallWithAuthTokenWithErrorResponse(
          ApiConfig.ADD_JUZ_PAGE,
          JSON.stringify(addjuz)
        )
          .then((res) => {
            console.log('ADD JUZ PAGE API', res);
            if (res.json && res.json?.success === true) {
              ToastMsg('success', res.json.message);
              juzListApi();
            } else {
              ToastMsg('error', res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);
            ToastMsg('error', 'something went wrong');
          });

      return updatedAddJuz;
    });
  };



  const editPageJuz = (juz_page_no) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_EDIT_PAGE +
      `juzId=${juz_id}&juzPgNo=${juz_page_no}&textType=${textType}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          let responseData = res.json.data;

          setAddjuz({
            ...addjuz,
            juzPageNo: juz_page_no || 0,
            pageNo: responseData[0]?.texts[0]?.PageNo || 0,
            lines: responseData.map((lineData) => ({
              lineNo: lineData?.line_no || 0,
              surahId: lineData?.texts[0]?.surahId || 0,
              texts: lineData?.texts.map((textData) => ({
                text: textData?.text || "",
                waqf: textData?.textwaqf || "",
                verseNo: textData?.verseNo || 0,
                textSequence: textData?.textSeq || 1,
                verseNoArabic: textData?.verseNoArabic || "",
              })),
            })),
          });

          const newLineIdsArray = res?.json?.data?.flatMap((item) =>
            item.texts?.map((text) => text.lineId)
          );

          // Removing duplicate lineIds
          const uniqueLineIdsArray = [...new Set(newLineIdsArray)];

          // Updating the state with unique lineIds
          setLineIdsArray(uniqueLineIdsArray);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const deleteJuzPage = () => {
    const body = {
      juzPageNo: juzPageNumber,
      lineIds: lineIdsArray
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_JUZ_PAGE,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          // setSabaqList(res.json.data);
          ToastMsg("success", res.json.message);
          juzListApi();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  // //view the
  // const viewPageJuz = (juz_page_no) => {
  //   simpleGetCallWithErrorResponse(
  //     ApiConfig.GET_EDIT_PAGE +
  //     `juzId=${juz_id}&juzPgNo=${juz_page_no}&textType=${textType}`
  //   )
  //     .then((res) => {
  //       setLoader(false);

  //       if (res.json && res.json?.success === true) {
  //         // setViewDetails(res.json.data);
  //         setViewJuz(res.json.data);
  //         console.log("View API>> :", res);
  //         handleViewShow();

  //         const newLineIdsArray = res?.json?.data?.flatMap((item) =>
  //           item.texts?.map((text) => text.lineId)
  //         );

  //         // Removing duplicate lineIds
  //         const uniqueLineIdsArray = [...new Set(newLineIdsArray)];
  //         console.log("uniqueLineIdsArray", uniqueLineIdsArray);

  //         // Updating the state with unique lineIds
  //         setLineIdsArray(uniqueLineIdsArray);
  //       } else {
  //         ToastMsg("error", res.json.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);

  //       ToastMsg("error", "something went wrong");
  //     });
  // };

  const viewPageJuz = (juz_page_no) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_EDIT_PAGE +
      `juzId=${juz_id}&juzPgNo=${juz_page_no}&textType=${textType}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewJuz(res.json.data);
          console.log("View API>> :", res);



          handleViewShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };


  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Quraan-e-Kareem ({textType} LINE)</label>
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="heading-btn-main">
            <div className="heading-common">
              <p> {juzName}</p>
            </div>
            <div className="add-btn">
              <button
                onClick={() => {
                  // handleShow();
                  handleShow("add");
                  setIsEdit("Add");
                }}
              >
                Add Page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="table-wrapper">
            <table className="display table table-lg table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th style={{ textAlign: "left" }}>Headings</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {paginatedData.length > 0
                  ? paginatedData.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>
                          Page {item.juz_page_no}
                        </td>
                        <td className="action">
                          <Link
                            className="icon edit"
                            href="/FirstYear"
                            onClick={() => {
                              // handleShow();
                              handleShow("edit");
                              setIsEdit("edit");
                              // setIsEdit("Add");
                              editPageJuz(item.juz_page_no);
                            }}
                          >
                            <img src={ic_edit} alt="" />
                          </Link>
                          <Link
                            className="icon view"
                            href="/FirstYear"
                            onClick={() => {
                              handleViewShow();
                              viewPageJuz(item.juz_page_no);
                            }}
                          >
                            <img src={ic_eyes} alt="" />
                          </Link>
                          <Link
                            className="icon delete"
                            href="/FirstYear"
                            // onClick={handleDeleteShow}
                            onClick={() => {
                              handleDeleteShow();
                              editPageJuz(item.juz_page_no);
                              setJuzPageNumber(item.juz_page_no);
                            }}
                          >
                            <img src={ic_delete} alt="" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                  : null}
                {/* <tr>
                  <td>2</td>
                  <td style={{ textAlign: "left" }}>Page 2</td>
                  <td className="action">
                    <Link
                      className="icon edit"
                      href="/FirstYear"
                      onClick={handleShow}
                    >
                      <img src={ic_edit} alt="" />
                    </Link>
                    <Link
                      className="icon view"
                      href="/FirstYear"
                      onClick={handleViewShow}
                    >
                      <img src={ic_eyes} alt="" />
                    </Link>
                    <Link
                      className="icon delete"
                      href="/FirstYear"
                      onClick={handleDeleteShow}
                    >
                      <img src={ic_delete} alt="" />
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td style={{ textAlign: "left" }}>Page 3</td>
                  <td className="action">
                    <Link
                      className="icon edit"
                      href="/FirstYear"
                      onClick={handleShow}
                    >
                      <img src={ic_edit} alt="" />
                    </Link>
                    <Link
                      className="icon view"
                      href="/FirstYear"
                      onClick={handleViewShow}
                    >
                      <img src={ic_eyes} alt="" />
                    </Link>
                    <Link
                      className="icon delete"
                      href="/FirstYear"
                      onClick={handleDeleteShow}
                    >
                      <img src={ic_delete} alt="" />
                    </Link>
                  </td>
                </tr> */}
              </tbody>
            </table>
            {paginatedData && paginatedData.length > 0 ? (
              <Pagination
                changePage={changePage}
                pageCount={pageCount}
                currentPage={currentPage}
              />
            ) : (
              <div>
                <h5 style={{ color: "red", textAlign: "center" }}>
                  {" "}
                  Records Not Found
                </h5>
              </div>
            )}
            {/* <div className="row mt-4">
              <div className="col-lg-12">
                <ul
                  className="paginationBttns"
                  role="navigation"
                  aria-label="Pagination"
                >
                  <li className="previous paginationDisabled">
                    <Link
                      className="previousBttn"
                      // tabIndex={-1}
                      // role="button"
                      // aria-disabled="true"
                      // aria-label="Previous page"
                      // rel="prev"
                    >
                      Previous
                    </Link>
                  </li>
                  <li className="paginationActive">
                    <Link
                      rel="canonical"
                      role="button"
                      tabIndex={-1}
                      aria-label="Page 1 is your current page"
                      aria-current="page"
                    >
                      1
                    </Link>
                  </li>
                  <li>
                    <Link
                      rel="next"
                      role="button"
                      tabIndex={0}
                      aria-label="Page 2"
                    >
                      2
                    </Link>
                  </li>
                  <li>
                    <Link role="button" tabIndex={0} aria-label="Page 3">
                      3
                    </Link>
                  </li>
                  <li className="next">
                    <Link
                      className="nextBttn"
                      tabIndex={0}
                      role="button"
                      aria-disabled="false"
                      aria-label="Next page"
                      rel="next"
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* ======================= START ADD ========================== */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        isEdit={isEdit}
        // show={show}
        // onHide={() => handleClose()}
        size="lg"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit === "edit" ? "Update Page" : "Add Page"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              {/* <div className="col-md-6 mb-3">
                <Form.Label>Para Page No.</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={addjuz?.juzPageNo || null}
                  onChange={(e) => {
                    setAddjuz({
                      ...addjuz,
                      juzPageNo: Number(e.target.value),
                    });
                  }}
                  name="parano"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div> */}

              <div className="col-md-6 mb-3">
                <Form.Label>Para Page No.</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={addjuz?.juzPageNo || ""}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    // Use regex to allow only numeric input
                    const numericInput = inputText.replace(/\D/g, "");

                    setAddjuz({
                      ...addjuz,
                      juzPageNo: numericInput,
                    });
                  }}
                  name="parano"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid numeric Para Page No.
                </Form.Control.Feedback>
              </div>


              <div className="col-md-6 mb-3">
                <Form.Label>Quran Page No.</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={addjuz?.pageNo || null}
                  onChange={(e) => {
                    setAddjuz({
                      ...addjuz,
                      pageNo: Number(e.target.value),
                    });
                  }}
                  name="quranpage"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Quran Page No.
                </Form.Control.Feedback>
              </div>
              {addjuz.lines &&
                addjuz.lines.map((element, lineIndex) => {
                  return (
                    <div className="col-md-12">
                      <div className="outer_wrapper">
                        <div className="row">
                          <div className="col-md-1 g-0 mt-2 ms-3">
                            <Form.Label>Line {lineIndex + 1} </Form.Label>
                          </div>
                          <div className="col-md-5 mb-3">
                            <Form.Control
                              required
                              type="text"
                              name="quranpage"
                              value={element.lineNo}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const isNumeric = /^[0-9]*$/.test(inputValue);

                                if (isNumeric) {
                                  const updatedAddjuz = { ...addjuz };
                                  updatedAddjuz.lines[lineIndex].lineNo = inputValue !== '' ? Number(inputValue) : ''; // Convert to number if not empty, otherwise keep it as an empty string
                                  setAddjuz(updatedAddjuz);
                                }
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Line
                            </Form.Control.Feedback>
                          </div>
                          <div className="col-md-5 mb-3">
                            {/* <Form.Label>Line</Form.Label> */}

                            <Form.Select
                              required
                              type="text"
                              name="quranpage"
                              value={element.surahId || ""}
                              onChange={(e) => {
                                const selectedSurahId = Number(e.target.value);
                                setAddjuz((prevAddjuz) => ({
                                  ...prevAddjuz,
                                  lines: prevAddjuz.lines.map(
                                    (line, mapIndex) =>
                                      mapIndex === lineIndex
                                        ? { ...line, surahId: selectedSurahId }
                                        : line
                                  ),
                                }));
                              }}
                            >
                              <option value="">Select Surah</option>
                              {surahList.length > 0 &&
                                surahList.map((item) => (
                                  <option
                                    key={item.surah_id}
                                    value={item.surah_id}
                                  >
                                    {item.surah_name}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please Select Surah
                            </Form.Control.Feedback>
                          </div>

                          <div className="col-md-12">
                            {element?.texts &&
                              element?.texts.map((item, textIndex) => {
                                return (
                                  <div className="inner_wrapper my-2">
                                    <div className="row">
                                      <div className="col-md-12 mb-3">
                                        <Form.Label>Arabic Text {textIndex + 1} </Form.Label>
                                        <Form.Control
                                          required
                                          as="textarea"
                                          rows={2}
                                          name="sabaqDesc"
                                          value={
                                            addjuz.lines[lineIndex].texts[
                                              textIndex
                                            ].text
                                          }
                                          onChange={(e) => {
                                            const newText = e.target.value;
                                            setAddjuz((prevAddjuz) => ({
                                              ...prevAddjuz,
                                              lines: prevAddjuz.lines.map(
                                                (line, mapIndex) =>
                                                  mapIndex === lineIndex
                                                    ? {
                                                      ...line,
                                                      texts: line.texts.map(
                                                        (text, tIndex) =>
                                                          tIndex === textIndex
                                                            ? {
                                                              ...text,
                                                              text: newText,
                                                            }
                                                            : text
                                                      ),
                                                    }
                                                    : line
                                              ),
                                            }));
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please Enter Tarjama.
                                        </Form.Control.Feedback>
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <Form.Label>Waqf</Form.Label>
                                        <Form.Control
                                          // required
                                          type="text"
                                          name="quranpage"
                                          value={
                                            addjuz.lines[lineIndex].texts[
                                              textIndex
                                            ].waqf
                                          }
                                          onChange={(e) => {
                                            const newWaqf = e.target.value;
                                            setAddjuz((prevAddjuz) => ({
                                              ...prevAddjuz,
                                              lines: prevAddjuz.lines.map(
                                                (line, mapIndex) =>
                                                  mapIndex === lineIndex
                                                    ? {
                                                      ...line,
                                                      texts: line.texts.map(
                                                        (text, tIndex) =>
                                                          tIndex === textIndex
                                                            ? {
                                                              ...text,
                                                              waqf: newWaqf,
                                                            }
                                                            : text
                                                      ),
                                                    }
                                                    : line
                                              ),
                                            }));
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please Enter Line
                                        </Form.Control.Feedback>
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <Form.Label>Ayat English</Form.Label>
                                        <Form.Control
                                          // required
                                          type="text"
                                          name="quranpage"
                                          value={
                                            addjuz.lines[lineIndex].texts[
                                              textIndex
                                            ].verseNo
                                          }
                                          onChange={(e) => {
                                            const newVerseNo = e.target.value;
                                            setAddjuz((prevAddjuz) => ({
                                              ...prevAddjuz,
                                              lines: prevAddjuz.lines.map(
                                                (line, mapIndex) =>
                                                  mapIndex === lineIndex
                                                    ? {
                                                      ...line,
                                                      texts: line.texts.map(
                                                        (text, tIndex) =>
                                                          tIndex === textIndex
                                                            ? {
                                                              ...text,
                                                              verseNo:
                                                                newVerseNo,
                                                            }
                                                            : text
                                                      ),
                                                    }
                                                    : line
                                              ),
                                            }));
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please Enter Ayat English
                                        </Form.Control.Feedback>
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <Form.Label>Ayat Arabic</Form.Label>
                                        <Form.Control
                                          // required
                                          type="text"
                                          name="quranpage"
                                          value={
                                            addjuz.lines[lineIndex].texts[
                                              textIndex
                                            ].verseNoArabic
                                          }
                                          onChange={(e) => {
                                            const newVerseNoArabic =
                                              e.target.value;
                                            setAddjuz((prevAddjuz) => ({
                                              ...prevAddjuz,
                                              lines: prevAddjuz.lines.map(
                                                (line, mapIndex) =>
                                                  mapIndex === lineIndex
                                                    ? {
                                                      ...line,
                                                      texts: line.texts.map(
                                                        (text, tIndex) =>
                                                          tIndex === textIndex
                                                            ? {
                                                              ...text,
                                                              verseNoArabic:
                                                                newVerseNoArabic,
                                                            }
                                                            : text
                                                      ),
                                                    }
                                                    : line
                                              ),
                                            }));
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please enter a valid Ayat Arabic with a maximum length of 4 digits.
                                        </Form.Control.Feedback>
                                      </div>
                                    </div>

                                    <div className="modal_btn_wrapper">
                                      <div className="plus_minus">
                                        <button
                                          type="button"
                                          className="pm_btn"
                                          onClick={() => {
                                            setAddjuz((prevAddjuz) => ({
                                              ...prevAddjuz,
                                              lines: prevAddjuz.lines.map((line, innerIdx) =>
                                                innerIdx === lineIndex
                                                  ? {
                                                    ...line,
                                                    texts: line.texts.concat({
                                                      text: "",
                                                      waqf: "",
                                                      verseNo: 0,
                                                      textSequence: line.texts.length + 1,
                                                      verseNoArabic: "",
                                                    }),
                                                  }
                                                  : line
                                              ),
                                            }));
                                          }}
                                        >
                                          +
                                        </button>

                                        <button
                                          type="button"
                                          className={
                                            addjuz?.lines[0]?.texts?.length > 1
                                              ? "btn btn-danger my"
                                              : "d-none"
                                          }
                                          disabled={addjuz.lines[0]?.texts?.length > 1 ? false : true}
                                          onClick={() => {
                                            if (addjuz.lines[0]?.texts?.length > 1) {
                                              setAddjuz((prevAddjuz) => ({
                                                ...prevAddjuz,
                                                lines: prevAddjuz.lines.map((line, innerIdx) =>
                                                  innerIdx === lineIndex
                                                    ? {
                                                      ...line,
                                                      texts: line.texts.filter(
                                                        (text, tIndex) => tIndex !== textIndex
                                                      ),
                                                    }
                                                    : line
                                                ),
                                              }));
                                            }
                                          }}
                                        >
                                          -
                                        </button>

                                        {/* <button
                                          type="button"
                                          className="pm_btn"
                                          onClick={() => {
                                            setAddjuz((prevAddjuz) => ({
                                              ...prevAddjuz,
                                              lines: prevAddjuz.lines.map((line, innerIdx) =>
                                                innerIdx === lineIndex
                                                  ? {
                                                    ...line,
                                                    texts: line.texts.concat({
                                                      text: "",
                                                      waqf: "",
                                                      verseNo: 0,
                                                      textSequence: line.texts.length + 1,
                                                      verseNoArabic: "",
                                                    }),
                                                  }
                                                  : line
                                              ),
                                            }));
                                          }}
                                        >
                                          +
                                        </button> */}



                                        {/* End */}
                                        {/* <button
                                          type="button"
                                          className={
                                            addjuz?.lines[0]?.texts?.length > 1
                                              ? "btn btn-danger my"
                                              : "d-none"
                                          }
                                          disabled={addjuz.lines[0]?.texts?.length > 1 ? false : true}
                                          onClick={() => {
                                            if (addjuz.lines[0]?.texts?.length > 1) {
                                              setAddjuz((prevAddjuz) => {
                                                const updatedTexts = prevAddjuz.lines[0].texts
                                                  .filter((text, index) => index !== textIndex)
                                                  .map((text, index) => ({
                                                    ...text,
                                                    textSequence: index + 1, // Set textSequence based on the index after deletion
                                                  }));

                                                return {
                                                  ...prevAddjuz,
                                                  lines: [
                                                    {
                                                      ...prevAddjuz.lines[0],
                                                      texts: updatedTexts,
                                                    },
                                                  ],
                                                };
                                              });
                                            }
                                          }}
                                        >
                                          -
                                        </button> */}



                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            <div className="modal_btn_wrapper mt-2">
                              <div className="add_delete">
                                <button
                                  className="btn btn-primary m-2"
                                  type="button"
                                  onClick={() => {
                                    let tempData = [
                                      ...addjuz.lines,
                                      {
                                        lineNo: 0,
                                        surahId: 0,
                                        texts: [
                                          {
                                            text: "",
                                            waqf: "",
                                            verseNo: 0,
                                            textSequence: 1,
                                            verseNoArabic: "",
                                          },
                                        ],
                                      },
                                    ];
                                    setAddjuz({
                                      ...addjuz,
                                      lines: tempData,
                                    });

                                    // setPageline(pageline + 1)
                                  }}
                                >
                                  {" "}
                                  Add New Line +{" "}
                                </button>
                                <button
                                  className={
                                    addjuz.lines.length > 1
                                      ? "btn btn-danger m-2"
                                      : "d-none"
                                  }
                                  type="button"
                                  disabled={
                                    addjuz.lines.length > 1 ? false : true
                                  }
                                  onClick={() => {
                                    if (addjuz.lines.length > 1) {
                                      setAddjuz((prevAddjuz) => ({
                                        ...prevAddjuz,
                                        lines: prevAddjuz.lines.slice(0, -1),
                                      }));
                                    }
                                  }}
                                >
                                  Delete Line -
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* start  */}

              {/* end */}

              {/* <div className="modal_btn_wrapper">
                <div className="add_delete">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setPageline(pageline + 1)}
                  >
                    Add New Line +
                  </button>
                </div>
              </div> */}
            </div>
            <div class="buttons-modal border-top pt-3 text-end ">
              <button class="me-2" type="submit">
                {isEdit === "edit" ? "Update" : "Add"}
              </button>
              <a class=" bg-danger" href="#" onClick={() => handleClose()}>
                Cancel
              </a>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= START VIEW ========================== */}
      <Modal
        show={view}
        onHide={() => handleViewClose()}
        size="lg"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{eventtitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Para Page No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewJuz[0]?.line_no}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Quran Page No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewJuz[0]?.texts[0]?.PageNo}
                  </label>
                </div>
              </div>

              {/* {
                            item.texts && item.texts.length > 0 ?
                              item.texts.map((textItem, index) => {
                                return (
                                  <>
                                    <div className="row">

                                      <div className="col-md-12 card m-2">
                                        
                                      </div>
                                    </div>
                                  </>
                                )
                              }) : null
                          } */}
              <div className="col-md-12">
                {viewJuz && viewJuz.length > 0
                  ? viewJuz.map((item, index) => {
                    console.log("item", item);
                    return (
                      <div className="outer_wrapper form_color">
                        {item.texts && item.texts.length > 0
                          ? item.texts.map((textItem, index) => {
                            return (
                              <div className="row">
                                <div className="col-md-1 mt-1 mb-4">
                                  <div>
                                    <label htmlFor="" className="key">
                                      Line:
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-5 mb-3">
                                  <div>
                                    <label htmlFor="" className="value">
                                      {textItem?.lineNo}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-1 mb-4">
                                  <div>
                                    <label htmlFor="" className="key">
                                      Surah
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-5 mb-3">
                                  <div>
                                    <label htmlFor="" className="value">
                                      {textItem.surahId}
                                    </label>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="card m-3">
                                    <div className="col-md-12 mb-4">
                                      <div className="mb-2">
                                        <label htmlFor="" className="key">
                                          Arabic Text
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="value">
                                          {/* بِسْمِ اللّٰہِ الرَّحْمٰنِ الرَّحِیْمِ */}
                                          {textItem.text}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                      <div className="mb-2">
                                        <label htmlFor="" className="key">
                                          Ayat English
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="value">
                                          {/* Ayat English */}
                                          {textItem.verseNo}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                      <div className="mb-2">
                                        <label htmlFor="" className="key">
                                          Ayat Arabic
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="value">
                                          {/* بِسْمِ اللّٰہِ الرَّحْمٰنِ الرَّحِیْمِ? */}
                                          {textItem.verseNoArabic}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-1">
                                      <div className="mb-2">
                                        <label htmlFor="" className="key">
                                          Waqf
                                        </label>
                                      </div>
                                      <div>
                                        <label htmlFor="" className="value">
                                          {/* ط */}
                                          {textItem.textwaqf}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      {/* ======================= START DELETE ========================== */}
      <Modal
        show={Delete}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-mashq-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              onClick={() => {
                handleDeleteClose();
                deleteJuzPage();
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}
    </main>
  );
};

export default Surah_page;

