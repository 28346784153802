import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules

import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";
// import ApiConfig from "../api/ApiConfig";
import ApiConfig from "../../api/ApiConfig";
import {
    DeleteCallWithErrorResponse,
    PostCallWithErrorResponse,
    putMultipartWithAuthCallWithErrorResponse,
    putMultipartWithAuthCallWithErrorResponsePlane,
    simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "../Pagination";
import FormLoader from "../FormLoader";
import HifzESurah from "../HifzESurah";
import NoraaniQaaidah from "../NoraaniQaaidah";
import DuaSunnat from "../DuaSunnat";
import Aqaaid from "../Aqaaid";
import Namaaz from "../Namaaz";
import Masaail from "../Masaail";
import Seerat from "../Seerat";

// import {
//   DeleteCallWithErrorResponse,
//   PostCallWithErrorResponse,

//   PostCallWithAuthTokenWithErrorResponse,
//   putMultipartWithAuthCallWithErrorResponse,
//   simpleGetCallWithErrorResponse,
// } from "../api/ApiServices";

const Quraan = ({ id }) => {
    const { sidebar, adminData } = useContext(AppContext);
    const [loader, setLoader] = useState(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [isEdit, setIsEdit] = useState(false);

    const handleShowAdd = () => {
        setIsEdit(false);
        setShow(true);
    };


    const [activeTab, setActiveTab] = useState("1");

    const [eventKey, setEventKey] = useState(1);
    const [eventtitle, setEventtitle] = useState("Hifze Hadees");
    const [addQuraan, setAddQuraan] = useState({
        // juzName
        juz_name: "",
        juz_name_arabic: "",
    })

    const [Delete, setDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleDeleteClose = () => {
        setDelete(false);
        setSelectedItem(null);
    };
    const handleDeleteShow = (item) => {
        setSelectedItem(item);
        setDelete(true);
    };

    const [juzList, setJuzList] = useState([]);
    const [surahList, setSurahList] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSurah, setCurrentPageSurah] = useState(0);

    const changePage = ({ selected }) => {
        setCurrentPage(selected);
    };
    const pageCount = Math.ceil(juzList?.length / 15);

    const changePageSurah = ({ selected }) => {
        setCurrentPageSurah(selected);
    };
    const pageCountSurah = Math.ceil(juzList?.length / 15);



    const handleShowEdit = (data) => {
        console.log("EditeData>>>", data)
        if (data.juz_id) {
            setAddQuraan({
                ...addQuraan,
                juz_id: data.juz_id,
                juz_name: data.juz_name,
                juz_name_arabic: data.juz_name_arabic,
            })
        }
        setIsEdit(true);
        setShow(true);
    };



    // Para / Juz List API
    const juzListApi = () => {
        simpleGetCallWithErrorResponse(ApiConfig.GET_ALL_JUZ + `categoryId=${id}`)
            .then((res) => {
                console.log("juz List", res);
                setLoader(false);

                if (res.json && res.json?.success === true) {
                    setJuzList(res.json.data);

                    // setLsatSabaq(res.json?.data.allSabaq.length);
                } else {
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                setLoader(false);

                // ToastMsg("error", "something went wrong");
            });
    };


    useEffect(() => {
        juzListApi();
    }, []);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            addQuaaranData();
        }

        // setValidated(true);
    };

    const addQuaaranData = () => {

        let body = {
            juzName: addQuraan?.juz_name,
            juzNameArabic: addQuraan?.juz_name_arabic,
            categoryId: Number(id),
            juzId: addQuraan?.juz_id
        }
        console.log("AddQuaaran Data>>>>>", body)
        isEdit?putMultipartWithAuthCallWithErrorResponsePlane(ApiConfig.UPDATESURAH, JSON.stringify(body))  
        .then((res) => {
         console.log("addQuranData", res)
         if (res.json && res.json?.success === true) {
           ToastMsg("success", res.json.message);
           handleClose()
           juzListApi();
         } else {
           ToastMsg("error", res.json.message);
         }
       })
       .catch((err) => {
         console.log("err", err)
       }):
        PostCallWithErrorResponse(ApiConfig.ADD_QUARAN_DATA, JSON.stringify(body))
            .then((res) => {
                console.log("addQuranData", res)
                if (res.json && res.json?.success === true) {
                    ToastMsg("success", res.json.message);
                    juzListApi();
                    handleClose()
                    setAddQuraan("")
                }else{
                    ToastMsg("error", res.json.message);
                }
            })
            .catch((err) => {
                console.log("err", err)
                ToastMsg("error", "something went wrong");

            })
    }

    const onDeleteJuz = (e) => {

        e.preventDefault()

        DeleteCallWithErrorResponse(ApiConfig.DELETE_JUZ+selectedItem?.juz_id)
            .then((res) => {
                console.log("res", res)
                if(res.json?.success === true){
                    ToastMsg("success", res?.json?.message)
                    juzListApi()
                    handleDeleteClose()
                }else{
                    ToastMsg("error", res?.json?.message)
                    handleDeleteClose()
                }
            })
            .catch((res) => {
                console.log("res", res)
            })
    }

    return (
        <>
            {/* // <main
    //   id="cx-main"
    //   className={sidebar ? "admin-main" : "cx-active admin-main"}
    // >
      {/* <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">
            Quraan-e-Kareem <span>(11 Line)</span>
          </label>
          <br />
        </div>
      </div> */}

            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Nav
                    variant="pills"
                    className="flex-row justify-content-between align-items-center"
                    activeKey={activeTab}
                    onSelect={(selectedKey) => setActiveTab(selectedKey)}
                >
                    <div className="d-flex">
                        <Nav.Item>
                            <Nav.Link eventKey="1">Juz</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
              <Nav.Link eventKey="2">Surah</Nav.Link>
            </Nav.Item> */}
                    </div>
                    <div className="add-btn d-flex mb-0">
                        <button onClick={handleShowAdd}>
                          
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
                            </svg>
                            {"Add Juz"}
                        </button>
                    </div>
                </Nav>
                {/* <pre>{JSON.stringify(juzList)}</pre> */}
                <Tab.Content className="Syllabus_Custom_Tabs">
                    {/*   ====== Quran ===== */}
                    <Tab.Pane eventKey="1">
                        <div className="row jd-main">
                            <main id="" className="admin-main">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-wrapper mt-4">
                                            <table className="display table table-lg table-striped table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th style={{ textAlign: "left" }}>Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {juzList.length > 0
                                                        ? juzList.map((item, index) => {
                                                            return (
                                                                <tr key={item.juz_id}>
                                                                    <td>{index+1}</td>
                                                                    <td
                                                                        onClick={() =>
                                                                            localStorage.setItem(
                                                                                "juz_id",
                                                                                item.juz_id
                                                                            )
                                                                        }
                                                                        style={{ textAlign: "left" }}
                                                                    >
                                                                        {" "}
                                                                        {/* <Link to="/Surah_page" >
                                        {item.juz_name} ({item.juz_name_arabic})
                                      </Link> */}
                                                                        <Link
                                                                            to="/Surah_page"
                                                                            onClick={() =>
                                                                                localStorage.setItem(
                                                                                    "juzName",
                                                                                    item.juz_name
                                                                                )
                                                                            }
                                                                        >
                                                                            {item.juz_name} ({item.juz_name_arabic})
                                                                        </Link>
                                                                    </td>
                                                                    <td className="action">
                                                                        <Link
                                                                            to="#"
                                                                            className="icon edit"
                                                                            onClick={() => handleShowEdit(item)}
                                                                        >
                                                                            <img src={ic_edit} alt="" />
                                                                        </Link>
                                                                        {/* <Link to="#" className="icon view">
                                        <img src={ic_eyes} alt="" />
                                      </Link> */}
                                                                        <Link
                                                                            to="#"
                                                                            className="icon delete"
                                                                            onClick={() => handleDeleteShow(item)}
                                                                        >
                                                                            <img src={ic_delete} alt="" />
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        : null}
                                                </tbody>
                                            </table>
                                            {juzList && juzList.length > 0 ? (
                                                <Pagination
                                                    changePage={changePage}
                                                    pageCount={pageCount}
                                                    currentPage={currentPage}
                                                />
                                            ) : (
                                                <div>
                                                    <h5 style={{ color: "red", textAlign: "center" }}>
                                                        {" "}
                                                        Records Not Found
                                                    </h5>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </Tab.Pane>

                </Tab.Content>
            </Tab.Container>

            {/* ======================= START ADD ========================== */}
            {activeTab === "1" && (
                <Modal
                    show={show}
                    onHide={() => handleClose()}
                    size="lg"
                    aria-labelledby="contained-modal-subSabaqTitle-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{isEdit ? "Edit Para" : "Add Para"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Form.Label>Para Name English</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="juz_name"
                                        value={addQuraan.juz_name}
                                        onChange={(e) => setAddQuraan({ ...addQuraan, juz_name: e.target.value })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Para Name
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <Form.Label>Para Name Arabic</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="juz_name_arabic"
                                        value={addQuraan.juz_name_arabic}
                                        onChange={(e) => setAddQuraan({ ...addQuraan, juz_name_arabic: e.target.value })}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Para Name
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                            <div className="buttons-modal border-top pt-3 text-end ">
                                <button className="me-2" type="submit">
                                    {isEdit ? "Update" : "Add"}
                                </button>
                                <Link to="#" className=" bg-danger">
                                    Cancel
                                </Link>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}

            {activeTab === "2" && (
                <Modal
                    show={show}
                    onHide={() => handleClose()}
                    size="lg"
                    aria-labelledby="contained-modal-subSabaqTitle-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{isEdit ? "Edit Surah" : "Add Surah"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Form.Label>Surah Name English</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="juz_name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Surah Name
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <Form.Label>Surah Name Arabic</Form.Label>
                                    <Form.Control
                                        required type="text"
                                        name="juz_name_arabic"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Surah Name
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                            <div className="buttons-modal border-top pt-3 text-end ">
                                <button className="me-2" type="submit">
                                    {isEdit ? "Update" : "Add"}
                                </button>
                                <Link to="#" className=" bg-danger">
                                    Cancel
                                </Link>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}

            {/* ======================= END ADD ========================== */}

            {/* ======================= START DELETE ========================== */}
            {activeTab === "1" && selectedItem && (
                <Modal
                    show={Delete}
                    onHide={handleDeleteClose}
                    size="sm"
                    aria-labelledby="contained-modal-subSabaqTitle-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="delete-msg text-center">
                            <label>
                                Are you sure you want to delete this item Juz? (
                                {selectedItem.juz_name})
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-mashq-center">
                        <div className="buttons-modal    ">
                            <button
                                className="me-2 bg-danger"
                                onClick={onDeleteJuz}
                            >
                                Delete
                            </button>
                            <Link to="#" className="" onClick={handleDeleteClose}>
                                Cancel
                            </Link>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
            {activeTab === "2" && selectedItem && (
                <Modal
                    show={Delete}
                    onHide={handleDeleteClose}
                    size="sm"
                    aria-labelledby="contained-modal-subSabaqTitle-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="delete-msg text-center">
                            <label>
                                Are you sure you want to delete this item Surah? (
                                {selectedItem.surah_name})
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-mashq-center">
                        <div className="buttons-modal    ">
                            <button
                                className="me-2 bg-danger"
                                onClick={() => {
                                    handleDeleteClose();
                                }}
                            >
                                Delete
                            </button>
                            <Link to="#" className="" onClick={handleDeleteClose}>
                                Cancel
                            </Link>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
            {/* ======================= END DELETE ========================== */}
            {/* // </main> */}
        </>
    );
};

export default Quraan;
