import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import { PostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
const PushNotification = () => {
  const { sidebar,adminData } = useContext(AppContext);
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);
  const [validated, setValidated] = useState(false);
  const navigate=useNavigate()

  const [notification,setNotication]=useState({
    title: "",
     message: ""
  })
//   {
//     "title": "Zohar Namaaz Reminder",
//     "message": "zohar namaaz in 5 min"
// }

const handleSubmit = (event) => {
  const form = event.currentTarget;

  event.preventDefault();

  if (form.checkValidity() === false) {
    event.stopPropagation();
  } else {
    handleDeleteShow()
  }

  setValidated(true);
};

const addNotification = (e) => {
  e.preventDefault();

  // setLoader(true);
  PostCallWithErrorResponse(
        ApiConfig.NOTIFICATION,
        JSON.stringify({...notification,})
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            navigate("/Dashboard")
            
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          // setLoader(false);

          ToastMsg("error", "something went wrong");
        });
};
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="wrapper-push-notification">
        <div className="row main-section">
          {/* <div className="col-md-6 left-section">
            <h4>Step by step working</h4>
            <div className="container"> */}
              {/* <!-- completed --> */}
              {/* <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>

                <div className="content">
                  <h5>Access Firebase Console</h5>
                  <p>
                    Go to the Firebase website and sign up for an account if you
                    don’t already have one. You can log in with a Google account
                    for easy access
                  </p>
                </div>
              </div> */}

              {/* <!-- active --> */}
              {/* <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>

                <div className="content">
                  <h5>CREATE NEW PROJECT</h5>
                  <p>
                    When you log in, you should be directed to the Firebase
                    console. You can manage all of your projects here. Go ahead
                    and create a new one by clicking the blue CREATE NEW PROJECT
                    button. Give your new project a name.
                  </p>
                </div>
              </div>

              <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>

                <div className="content">
                  <h5>Next</h5>
                  <p>
                    Once you create your project, you’ll be redirected to your
                    project’s console overview. If you check the URL at the top
                    of your browser, you’ll see something like
                    https://console.firebase.google.com/project/talks-you-should-watch/overview,
                    where the part of the URL after /project/ matches your
                    project’s name.
                  </p>
                </div>
              </div>

              <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>

                <div className="content">
                  <h5>Add Application</h5>
                  <p>
                    Now yoy can add application from Add app option. After this
                    go into the app settings and into the cloud messaging
                    section. You can find server key for android application and
                    APNS Topic, .pem file for ios application. Add those
                    credentials here.
                  </p>
                </div>
              </div> */}
            {/* </div>
          </div> */}
         
        </div>
         <div className="col-md-12 right-section">
            <h4>Push Notification</h4>
            <div className="mt-5">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>

              <div className="mb-4">
                <Form.Label>Title</Form.Label>
                <Form.Control
                 required 
                 type="text"
                    onChange={(e)=>setNotication({...notification,
                      title:e.target.value
                      })}
                />
                <Form.Control.Feedback
                  type="invalid"
                  placeholder="Email Address"
                >
                  Please Enter Title.
                </Form.Control.Feedback>
              </div>
              <div className="mb-4">
                <Form.Label>Message</Form.Label>
                <Form.Control 
                required
                as="textarea"
                rows={3}
                onChange={(e)=>setNotication({...notification,
                message:e.target.value
                })}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your Message.
                </Form.Control.Feedback>
              </div>

              <div className="text-end">
                <button type="submit" >Submit</button>
              </div>
              </Form>
              {/* <div className="mb-5">
                <Form.Label>APNS Certificate</Form.Label>
                <Form.Control required type="file" />
                <Form.Control.Feedback type="invalid">
                  Please Enter your password.
                </Form.Control.Feedback>
              </div> */}
              
            </div>
            <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center my-4">
            <label htmlFor="">Are you sure you want to send Notification to All Users?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
             onClick={(e) =>{ 
              handleDeleteClose() 
                addNotification(e)}
              }
            >
             Send
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
          </div>
      </div>
    </main>
  );
};

export default PushNotification;
