import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules

import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import search from "../../assets/images/search.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";
// import ApiConfig from "../api/ApiConfig";
import ApiConfig, {
  FILE_BASE_URL,
  FILE_BASE_URL_VERSE,
} from "../../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  multipartPutCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  PostCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponsePlane,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "../Pagination";
import FormLoader from "../FormLoader";
import HifzESurah from "../HifzESurah";
import NoraaniQaaidah from "../NoraaniQaaidah";
import DuaSunnat from "../DuaSunnat";
import Aqaaid from "../Aqaaid";
import Namaaz from "../Namaaz";
import Masaail from "../Masaail";
import Seerat from "../Seerat";

// import {
//   DeleteCallWithErrorResponse,
//   multipartPostCallWithErrorResponse,

//   PostCallWithAuthTokenWithErrorResponse,
//   putMultipartWithAuthCallWithErrorResponse,
//   simpleGetCallWithErrorResponse,
// } from "../api/ApiServices";

const Reciter = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(true);

  const [deleteReciterId, setDeleteReciterId] = useState("");

  const [verseID, setVerseID] = useState();


  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };

  const [createReciter, setCreatReciter] = useState({
    reciterName: "",
  });

  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    Tarjama: "100%",
    Description: "100%",
  });

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // viewModal

  const [viewModal, setViewModal] = useState(false);
  const handleViewClose = () => setViewModal(false);
  const handleViewShow = () => setViewModal(true);

  const [eventKey, setEventKey] = useState(1);
  const [eventtitle, setEventtitle] = useState("Hifze Hadees");

  const [Delete, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [verseList, setVerseList] = useState([]);

  const [searchVal, setSearchVal] = useState();

  const [reciterList, setReciterList] = useState([]);
  const [juzList, setJuzList] = useState([]);
  const [reciter, setReciter] = useState({
    reciterId: 0,
    reciterName: "",
  });

  const [reciterIdDropdown, setReciterIdDropdown] = useState();
  const [selectedPara, setSelectedPara] = useState("");

  const [selectedjuzId, setSelectedjuzId] = useState("");

  // const [selectedJuzNo, setSelectedJuzNo] = useState();
  // console.log("selectedJuzNo", selectedJuzNo);

  const [currentPage, setCurrentPage] = useState(0);

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(reciterList?.length / 15);

  const [totalCount, setTotalCount] = useState();

  // Pagination for verse
  const [currentPageVerse, setCurrentPageVerse] = useState(1); // Start from page 1

  const changePageVerse = ({ selected }) => {
    setCurrentPageVerse(selected + 1); // Adjust selected to start from 1
  };

  const pageCountVerse = Math.ceil(totalCount / 10);


  console.log("createReciter", createReciter);

  const handleAddReciter = (e) => {
    e.preventDefault();
    setShow1(false);

    PostCallWithErrorResponse(
      ApiConfig.ADD_RECITER,
      JSON.stringify(createReciter)
    )
      .then((res) => {
        console.log("res1>>>>>", res);
        // setIsLoading(true);
        // setLoader(false)

        if (res.json.success == true) {
          ToastMsg("success", res.json.message);
          recitersListApi();
        } else {
          // setIsLoading(false);
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => console.log(err));
    console.log("handleAddReciter", createReciter);
  };

  const [verseAudioObj, setVerseAudioObj] = useState({
    verseId: 0,
    reciterId: reciterIdDropdown,
    juzNo: 0,
    verseAudio: [],
  });
  console.log("verseAudioObj", verseAudioObj);

  const [edit, setEdit] = useState(false);
  const [edit1, setEdit1] = useState(false);
  const [edit2, setEdit2] = useState(false);

  const [addReciter, setAddReciter] = useState("");
  const handleAddReciterName = (e) => {
    setAddReciter(e.target.value);
  };

  const [value, setValue] = useState("Reciter 1");
  const [value1, setValue1] = useState("Reciter 2");
  const [value2, setValue2] = useState("Reciter 3");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleChange1 = (e) => {
    setValue1(e.target.value);
  };

  const handleChange2 = (e) => {
    setValue2(e.target.value);
  };

  // Reciter List API
  const recitersListApi = () => {
    // simpleGetCallWithErrorResponse(ApiConfig.GET_ALL_RECITERS + `?searchVal=${addReciter}`)
    simpleGetCallWithErrorResponse(addReciter == "[object Object]" || null ? ApiConfig.GET_ALL_RECITERS : ApiConfig.GET_ALL_RECITERS + `?searchVal=${addReciter}`)
      .then((res) => {
        console.log("Reciter List", res);
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setReciterList(res.json.data);

          // setLsatSabaq(res.json?.data.allSabaq.length);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  useEffect(() => {
    const defaultReciterId =
      reciterList.length > 0 ? reciterList[0].reciter_id : "";
    setReciterIdDropdown(defaultReciterId);
  }, [reciterList]);

  useEffect(() => {
    const defaultSelectedPara = juzList.length > 0 ? juzList[0].juz_id : "";
    setSelectedPara(defaultSelectedPara);
  }, [juzList]);

  useEffect(() => {
    verseListApi();
  }, [selectedPara, reciterIdDropdown, searchVal, currentPageVerse]);

  // Reciter List API
  const juzListApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_ALL_JUZ)
      .then((res) => {
        console.log("Juz List", res);
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setJuzList(res.json.data);
          setSelectedjuzId(res.json.data[0].juz_id);
          setVerseAudioObj({
            ...verseAudioObj,
            juzNo: res.json.data[0].juz_no,
          });
          // setLsatSabaq(res.json?.data.allSabaq.length);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  // verseListApi List API
  // const verseListApi = () => {
  //   setLoader(true);

  //   simpleGetCallWithErrorResponse(
  //     ApiConfig.VERSE_LIST_BY_RECITER +
  //       `juzId=${selectedjuzId}&verseType=15&reciterId=${reciterIdDropdown}&searchVal=${
  //         searchVal || ""
  //       }&page=${currentPageVerse || ""}`
  //   )
  //     .then((res) => {
  //       setLoader(false);

  //       if (res.json && res.json?.success === true) {
  //         setVerseList(res.json.data);
  //         setTotalCount(res?.json?.total_count);
  //         // const pageCountVerse = Math.ceil(totalCount / 15);
  //       } else {
  //         // Handle error
  //         // ToastMsg("error", res.json.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       // Handle error
  //       // ToastMsg("error", "something went wrong");
  //     });
  // };

  const verseListApi = () => {
    setLoader(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.VERSE_LIST_BY_RECITER +
      `juzId=${selectedjuzId}&verseType=15&reciterId=${reciterIdDropdown}&searchVal=${searchVal || ""
      }&page=${currentPageVerse || ""}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          // Update the state with the fetched data
          setVerseList(res.json.data);
          setTotalCount(res?.json?.total_count);

          // Log the updated state
          console.log("Updated Verse List:", res.json.data);
        } else {
          // Handle error
          // ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        // Handle error
        // ToastMsg("error", "something went wrong");
      });
  };


  // Reciter Update API
  const updateReciter = () => {
    putMultipartWithAuthCallWithErrorResponsePlane(
      ApiConfig.UPDATE_RECITER,
      JSON.stringify(reciter)
    )
      .then((res) => {
        console.log("res", res);
        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);
          recitersListApi();
          // setSaveBotton(false);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const deleteReciter = () => {
    const body = {
      reciterId: deleteReciterId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_RECITER,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        // setLoader(false);
        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          // setSabaqList(res.json.data);
          ToastMsg("success", res.json.message);
          recitersListApi();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  useEffect(() => {
    recitersListApi();
  }, [addReciter]);

  useEffect(() => {
    juzListApi();
  }, []);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      updateVerseAudio();
    }

    setValidated(true);
  };

  // const updateVerseAudio = () => {
  //   // setSaveBotton(true);
  //   // handleAddClose();
  //   handleClose2();
  //   setLoader(true);

  //   const body = {
  //     verseId: verseAudioObj.verseId,
  //     reciterId: reciterIdDropdown,
  //     juzNo: verseAudioObj.juzNo,
  //     verseAudio: verseAudioObj.verseAudio,
  //   };
  //   //all dataa paylodade

  //   let payLoadFormData = new FormData();
  //   payLoadFormData.append("verseId", body.verseId);
  //   payLoadFormData.append("reciterId", body.reciterId);
  //   payLoadFormData.append("juzNo", body.juzNo);
  //   payLoadFormData.append(
  //     "verseAudio",
  //     body.verseAudio ? body.verseAudio : ""
  //   );

  //   multipartPostCallWithErrorResponse(
  //     ApiConfig.UPDATE_VERSE_AUDIO,
  //     payLoadFormData
  //     // payLoadFormDataNew
  //   )
  //     .then((res) => {
  //       if (res.json && res.json?.success === true) {
  //         verseListApi();
  //         ToastMsg("success", res.json.message);
  //         // duaaListApi();
  //         // setSaveBotton(false);
  //       } else {
  //         ToastMsg("error", res.json.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);

  //       ToastMsg("error", "something went wrong");
  //     });
  // };

  const updateVerseAudio = () => {
    handleClose2();
    setLoader(true);

    const body = {
      verseId: verseID,
      reciterId: reciterIdDropdown,
      juzNo: verseAudioObj?.juzNo,
      verseAudio: verseAudioObj?.verseAudio,
    };

    let payLoadFormData = new FormData();
    payLoadFormData.append("verseId", body.verseId);
    payLoadFormData.append("reciterId", body.reciterId);
    payLoadFormData.append("juzNo", body.juzNo);
    payLoadFormData.append("verseAudio", body.verseAudio ? body.verseAudio : "");

    multipartPostCallWithErrorResponse(ApiConfig.UPDATE_VERSE_AUDIO, payLoadFormData)
      .then((res) => {
        if (res.json && res.json?.success === true) {
          // Append a timestamp or unique identifier to force a refresh
          ToastMsg("success", "Update successful");
          verseListApi();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };


  const handleVerseAudioEdit = (item) => {
    setVerseAudioObj({
      ...verseAudioObj,
      verseAudio: item.audio_path
    })
  }

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Reciters</label>
        </div>
      </div>

      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Nav variant="pills" className="flex-row">
          <Nav.Item>
            <Nav.Link
              eventKey="1"
              onClick={() => {
                setEventKey(1);
                // setEventtitle("Noorani Qaaidah");
              }}
            >
              Reciter
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="2"
              onClick={() => {
                setEventKey(1);
                setEventtitle("Dua Sunnat");
              }}
            >
              Verse Audios
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          {/*   ====== Reciter ===== */}
          <Tab.Pane eventKey="1">
            <div className="jobDescriptio-main">
              <div className="wrapper">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row jd-main">
                    <div className="heading-btn-main">
                      <div className="heading-common"></div>
                      <div className="add-btn">
                        <input
                          type="text"
                          className="form-control"
                          // value={addReciter.reciter_name}
                          onChange={handleAddReciterName}
                          placeholder="Enter Reciter Name"
                        />
                        <button
                          className="m-2"
                          // disabled={addReciter?.length > 0 ? false : true}
                          onClick={() => {
                            handleShow1();
                            // let test = {
                            //   reciter_name: addReciter,
                            // };

                            let test = {
                              reciter_name: addReciter.reciter_name, // Render specific property
                            };

                            // reciterList.push(test);

                            // setReciterList([...reciterList, test]);
                            setReciterList([...reciterList]);
                            setAddReciter({ reciter_name: "" });
                          }}
                        >
                          Add Reciter
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="white"
                              d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="table-wrapper">
                      <table className="display table table-lg table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th style={{ textAlign: "left" }}>Reciter Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reciterList.map((ele, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td
                                  style={{ textAlign: "left" }}
                                  className="action"
                                >
                                  {edit === i ? (
                                    <div className="reciter_wrapepr">
                                      <input
                                        value={ele.reciter_name}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                          setReciterList(
                                            reciterList.map(
                                              (item, innerIndex) => {
                                                return innerIndex === i
                                                  ? {
                                                    ...item,
                                                    reciter_name:
                                                      e.target.value,
                                                  }
                                                  : item;
                                              }
                                            )
                                          );
                                          setReciter({
                                            reciterName: e.target.value,
                                            reciterId: ele.reciter_id,
                                          });
                                        }}
                                      />
                                      <Link
                                        className="icon edit"
                                        onClick={() => {
                                          setEdit("");
                                          updateReciter();
                                        }}
                                      >
                                        Update
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className="reciter_wrapepr">
                                      {ele.reciter_name}
                                      <Link
                                        className="icon edit"
                                        onClick={() => {
                                          setEdit(i);
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    </div>
                                  )}
                                </td>
                                <td className="action">
                                  <Link
                                    className="icon delete"
                                    href="/FirstYear"
                                    onClick={() => {
                                      handleDeleteShow();
                                      // deleteReciter(ele.reciter_id);
                                      setDeleteReciterId(ele.reciter_id);
                                    }}
                                  >
                                    <img src={ic_delete} alt="" />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {reciterList && reciterList.length > 0 ? (
                        <Pagination
                          changePage={changePage}
                          pageCount={pageCount}
                          currentPage={currentPage}
                        />
                      ) : (
                        <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                          <h5 style={{ color: "red", textAlign: "center", }}> Records Not Found</h5>
                        </div>
                      )}
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </Tab.Pane>
          {/* ======================= START ADD ========================== */}
          <Modal
            show={show}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="contained-modal-subSabaqTitle-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add {eventtitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <Form.Label>Sabaq Name</Form.Label>
                    <Form.Control required type="text" name="sabaqName" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>

                  <div className="col-md-6 ">
                    <Form.Label> Sabaq Audio</Form.Label>
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <Form.Control
                      type="file"
                      accept="audio/mp3,audio/*;capture=microphone"
                      name="sabaqAudio"
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>

                  <div className="col-md-12 mb-3">
                    <Form.Label>Arabic Text</Form.Label>
                    <Form.Control
                      // required
                      as="textarea"
                      rows={3}
                      name="sabaqDesc"
                    // value={addSabaq?.sabaqDesc}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Label> Arabic Audio</Form.Label>
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <Form.Control
                      type="file"
                      accept="audio/mp3,audio/*;capture=microphone"
                      name="sabaqAudio"
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Label>Hadees Reference</Form.Label>
                    <Form.Control required type="text" name="sabaqName" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Label>Hadees Tarjama Text</Form.Label>
                    <Form.Control
                      // required
                      as="textarea"
                      rows={3}
                      name="sabaqDesc"
                    // value={addSabaq?.sabaqDesc}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Label> Hadees Tarjama Audio</Form.Label>
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <Form.Control
                      type="file"
                      accept="audio/mp3,audio/*;capture=microphone"
                      name="sabaqAudio"
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>
                </div>
                <Link to="#" className={"add-newSabaq-btn btn btn-primary"}>
                  + Add Sub Sabaq Details
                </Link>
                <div className="buttons-modal border-top pt-3 text-end ">
                  <button className="me-2" type="submit">
                    Add
                  </button>
                  <Link to="#" className=" bg-danger">
                    Cancel
                  </Link>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          {/* ======================= END ADD ========================== */}
          {/* ======= Verse Audio ======= */}
          <Tab.Pane eventKey="2">
            <div className="row g-0 jd-main">
              <div className="heading-btn-main">
                {/* <div className="heading-common"></div> */}
                <div className="col-md-12">
                  <div className="add-btn mt-4">
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Select
                          onChange={(e) => {
                            setReciterIdDropdown(e.target.value);
                            setVerseAudioObj({
                              ...verseAudioObj,
                              reciterId: Number(e.target.value),
                            });
                          }}
                          value={reciterIdDropdown}
                        >
                          <option disabled selected>
                            Select Reciter
                          </option>
                          {reciterList.length > 0
                            ? reciterList.map((item) => {
                              return (
                                <option
                                  key={item.reciter_id}
                                  value={item.reciter_id}
                                >
                                  {item.reciter_name}
                                </option>
                              );
                            })
                            : null}
                        </Form.Select>
                      </div>
                      <div className="col-md-4">
                        {/* <Form.Select onChange={(e) => setSelectedPara(e.target.value)}  value={selectedPara}>
                          <option disabled selected>
                            Select Para
                          </option>
                          {
                            juzList.length > 0 ?
                              juzList.map(item => {
                                return (
                                  <option value={item.juz_id}>{item.juz_name} ({item.juz_name_arabic})</option>

                                )
                              }) : null
                          }
                        </Form.Select> */}

                        <Form.Select
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            const { juz_id, juz_no } =
                              JSON.parse(selectedValue);

                            setSelectedPara(selectedValue);
                            setSelectedjuzId(juz_id); // Use juz_id consistently
                            setVerseAudioObj({
                              ...verseAudioObj,
                              juzNo: juz_no, // Use juz_id consistently
                            });
                          }}
                          value={selectedPara}
                        >
                          <option disabled selected>
                            Select Para
                          </option>
                          {juzList.length > 0
                            ? juzList.map((item) => (
                              <option
                                key={item.juz_id}
                                value={JSON.stringify({
                                  juz_id: item.juz_id,
                                  juz_no: item.juz_no,
                                })}
                              >
                                {item.juz_name} ({item.juz_name_arabic})
                              </option>
                            ))
                            : null}
                        </Form.Select>
                      </div>
                      <div className="col-md-4 search_option">
                        <Form.Control
                          placeholder="Search by Ayat Number..."
                          onChange={(e) => {
                            setSearchVal(Number(e.target.value));
                          }}
                        ></Form.Control>
                        <button className="search">
                          <img src={search} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                {/* <h6>Total Records : 34</h6> */}
                <table className="display table table-lg table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th style={{ textAlign: "left" }}>Ayat No.</th>
                      <th>Arabic Text</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {verseList && verseList.length > 0 ? (
                      verseList.map((item, index) => {
                        console.log("AUDIO", `https://apiquran.deenlearning.in:3000/${item?.audio_path}`);
                        return (
                          <tr key={item.verse_id}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>{item.verse_no}</td>
                            <td>{item.verse}</td>
                            <td className="action" id="audioMain">
                              <Link
                                className="icon edit"
                                href="/FirstYear"
                                onClick={() => {
                                  handleShow2();
                                  setVerseAudioObj({
                                    ...verseAudioObj,
                                    verseId: item.verse_id,
                                  });
                                  handleVerseAudioEdit(item);
                                  setVerseID(item.verse_id);
                                }}
                              >
                                <img src={ic_edit} alt="" />
                              </Link>

                              {item.audio_path !== null && (
                                <audio controls className="audioJack">
                                  <source
                                    src={`${FILE_BASE_URL}${item?.audio_path}?${Date.now()}`}
                                    type="audio/ogg"
                                  />
                                  <source
                                    src={`${FILE_BASE_URL}${item?.audio_path}?${Date.now() + 1}`}
                                    type="audio/mpeg"
                                  />
                                  Your browser does not support the audio element.
                                </audio>
                              )}

                              {/* Additional icons if needed */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="h5" style={{ color: "red" }} colSpan="4">
                          Records Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>

                </table>
                {/* {verseList && verseList.length > 0 ? ( */}
                <Pagination
                  breakLabel="..."
                  changePage={changePageVerse}
                  pageCount={pageCountVerse}
                  currentPage={currentPageVerse - 1}
                />
                {/* ) : ""} */}
                {/* <div className="row mt-4">
                  <div className="col-lg-12">
                    <ul
                      className="paginationBttns"
                      role="navigation"
                      aria-label="Pagination"
                    >
                      <li className="previous paginationDisabled">
                        <Link
                          className="previousBttn"
                        // tabIndex={-1}
                        // role="button"
                        // aria-disabled="true"
                        // aria-label="Previous page"
                        // rel="prev"
                        >
                          Previous
                        </Link>
                      </li>
                      <li className="paginationActive">
                        <Link
                          rel="canonical"
                          role="button"
                          tabIndex={-1}
                          aria-label="Page 1 is your current page"
                          aria-current="page"
                        >
                          1
                        </Link>
                      </li>
                      <li>
                        <Link
                          rel="next"
                          role="button"
                          tabIndex={0}
                          aria-label="Page 2"
                        >
                          2
                        </Link>
                      </li>
                      <li>
                        <Link role="button" tabIndex={0} aria-label="Page 3">
                          3
                        </Link>
                      </li>
                      <li className="next">
                        <Link
                          className="nextBttn"
                          tabIndex={0}
                          role="button"
                          aria-disabled="false"
                          aria-label="Next page"
                          rel="next"
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container >

      {/* ======================= START ADD ========================== */}
      < Modal
        show={show1}
        onHide={() => handleClose1()}
        size="lg"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Reciter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={handleAddReciter}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Reciter Name</Form.Label>
                <Form.Control
                  required
                  onChange={(e) =>
                    setCreatReciter({
                      reciterName: e.target.value,
                    })
                  }
                  type="text"
                  name="sabaqName"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              {/* <div className="col-md-6 ">
                <Form.Label> Arabic Audio</Form.Label>
              </div>
              <div className="col-md-12 mb-3 ">
                <Form.Control
                  type="file"
                  accept="audio/mp3,audio/*;capture=microphone"
                  name="sabaqAudio"
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Arabic Text</Form.Label>
                <Form.Control
                  // required
                  as="textarea"
                  rows={3}
                  name="sabaqDesc"
                  // value={addSabaq?.sabaqDesc}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Tarjama.
                </Form.Control.Feedback>
              </div> */}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                Add
              </button>
              <Link to="#" className=" bg-danger">
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal >
      {/* ======================= END ADD ========================== */}

      {/* ======================= START EDIT ========================== */}
      <Modal
        show={show2}
        onHide={() => handleClose2()}
        size="lg"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Audio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              {/* <div className="col-md-12 mb-3">
                <Form.Label>Ayat No.</Form.Label>
                <Form.Control required type="text" name="sabaqName" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div> */}

              {show2 ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Arabic Audio
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof verseAudioObj?.verseAudio === "string"
                          ? verseAudioObj?.verseAudio.slice(-15)
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaq }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaq: "100%" });
                          setVerseAudioObj({
                            ...verseAudioObj,
                            verseAudio: e.target.files[0],
                          })
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <div className="col-md-6 ">
                    <Form.Label> Arabic Audio</Form.Label>
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <Form.Control
                      // required
                      // src={verseAudioObj?.verseAudio}
                      type="file"
                      accept="audio/mp3,audio/*;capture=microphone"
                      name="sabaqAudio"
                      onChange={(e) =>
                        setVerseAudioObj({
                          ...verseAudioObj,
                          verseAudio: e.target.files[0],
                        })
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      Please Enter Verse Audio
                    </Form.Control.Feedback>
                  </div>

                </div>
              )}



              {/* <div className="col-md-6 ">
                <Form.Label> Arabic Audio</Form.Label>
              </div>
              <div className="col-md-12 mb-3 ">
                <Form.Control
                  // required
                  type="file"
                  accept="audio/mp3,audio/*;capture=microphone"
                  name="sabaqAudio"
                  onChange={(e) =>
                    setVerseAudioObj({
                      ...verseAudioObj,
                      verseAudio: e.target.files[0],
                    })
                  }
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Verse Audio
                </Form.Control.Feedback>
              </div> */}


            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                Update
              </button>
              {/* <Link to="#" className=" bg-danger">
                Cancel
              </Link> */}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END EDIT ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Audio Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Ayat No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    123
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Arabic Text
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    بِسْمِ اللّٰہِ الرَّحْمٰنِ الرَّحِیْمِ
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Ayat Audio
                  </label>
                </div>
                <div>
                  <audio controls style={{ width: "100%", height: "38px" }}>
                    <source src="" type="audio/ogg" />
                    <source src="" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      {/* ======================= START DELETE ========================== */}
      <Modal
        show={Delete}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-mashq-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              onClick={() => {
                handleDeleteClose();
                deleteReciter();
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}
    </main >
  );
};

export default Reciter;
