import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import ApexCharts from 'apexcharts'
import Chart from "react-apexcharts";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import ic_install from "../assets/images/ic_install.svg";
import ic_newReturn from "../assets/images/ic_newReturn.svg";
import ic_activeUser from "../assets/images/ic_activeUser.svg";
import ic_location from "../assets/images/ic_location.svg";
import ic_device from "../assets/images/ic_device.svg";
import ic_registered from "../assets/images/ic_registered.svg";
import Modal from "react-bootstrap/Modal";
import ic_time from "../assets/images/ic_time.svg";
import Aqaaid_active from "../assets/images/Aqaaid_active.svg";
import Seerat_active from "../assets/images/Seerat_active.svg";
import Noraani_Qaaidah_active from "../assets/images/Noraani_Qaaidah_active.svg";
import Dua_Sunnat_active from "../assets/images/Dua_Sunnat_active.svg";
import Hifz_e_Surah_active from "../assets/images/Hifz_e_Surah_active.svg";
import Namaaz_active from "../assets/images/Namaaz_active.svg";
import Masaail_active from "../assets/images/Masaail_active.svg";
import Umrah_Hajj_active from "../assets/images/Umrah_Hajj_active.svg";
import { ToastMsg } from "./TostMsg";
import { simpleGetCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import FormLoader from "./FormLoader";

const Dashboard = () => {

  const { sidebar } = useContext(AppContext);
  const navigate = useNavigate();
  const [oSCount, setOSCount] = useState(0)
  const [dashboardCount, setDashboardCount] = useState([]);
  const [loader, setLoader] = useState(true);

  const [dashboardData, setDashboardData] = useState([]);
  console.log("Data", dashboardData[0]);

  const [countryNames, setCountryNames] = useState([]);
  console.log("countryNames", countryNames);
  const [totalCountryCount, setTotalCountryCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState([]);
  console.log("totalUsersCount", totalUsersCount);

  const [osName, setOsName] = useState([]);
  console.log("OS NAME", osName);

  // Add a loading state to show a loader while data is being fetched
  const [loading, setLoading] = useState(true);


  const [totalIosUsersCount, setTotalIosUsersCount] = useState(0);

  useEffect(() => {
    getDashboardData();
  }, []);

  // Function to calculate the total number of users for a given OS name
  const getTotalUsersByOS = (osName) => {
    let totalUsers = 0;
    dashboardData[0]?.operatingSystem.forEach((os) => {
      if (os.osName === osName) {
        totalUsers += parseInt(os.totalUsers);
      }
    });
    return totalUsers;
  };

  // Calculate the total number of users for Android and iOS
  const androidTotalUsers = getTotalUsersByOS("Android");
  const iOSTotalUsers = getTotalUsersByOS("iOS");
  console.log("androidTotalUsers", androidTotalUsers);
  console.log("iOSTotalUsers", iOSTotalUsers);


  //   simpleGetCallWithErrorResponse(ApiConfig.GET_GOOGLE_ANALYTICS)
  //     .then((res) => {
  //       setLoader(false);
  //       if (res.json && res.json?.success === true) {
  //         console.log(">>>>>>>>>>>>>>>>>>>", res.json.data);
  //         setDashboardData(res.json.data);
  //         let data = res.json.data;
  //         const totalCountry = data[0]?.countries[0]?.totalCountries;
  //         console.log("Total Countries:", totalCountry);
  //         setTotalCountryCount(totalCountry || 0);
    
  //         const countries = data[0]?.countries?.slice(1);
  //         console.log("countries", countries);
    
  //         const countryTotalUsers = countries?.map((users) => users.totalUsers);
  //         setTotalUsersCount(countryTotalUsers);
    
  //         const countryNamesArray = countries?.map(
  //           (country) => country.countryName
  //         );
  //         setCountryNames(countryNamesArray);
    
  //         const iOSCount = data[0]?.operatingSystem.filter(
  //           (item) => item.osName === "iOS"
  //         ).length;
  //         setTotalIosUsersCount(iOSCount);
  //         console.log("iOSCount:", iOSCount);
  //       } else {
  //         ToastMsg("error", res.json.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       ToastMsg("error", "something went wrong");
  //     });
  // };

  const [locationChart, setlocationChart] = useState({
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },

      xaxis: {
        categories: dashboardData.flatMap((item) =>
        item.countries?.map((country) => country.countryName) ?? []
      ),
      },
    },
    series: [
      {
        name: "series-1",
        data: (dashboardData[0]?.countries ?? [])
          ?.map((country) => country.totalUsers)
          .slice(1), // Slicing the array to exclude the first index
      },
    ],
  });
  
  console.log("locationChart", locationChart);

  const getDashboardData = () => {
  simpleGetCallWithErrorResponse(ApiConfig.GET_GOOGLE_ANALYTICS)
    .then((res) => {
      setLoader(false);
      if (res.json && res.json?.success === true) {
        console.log(">>>>>>>>>>>>>>>>>>>", res.json.data);
        setDashboardData(res.json.data);
        let data = res.json.data;


        // Extracting country names and total users
        const countryNamesArray = data.flatMap((item) =>
          item.countries?.map((country) => country.countryName) ?? []
        );
        setlocationChart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: countryNamesArray.slice(1),
            },
          },
        }));

        const totalUsersArray = data[0]?.countries
          ?.map((country) => country.totalUsers)
          .slice(1);
        setlocationChart((prevState) => ({
          ...prevState,
          series: [
            {
              ...prevState.series[0],
              data: totalUsersArray,
            },
          ],
        }));

        // Rest of your code...
        
        // Update iOSChart
        const iosCategories = [
          ...new Set(
            data[0]?.operatingSystem?.map((brand) => brand.osName)
          ),
        ];
        setiOSChart((prevState) => ({
          ...prevState,
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: iosCategories,
            },
          },
        }));

        const androidTotalUsers = data[0]?.operatingSystem?.find(
          (os) => os.osName === "Android"
        )?.totalUsers;
        const iOSTotalUsers = data[0]?.operatingSystem?.find(
          (os) => os.osName === "iOS"
        )?.totalUsers;
        setiOSChart((prevState) => ({
          ...prevState,
          series: [
            {
              ...prevState.series[0],
              data: [androidTotalUsers, iOSTotalUsers],
            },
          ],
        }));

           // Update AndroidChart
           const androidCategories = data[0]?.operatingSystem?.map(
            (os) => `${os.osName} ${os.osVersion}`
          );
          setOSCount(androidCategories.length)
          const androidData = data[0]?.operatingSystem?.map(
            (os) => os.totalUsers
          );
          setAndroidChart((prevState) => ({
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: androidCategories,
              },
            },
            series: [
              {
                ...prevState.series[0],
                data: androidData,
              },
            ],
          }));
        
        const totalCountry = data[0]?.countries[0]?.totalCountries;
                console.log("Total Countries:", totalCountry);
                setTotalCountryCount(totalCountry || 0);

      } else {
        ToastMsg("error", res.json.message);
      }
    })
    .catch((err) => {
      setLoader(false);
      ToastMsg("error", "something went wrong");
    });
};



  const [iOSChart, setiOSChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
        categories: [
          ...new Set(
            dashboardData[0]?.operatingSystem?.map((brand) => brand.osName)
          ),
        ],
      },
    },
    series: [
      {
        name: "series-1",
        data: [androidTotalUsers, iOSTotalUsers],
      },
    ],
  });

  const [AndroidChart, setAndroidChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
        categories: (dashboardData[0]?.operatingSystem ?? []).map(
          (os) => `${os.osName} ${os.osVersion}`
        ),
      },
    },
    series: [
      {
        name: "series-1",
        data: (dashboardData[0]?.operatingSystem ?? []).map(
          (item) => item.totalUsers
        ),
      },
    ],
  });

  const [location, setlocation] = useState(false);
  const handlelocationClose = () => setlocation(false);
  const handlelocationShow = () => setlocation(true);

  const [devices, setDevice] = useState(false);
  const handledevicesClose = () => setDevice(false);
  const handledevicesShow = () => setDevice(true);

  useEffect(() => {
    $(document).ready(function () {
      $("#table_id").DataTable();
    });
  }, []);
  useEffect(() => {
    // dashboard();
    // getDashboardData();
  }, []);
  const dashboard = () => {
    // setIsLoading(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.DASHBOARD

      //   JSON.stringify({
      //     ...body,
      //   })
    )
      .then((res) => {
        setLoader(false);
        // console.log(res);
        if (res.json && res.json?.success === true) {
          // ToastMsg("success", res.json.message);
          setDashboardCount(res.json.data);
          // handleDeleteClose();
          // SabaqListApi();
          // setIsDeleteList(true);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const naviGationRoute = (id) => {
    // console.log(id,"fscxvbxvb")
    if (id == 2) {
      navigate("/NoraaniQaaidah");
    } else if (id == 3) {
      navigate("/HifzESurah");
    } else if (id == 4) {
      navigate("/DuaSunnat");
    } else if (id == 5) {
      navigate("/Aqaaid");
    } else if (id == 6) {
      navigate("/Masaail");
    } else if (id == 7) {
      navigate("/UmrahHajj");
    } else if (id == 8) {
      navigate("/Namaaz");
    } else if (id == 9) {
      navigate("/Seerat");
    } else if (id == 10) {
      navigate("/Ramzan");
    }
  };





  return (
    <main
      id="cx-main"
      className={sidebar ? "dashboard-main" : "cx-active dashboard-main"}
    >
      {loader ? (
        <FormLoader />
      ) : (
        <div className="dashboard-wrapper">
          <div className="heading-common">
            <label htmlFor="">DASHBOARD</label>
          </div>
          <div className="row mt-3 main-section">
            <div className="col-md-6 left-section">
              <div className="row">
                <h5>App Content Count</h5>
                {dashboardCount &&
                  dashboardCount.length > 0 &&
                  dashboardCount.map((ele, index) => {
                    return (
                      <div className="col-md-6" key={"index" + index}>
                        <div
                          className="mainCards"
                          onClick={() => naviGationRoute(ele.category_id)}
                        >
                          <div className="ineerCard">
                            <div className="innerIcon">
                              <img src={ele.categoryImagePath} alt="" />
                            </div>
                            <div className="innerText">
                              <div className="col headingText">
                                {ele.category_name}
                              </div>
                              <div className="col count">
                                {ele.contentCount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="right-section col-md-6">
              <div className="row">
                <h5>Analytics</h5>

                {dashboardData?.length > 0 ? (
                  <>
                    <div className="col-md-6">
                      <Link className="mainCards" to="#">
                        <div className="ineerCard">
                          <div className="innerIcon">
                            <img src={ic_install} alt="" />
                          </div>
                          <div className="innerText">
                            <div className="col headingText">Total Install</div>
                            <div className="col count">
                              {dashboardData[0].totalInstall}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link className="mainCards" to="#">
                        <div className="ineerCard">
                          <div className="innerIcon">
                            <img src={ic_newReturn} alt="" />
                          </div>
                          <div className="innerText">
                            <div className="col headingText">
                              New/Returning Users
                            </div>
                            <div className="col count">
                              <span>{dashboardData[0].newUsers}</span>/
                              <span>{dashboardData[0].returningUsers}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link
                        className="mainCards"
                        to="#"
                        onClick={handlelocationShow}
                      >
                        <div className="ineerCard">
                          <div className="innerIcon">
                            <img src={ic_location} alt="" />
                          </div>
                          <div className="innerText">
                            <div className="col headingText">Location</div>
                            <div className="col count">{totalCountryCount}</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link
                        className="mainCards"
                        to="#"
                        onClick={handledevicesShow}
                      >
                        <div className="ineerCard">
                          <div className="innerIcon">
                            <img src={ic_device} alt="" />
                          </div>
                          <div className="innerText">
                            <div className="col headingText">
                              Device/Version
                            </div>
                            <div className="col count">
                              {androidTotalUsers} / {iOSTotalUsers}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link className="mainCards" to="#">
                        <div className="ineerCard">
                          <div className="innerIcon">
                            <img src={ic_time} alt="" />
                          </div>
                          <div className="innerText">
                            <div className="col headingText">Screen Time</div>
                            <div className="col count">1358</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/* <div className="col-md-6">
                  <Link className="mainCards" to="#">
                    <div className="ineerCard">
                      <div className="innerIcon">
                        <img src={ic_registered} alt="" />
                      </div>
                      <div className="innerText">
                        <div className="col headingText">Registered</div>
                        <div className="col count">78</div>
                      </div>
                    </div>
                  </Link>
                </div> */}
                    <div className="col-md-6">
                      <Link className="mainCards" to="#">
                        <div className="ineerCard mb-0">
                          <div className="innerIcon">
                            <img src={ic_activeUser} alt="" />
                          </div>
                          <div className="innerText">
                            <div className="col headingText">Active User</div>
                            <div className="col count">
                              {dashboardData[0].activeUsers}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                ) : (
                  <h5 className="text-center text-danger">No data found</h5>
                )}
              </div>

              <Modal
                show={location}
                onHide={handlelocationClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className="mb-3">
                    Cumulative total of countries : {totalCountryCount}
                  </h5>
                  <Chart
                    options={locationChart?.options}
                    series={locationChart?.series}
                    type="bar"
                    width="100%"
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>

              <Modal
                show={devices}
                onHide={handledevicesClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Devices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className="mb-3">
                    Cumulative total of OS : {oSCount || 0}
                  </h5>

                  {/* iOS Chart */}
                  <Chart
                    options={iOSChart?.options}
                    series={iOSChart?.series}
                    type="bar"
                    width="100%"
                  />
                  {/* Android chart */}
                  <Chart
                    options={AndroidChart?.options}
                    series={AndroidChart?.series}
                    type="bar"
                    width="100%"
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Dashboard;
