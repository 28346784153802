// export const BASE_URL = "https://apiquran.deenlearning.in:3000/api/admin/";
export const BASE_URL = "http://203.129.225.99:3003/api/admin/";
// export const BASE_URL = "http://192.168.1.39:3000/api/admin/";
export const FILE_BASE_URL = "https://apiquran.deenlearning.in:3000/";
export const FILE_BASE_URL_VERSE = "https://apiquran.deenlearning.in:3000";
const BASE_URL_download = "https://apiquran.deenlearning.in:3006/api/admin/";
 const NOORANIQAIDAH= "nooraniQaidah/" 
const GOOGLE_ANALYTICS = "dashboard/analytics"

const ApiConfig = {
  BASE_URL_download,
  BASE_URL_FOR_IMAGES: "https://apiquran.deenlearning.in:3000/api/admin/",
  FILE_BASE_URL : FILE_BASE_URL,
  FILE_BASE_URL_VERSE : FILE_BASE_URL_VERSE,
  BASE_URL: BASE_URL,
  LOGIN: BASE_URL + "login",
  DASHBOARD: BASE_URL + "dashboard/appContentCount",
  SUBAQADD: BASE_URL +NOORANIQAIDAH+"addSabaq",
  SUBAQUPDATENORANI: BASE_URL + NOORANIQAIDAH+"updateSabaq",
  SUBAQADDHIFZ: BASE_URL +"hifzeSurah/addSurah",
  SUBAQUPDATHIFZ: BASE_URL + "hifzeSurah/updateSurah",
  SUBAQADDALL: BASE_URL +"addSabaq",
  SUBAQUPDATE: BASE_URL + "updateSabaq/",
  SUBAQLIST: BASE_URL + "sabaqList",
  EDIT: BASE_URL +NOORANIQAIDAH+ "subSabaqList",
  EDITALL: BASE_URL + "subSabaqList",
  EDITHIFZ: BASE_URL + "hifzeSurah/ayahList",
  DELETEAPI: BASE_URL +"deleteSabaq",
  NOTIFICATION: BASE_URL +"notification/",
  // Google Analytics
  // Dashboard
GET_GOOGLE_ANALYTICS : BASE_URL + GOOGLE_ANALYTICS,
GET_MODULE_LIST : BASE_URL + "modules-list",
GET_SECTION_CATEGORY_LIST : BASE_URL + "sectionsCategories-list",
GET_ALL_SABAQ : BASE_URL + "sabaq-list?",


VIEW_ALL_SABAQ : BASE_URL + "/view-sabaq?",
ADD_SABAQ : BASE_URL + "noorani-qaidah/add-sabaq",
DELETE_SABAQ : BASE_URL + "/delete-sabaq",
UPDATE_SABAQ : BASE_URL + "noorani-qaidah/update-sabaq",

VIEW_HIFZE_SURAH : BASE_URL + "hifze-surah/view-surah?",
ADD_SURAH : BASE_URL + "hifze-surah/add-surah",
UPDATE_SURAH : BASE_URL + "hifze-surah/update-surah",
DELETE_SURAH : BASE_URL + "delete-sabaq",

ADD_SABAQ_SECTION : BASE_URL + "add-sabaq",
UPDATE_SABAQ_SECTION : BASE_URL + "update-sabaq",
VIEW_SABAQ_SECTION : BASE_URL + "view-sabaq?",
DELETE_SABAQ_SECION : BASE_URL + "delete-sabaq",

// Reciters 
GET_ALL_RECITERS : BASE_URL + "reciters-list",
ADD_RECITER: BASE_URL + "add-reciter",
UPDATE_RECITER : BASE_URL + "update-reciter",
DELETE_RECITER : BASE_URL + "delete-reciter",

// Ouran e kareem
GET_ALL_JUZ : BASE_URL + "quraanEKareem/juz-list?",
ADD_QUARAN_DATA:BASE_URL + "quraanEKareem/add-juz",
DELETE_JUZ:BASE_URL + 'quraanEKareem/delete-juz?juzId=',
GET_ALL_SURAH : BASE_URL + "quraanEKareem/surah-list",
UPDATEDQUAARAN:BASE_URL+"quraanEKareem/update-juz",

// SURAH
ADDSURAH:BASE_URL+'quraanEKareem/add-surah',
UPDATESURAH:BASE_URL + "quraanEKareem/update-surah",
DELETESURAH:BASE_URL +"quraanEKareem/delete-surah?SurahId=",



// Juz 
PAGE_LIST_BY_JUZ : BASE_URL + "quraanEKareem/juz-page-list?",
ADD_JUZ_PAGE : BASE_URL + "quraanEKareem/add-juz-page",
GET_EDIT_PAGE : BASE_URL + "quraanEKareem/juz-page-view?",
UPDATE_JUZ_PAGE : BASE_URL + "quraanEKareem/update-juz-page",
DELETE_JUZ_PAGE : BASE_URL + "quraanEKareem/delete-juz-page",

// /quraanEKareem/juz-verses-list?juzId=1&verseType=15&reciterId=1&searchVal=1
// Reciters
VERSE_LIST_BY_RECITER : BASE_URL + "quraanEKareem/juz-verses-list?",
UPDATE_VERSE_AUDIO : BASE_URL + "quraanEKareem/update-verse",

// huruf 
HARF_LIST : BASE_URL + "harf-list",

};
export default ApiConfig;