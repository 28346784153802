import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { PostCallWithErrorResponse } from "../api/ApiServices";
import { AppContext } from "../context/AppContext";
import circle_logo from "../assets/images/circle_logo.png";
import { ToastMsg } from "./TostMsg";
const Login = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const { sidebar, adminData, setAdminData } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false);
  const [adminDetails, setAdminDetails] = useState({
    emailId: "",
    password: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

    } else {
      logInApiCall();
    }
    setValidated(true);
  };

  const logInApiCall = () => {
    setLoader(true)
    PostCallWithErrorResponse(
      ApiConfig.LOGIN,
      JSON.stringify({ ...adminDetails })
    )
      .then((res) => {
        setIsLoading(true);
        setLoader(false)
        
        if (res.json.success === true) {

          setAdminData({
            authorization: res.json.data.token,
            user_id: res.json.data.user_id,
          });
          localStorage.setItem("authorization", res.json.data.token);
          localStorage.setItem("user_id", res.json.data.user_id);
          localStorage.setItem("logedIn", true);
          setLoggedIn(true);
          setIsLoading(false);
            //  ToastMsg("success", res.json.message);
       
        } else {
          setIsLoading(false);
             ToastMsg("error", res.json.message);
     
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <main className="main-login">
      <div className="wrapper row">
        <div className="left col-md-8">
          <div className="logo">
            <img src={circle_logo} alt="" />
          </div>
        </div>
        <div className="right col-md-4">
          <div className="inner-form">
            <div className="text-center mb-4 heading-login">
              <h2>Welcome</h2>
              <label htmlFor="">Please enter your credentials</label>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="mb-3">
                <Form.Control
                  required
                  type="email"
                  onChange={(e) =>
                    setAdminDetails({
                      ...adminDetails,
                      emailId: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  placeholder="Email Address"
                >
                  Please Enter Your Email.
                </Form.Control.Feedback>
              </div>
              <div className="mb-4">
                <Form.Control
                  required
                  type="password"
                  onChange={(e) =>
                    setAdminDetails({
                      ...adminDetails,
                      password: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your password.
                </Form.Control.Feedback>
              </div>
              <div className="login-btn-main ">




                <div className="innerLink">
                  <button className="cx-btn-1" type="submit">
                    {loader ? (
                      <div
                        className="spinner-border mx-auto mt-2"
                        style={{
                          color: "#fff",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    ) : (
                      <> Login</>
                    )}

                  </button>
                </div>


              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
