import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules

import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";
// import ApiConfig from "../api/ApiConfig";
import ApiConfig, { FILE_BASE_URL } from "../../api/ApiConfig";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  multipartPutCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Pagination } from "../Pagination";
import FormLoader from "../FormLoader";
import HifzESurah from "../HifzESurah";
import NoraaniQaaidah from "../NoraaniQaaidah";
import DuaSunnat from "../DuaSunnat";
import Aqaaid from "../Aqaaid";
import Namaaz from "../Namaaz";
import Masaail from "../Masaail";
import Seerat from "../Seerat";

// import {
//   DeleteCallWithErrorResponse,
//   multipartPostCallWithErrorResponse,

//   PostCallWithAuthTokenWithErrorResponse,
//   putMultipartWithAuthCallWithErrorResponse,
//   simpleGetCallWithErrorResponse,
// } from "../api/ApiServices";

const FifthYear = () => {
  let module_id;
  let course_yr_id;
  let category_id;
  let course_year;

  if (typeof window !== undefined) {
    module_id = localStorage.getItem("module_id");
    course_yr_id = localStorage.getItem("course_yr_id");
    category_id = localStorage.getItem("category_id");
    course_year = localStorage.getItem("course_year_module_one");
  }

  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    Tarjama: "100%",
    Description: "100%",
  });

  const [add, setAdd] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [saveBotton, setSaveBotton] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [viewModal, setView] = useState(false);
  const handleViewClose = () => {
    setView(false);
  };
  const handleViewShow = () => setView(true);

  const handleAddClose = (test) => {
    setAdd(false);
    setIdEdit(test);
    setAddSabaq({
      sabaqName: "",
      sabaqAudio: "",
      sabaqDesc: "",
      sabaqIcon: [],
      sabaqDescAudio: "",
      sabaqTeacherAudio: '',
      sabaqTeacherDesc: "",
      subSabaqs: [
        {
          subSabaqTitle: "",
          titleAudioPath: "",
          subSabaqDesc: "",
          descAudioPath: "",
          descReference: "",
          tarjama: "",
          tarjamaAudioPath: "",
          titleTeacherAudioPath: "",
          teacherDescAudioPath: "",
          teacherTarjamaAudioPath: "",
          mashqs: [
            {
              mashq: "",
              mashqAudioPath: "",
            },
          ],
        },
      ],
    });
  };

  const [duaaList, setDuaaList] = useState([]);
  const [lasatSabaqId, setLsatSabaq] = useState("");

  const [nooraniQaaidaNumber, setNooraniQaaidaNumber] = useState();
  const [idEdit, setIdEdit] = useState("");
  const [ditData, setEditData] = useState(false);
  console.log("ISEDIT>>>>>>>", idEdit);

  console.log("NooraniQaaidaNumber", nooraniQaaidaNumber);
  const { sidebar, adminData, setEvent } = useContext(AppContext);
  const [loader, setLoader] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Delete, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [sectionCatList, setsectionCatList] = useState([]);
  // const [activeCategory, setActiveCategory] = useState(
  //   localStorage.getItem("category_id") || "1"
  // );

  const [validated, setValidated] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(duaaList?.length / 15);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addSabaqApi();
    }

    setValidated(true);
  };

  // const getSectionsCategoriesList = () => {
  //   // setIsLoading(true);

  //   simpleGetCallWithErrorResponse(ApiConfig.GET_SECTION_CATEGORY_LIST)
  //     .then((res) => {
  //       // setLoader(false);
  //       console.log("RESS", res);
  //       if (res.json && res.json?.success === true) {
  //         setsectionCatList(res.json.data);
  //       } else {
  //         ToastMsg("error", res.json.message);
  //       }
  //     })
  //     .catch((err) => {
  //       ToastMsg("error", "something went wrong");
  //     });
  // };

  // const handleTabClick = (categoryID) => {
  //   console.log("ID>>>>>>>>>>>>>>>", categoryID);
  //   setActiveCategory(categoryID);
  //   // localStorage.setItem("category_id", categoryID);
  // };

  // useEffect(() => {
  //   getSectionsCategoriesList();
  //   // duaaListApi();
  // }, []);

  const duaaListApi = (cateId) => {

    // const apiUrl = module_id !== 1 || 2
    // ? ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${2}`
    // : ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${2}`;
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_ALL_SABAQ +
      `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${cateId || 1}`
    )
      // simpleGetCallWithErrorResponse(apiUrl)
      .then((res) => {
        console.log("RESS 1st year :", res)
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setDuaaList(res.json.data.allSabaq);

          setLsatSabaq(res.json?.data.allSabaq.length);
        } else {
          // ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  //  For new Crud
  const [addSabaq, setAddSabaq] = useState({
    sabaqName: "",
    sabaqAudio: "",
    sabaqDesc: "",
    sabaqDescAudio: "",
    sabaqDescReference: "",
    sabaqTeacherAudio: '',
    sabaqTeacherDesc: "",
    sabaqIcon: [],
    subSabaqs: [
      {
        subSabaqTitle: "",
        descReference: "",
        tarjama: "",
        tarjamaAudioPath: "",
        titleAudioPath: "",
        subSabaqDesc: "",
        descAudioPath: "",
        titleTeacherAudioPath: "",
        teacherDescAudioPath: "",
        teacherTarjamaAudioPath: "",
        mashqs: [
          {
            mashq: "",
            mashqAudioPath: "",
          },
        ],
      },
    ],
  });
  console.log("addSabaqOBJ", addSabaq);

  const handleAddShow = (set) => {
    if (set === "add") {
      setIdEdit("");
      setAddSabaq({
        sabaqName: "",
        sabaqAudio: "",
        sabaqIcon: [],
        sabaqDescReference: "",
        sabaqDesc: "",
        sabaqDescAudio: "",
        sabaqTeacherAudio: '',
        sabaqTeacherDesc: "",
        subSabaqs: [
          {
            subSabaqTitle: "",
            titleAudioPath: "",
            subSabaqDesc: "",
            descAudioPath: "",
            descReference: "",
            tarjama: "",
            tarjamaAudioPath: "",
            titleTeacherAudioPath: "",
            teacherDescAudioPath: "",
            teacherTarjamaAudioPath: "",
            mashqs: [
              {
                mashq: "",
                mashqAudioPath: "",
              },
            ],
          },
        ],
      });
    }
    setAdd(true);
  };

  const addSabaqApi = () => {
    setSaveBotton(true);
    handleAddClose();
    setLoader(true);
    const body = {
      moduleId: module_id || 1,
      courseYrId: course_yr_id || 0,
      categoryId: category_id,
      userId: Number(adminData.user_id),
      sabaqName: addSabaq.sabaqName,
      sabaqDesc: addSabaq.sabaqDesc,
      sabaqDescReference: addSabaq.sabaqDescReference,
      sabaqNo: lasatSabaqId + 1,
      sabaqId: addSabaq.SabaqId,
      sabaqIcon: addSabaq.sabaqIcon,
      // sabaqId:lsatSabaqId
    };
    let payLoadFormData = new FormData();
    payLoadFormData.append("moduleId", body.moduleId);
    payLoadFormData.append("courseYrId", body.courseYrId);
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqName", body.sabaqName ? body.sabaqName : "");
    payLoadFormData.append("sabaqIcon", body.sabaqIcon ? body.sabaqIcon : "");
    // payLoadFormData.append("sabaqNo", body.sabaqNo);
    payLoadFormData.append("sabaqDesc", body.sabaqDesc ? body.sabaqDesc : "");
    payLoadFormData.append("sabaqDescReference", body.sabaqDescReference ? body.sabaqDescReference : "");
    let subSabaq =
    addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele, index) => {
      return {
        subSabaqTitle: ele.subSabaqTitle,
        subSabaqTitleAudio:
          ele.titleAudioPath == "" ? "no file" : "file name",
        subSabaqDescAudio: ele.descAudioPath == "" ? "no file" : "file name",
        subSabaqDesc: ele.subSabaqDesc,
        subSabaqNo: index + 1,
        subSabaqDescReference: ele.descReference ? ele.descReference : "",
        subSabaqTarjama: ele.tarjama ? ele.tarjama : "",
        subSabaqTarjamaAudio: ele.tarjamaAudioPath == "" ? "no file" : "file name",
        subSabaqTitleTeacherAudio: ele.titleTeacherAudioPath == "" ? "no file" : "file name",
        subSabaqDescTeacherAudio: ele.teacherDescAudioPath == "" ? "no file" : "file name",
        subSabaqTarjamaTeacherAudio: ele.teacherTarjamaAudioPath == "" ? "no file" : "file name",
        mashqs: ele?.mashqs?.map((elesub) => {
          return {
            mashq: elesub.mashq,
            mashqAudio: elesub.mashqAudioPath == "" ? "no file" : "file name",
          };
        }),
      };
    });
  let subSabaqEDIT =
    addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele, index) => {
      console.log("ele", ele);
      return {
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
        subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
        subSabaqTitleAudio: typeof ele.titleAudioPath == "object" ? "updated" : "not updated",
        subSabaqDescAudio: typeof ele.descAudioPath == "object" ? "updated" : "not updated",
        // subSabaqNo: ele.subSabaqNo,
        subSabaqNo: index + 1,
        subSabaqDescReference: ele.descReference ? ele.descReference : "",
        subSabaqTarjama: ele.tarjama ? ele.tarjama : "",
        subSabaqTarjamaAudio: typeof ele.tarjamaAudioPath == "object" ? "updated" : "not updated",
        subSabaqTitleTeacherAudio: typeof ele.titleTeacherAudioPath == "object" ? "updated" : "not updated",
        subSabaqDescTeacherAudio: typeof ele.teacherDescAudioPath == "object" ? "updated" : "not updated",
        subSabaqTarjamaTeacherAudio: typeof ele.teacherTarjamaAudioPath == "object" ? "updated" : "not updated",

        subSabaqId: ele.subSabaqId,
        mashqs: ele?.mashqs?.map((elesub) => {
          return {
            mashq: elesub.mashq ? elesub.mashq : "",
            mashqId: elesub.mashqId,
            mashqAudio:
              typeof elesub.mashqAudioPath == "object"
                ? "updated"
                : "not updated",
          };
        }),
      };
    });

    idEdit === "edit"
      ? payLoadFormData.append("sabaqId", body.sabaqId ? body.sabaqId : "")
      : payLoadFormData.append("sabaqNo", body.sabaqNo ? body.sabaqNo : "");

    // payLoadFormData.append("categoryName", body.categoryName);
  

    let newSubaqAudio = [];
    newSubaqAudio.push(addSabaq.sabaqAudio);

    let newSubaqDesceAudio = [];
    newSubaqDesceAudio.push(addSabaq.sabaqDescAudio);

    let newSubaqTeacherAudio = [];
    newSubaqTeacherAudio.push(addSabaq.sabaqTeacherAudio);

    let newSubaqDesceTeacherAudio = [];
    newSubaqDesceTeacherAudio.push(addSabaq.sabaqTeacherDesc);
    //descreption mashqAudioPath
    newSubaqDesceAudio.map((ele) =>
      payLoadFormData.append("sabaqDescAudio", ele ? ele : "")
    );

    //subaq mashqAudioPath
    newSubaqAudio.map((ele) =>
      payLoadFormData.append("sabaqNameAudio", ele ? ele : "")
    );

    newSubaqDesceTeacherAudio.map((ele) =>
      payLoadFormData.append("sabaqDescTeacherAudio", ele ? ele : "")
    );
    //subaq mashqAudioPath
    newSubaqTeacherAudio.map((ele) =>
      payLoadFormData.append("sabaqNameTeacherAudio", ele ? ele : "")
    );


    addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqTitleAudio",
        ele.titleAudioPath ? ele.titleAudioPath : ""
      )
    );
  //
  addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqDescAudio",
        ele.descAudioPath ? ele.descAudioPath : ""
      )
    );

  addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqTitleTeacherAudio",
        ele.titleTeacherAudioPath ? ele.titleTeacherAudioPath : ""
      )
    );
  addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqDescTeacherAudio",
        ele.teacherDescAudioPath ? ele.teacherDescAudioPath : ""
      )
    );

  addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqTarjamaTeacherAudio",
        ele.teacherTarjamaAudioPath ? ele.teacherTarjamaAudioPath : ""
      ))

  addSabaq?.subSabaqs?.length > 0 &&
    addSabaq?.subSabaqs?.map((ele, index) =>
      ele?.mashqs?.map((audiodata) =>
        payLoadFormData.append(
          "mashqAudio",
          audiodata.mashqAudioPath ? audiodata.mashqAudioPath : ""
        )
      )
    );


  // addSabaq?.subSabaqs?.length > 0 &&
  // addSabaq.subSabaqs.map((ele) =>
  //   payLoadFormData.append(
  //     "subSabaqDescReference",
  //     ele.subSabaqDescReference ? ele.subSabaqDescReference : ""
  //   )
  // );

  // addSabaq?.subSabaqs?.length > 0 &&
  // addSabaq.subSabaqs.map((ele) =>
  //   payLoadFormData.append(
  //     "subSabaqTarjama",
  //     ele.subSabaqTarjama ? ele.subSabaqTarjama : ""
  //   )
  // );

  addSabaq?.subSabaqs?.length > 0 &&
    addSabaq.subSabaqs.map((ele) =>
      payLoadFormData.append(
        "subSabaqTarjamaAudio",
        ele.tarjamaAudioPath ? ele.tarjamaAudioPath : ""
      )
    );
    // console.log(JSON.parse(payLoadFormData.getAll("sabaqDescAudio")),"testing data")
    //new


    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEDIT))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaq));
    // console.log(subSabaqEDIT,"new file")

    // setIsLoading(true);
    idEdit === "edit"
      ? multipartPutCallWithErrorResponse(
        category_id == 4 || 9 || 10 || 12 || 13
          ? ApiConfig.UPDATE_SABAQ_SECTION
          : ApiConfig.UPDATE_SABAQ,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);

            // SabaqListApi();
            duaaListApi(category_id);
            setIdEdit("");
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          // ToastMsg("error", "something went wrong");
        })
      : // : multipartPostCallWithErrorResponse(ApiConfig.SUBAQADD, payLoadFormData)
      multipartPostCallWithErrorResponse(
        category_id == 4 || 9 || 10 || 12 || 13
          ? ApiConfig.ADD_SABAQ_SECTION
          : ApiConfig.ADD_SABAQ,
        payLoadFormData
      )
        .then((res) => {
          setLoader(false);

          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);

            // SabaqListApi();
            duaaListApi(category_id);
            setIdEdit("");
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          // ToastMsg("error", "something went wrong");
        });
  };

  const EditListApi = (id) => {
    console.log("SABAQ IDDDD", id);
    simpleGetCallWithErrorResponse(
      ApiConfig.VIEW_ALL_SABAQ + `sabaqId=${id}&categoryId=${category_id}`
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setAddSabaq(res.json.data);
          console.log("DATA RECIVED :", res.json.data);

          handleAddShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const viewDetailshandle = (id) => {
    // simpleGetCallWithErrorResponse(ApiConfig.EDIT + `?sabaqId=${id}`)
    simpleGetCallWithErrorResponse(
      ApiConfig.VIEW_ALL_SABAQ + `sabaqId=${id}&categoryId=${category_id}`
    )
      .then((res) => {
        setLoader(false);
        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const SabaqLisDeleteApi = () => {
    handleDeleteClose();
    const body = {
      categoryId: category_id,
      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_SABAQ,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);

          // SabaqListApi();
          duaaListApi(category_id);

          // setIsDeleteList(true);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">
            Deeniyat Syllabus <span>(Daily {module_id == 1 ? "60" : "40"} Minutes)</span>{" "}
          </label>
          <br />
          <label htmlFor="">Fifth Year</label>
          <br />
        </div>
        {/* <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Sabaq
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div> */}
      </div>

      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Nav variant="pills" className="flex-row">
          {/* {
            sectionCatList.length > 0 ?
              sectionCatList.map(item => {
                return (
                  <Nav.Item >
                    <Nav.Link
                      eventKey={item.sectionId}
                      // onClick={() => {
                      //   setEventKey(item.sectionId);
                      //   setEventtitle(item.category_name);
                      // }}
                    >
                      {item.sectionName}
                    </Nav.Link>
                  </Nav.Item>
                )
              })
              : null
          } */}

          {/* {sectionCatList?.map((section) => (
            <Nav.Item key={section.sectionId}>
              <Nav.Link
                eventKey={section.sectionId.toString()}
                onClick={() => handleTabClick(section.sectionId.toString())}
                active={activeCategory === section.sectionId.toString()}
              >
                {section.sectionName}
              </Nav.Link>
            </Nav.Item>
          ))} */}

          <Nav.Item>
            <Nav.Link
              eventKey="1"
              onClick={() => {
                localStorage.setItem("category_id", 1);
              }}
            >
              Qur'aan
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="2"
              onClick={() => {
                localStorage.setItem("category_id", 3);
              }}
            >
              Hadees
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="3"
              onClick={() => {
                localStorage.setItem("category_id", 5);
              }}
            >
              Aqaaid -o- Masaail
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            onClick={() => {
              localStorage.setItem("category_id", 9);
            }}
          >
            <Nav.Link eventKey="4">Islaami Tarbiyat</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="5"
              onClick={() => {
                localStorage.setItem("category_id", 13);
              }}
            >
              Zaban
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          {/*   ====== Quran ===== */}
          <Tab.Pane eventKey="1">
            <div className="jobDescriptio-main">
              <div className="wrapper">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="1"
                // defaultActiveKey={activeCategory}
                >
                  <div className="row">
                    <div className="">
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 1);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="1">Noorani Qaaida</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 2);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="2">Hifze Surah</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="">
                      {/* ==== Quraan ==== */}
                      <Tab.Content className="Syllabus_Custom_Tabs">
                        <Tab.Pane eventKey="1">
                          <NoraaniQaaidah key={nooraniQaaidaNumber} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <HifzESurah key={nooraniQaaidaNumber} />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </Tab.Pane>
          {/* ======= Hadees ======= */}
          <Tab.Pane eventKey="2">
            <div className="jobDescriptio-main">
              <div className="wrapper">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row">
                    <div className="">
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 3);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="1">Dua wa Sunnat</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 4);
                            duaaListApi(4);
                          }}
                        >
                          <Nav.Link eventKey="2">Hifze Hadees</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="">
                      <Tab.Content className="Syllabus_Custom_Tabs">
                        {/* ==== Hadees ==== */}
                        <Tab.Pane eventKey="1">
                          <div className="row jd-main">
                            <DuaSunnat key={nooraniQaaidaNumber} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>

                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() =>
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}
                            </main>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </Tab.Pane>
          {/* ====== Aqaaid -o- Masaail ======= */}
          <Tab.Pane eventKey="3">
            <div className="jobDescriptio-main">
              <div className="wrapper">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row">
                    <div className="">
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 5);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="1">Aqaaid</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 6);
                            // setNooraniQaaidaNumber(Math.random() * 100);
                            duaaListApi(6);
                          }}
                        >
                          <Nav.Link eventKey="2">Asma-e-Husna</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 7);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="3">Namaaz</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 8);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="4">Masail</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="">
                      <Tab.Content className="Syllabus_Custom_Tabs">
                        {/* ==== Aqaaid -o- Masaail ==== */}
                        <Tab.Pane eventKey="1">
                          <div className="row jd-main">
                            <Aqaaid key={nooraniQaaidaNumber} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() =>
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}
                            </main>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                          <div className="row jd-main">
                            <Namaaz key={nooraniQaaidaNumber} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                          <div className="row jd-main">
                            <Masaail key={nooraniQaaidaNumber} />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </Tab.Pane>
          {/* ============ Islaami Tarbiyat ========== */}
          <Tab.Pane eventKey="4">
            <div className="jobDescriptio-main">
              <div className="wrapper">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row">
                    <div className="">
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 9);
                            duaaListApi(9);
                          }}
                        >
                          <Nav.Link eventKey="1">Islaami Maaloomaat </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 10);
                            duaaListApi(10);
                          }}
                        >
                          <Nav.Link eventKey="2">Bayaan wa dua</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 11);
                            setNooraniQaaidaNumber(Math.random() * 100);
                          }}
                        >
                          <Nav.Link eventKey="3">Seerat</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 12);
                            duaaListApi(12);
                          }}
                        >
                          <Nav.Link eventKey="4">Akhlaaqi Ta'aleem</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="">
                      <Tab.Content className="Syllabus_Custom_Tabs">
                        {/* ==== Islaami Tarbiyat ==== */}

                        <Tab.Pane eventKey="1">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>

                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() =>
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}
                            </main>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() =>
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}


                            </main>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                          <div className="row jd-main">
                            <Seerat key={nooraniQaaidaNumber} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>

                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() =>
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* <div className="table-wrapper">
                                <h6>Total Records : 34</h6>
                                <table className="display table table-lg table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>Sabaq No.</th>
                                      <th style={{ textAlign: "center" }}>
                                        Sabaq List
                                      </th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td style={{ textAlign: "center" }}>
                                        Sabaq 1
                                      </td>
                                      <td className="action">
                                        <Link
                                          className="icon edit"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_edit} alt="" />
                                        </Link>
                                        <Link
                                          className="icon view"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_eyes} alt="" />
                                        </Link>
                                        <Link
                                          className="icon delete"
                                          href="/FifthYear"
                                          onClick={handleDeleteShow}
                                        >
                                          <img src={ic_delete} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td style={{ textAlign: "center" }}>
                                        Sabaq 2
                                      </td>
                                      <td className="action">
                                        <Link
                                          className="icon edit"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_edit} alt="" />
                                        </Link>
                                        <Link
                                          className="icon view"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_eyes} alt="" />
                                        </Link>
                                        <Link
                                          className="icon delete"
                                          href="/FifthYear"
                                          onClick={handleDeleteShow}
                                        >
                                          <img src={ic_delete} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td style={{ textAlign: "center" }}>
                                        Sabaq 3
                                      </td>
                                      <td className="action">
                                        <Link
                                          className="icon edit"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_edit} alt="" />
                                        </Link>
                                        <Link
                                          className="icon view"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_eyes} alt="" />
                                        </Link>
                                        <Link
                                          className="icon delete"
                                          href="/FifthYear"
                                          onClick={handleDeleteShow}
                                        >
                                          <img src={ic_delete} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="row mt-4">
                                  <div className="col-lg-12">
                                    <ul
                                      className="paginationBttns"
                                      role="navigation"
                                      aria-label="Pagination"
                                    >
                                      <li className="previous paginationDisabled">
                                        <Link
                                          className="previousBttn"
                                          // tabIndex={-1}
                                          // role="button"
                                          // aria-disabled="true"
                                          // aria-label="Previous page"
                                          // rel="prev"
                                        >
                                          Previous
                                        </Link>
                                      </li>
                                      <li className="paginationActive">
                                        <Link
                                          rel="canonical"
                                          role="button"
                                          tabIndex={-1}
                                          aria-label="Page 1 is your current page"
                                          aria-current="page"
                                        >
                                          1
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          rel="next"
                                          role="button"
                                          tabIndex={0}
                                          aria-label="Page 2"
                                        >
                                          2
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          role="button"
                                          tabIndex={0}
                                          aria-label="Page 3"
                                        >
                                          3
                                        </Link>
                                      </li>
                                      <li className="next">
                                        <Link
                                          className="nextBttn"
                                          tabIndex={0}
                                          role="button"
                                          aria-disabled="false"
                                          aria-label="Next page"
                                          rel="next"
                                        >
                                          Next
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </main>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </Tab.Pane>
          {/* ============ Zaban ========== */}
          <Tab.Pane eventKey="5">
            <div className="jobDescriptio-main">
              <div className="wrapper">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row">
                    <div className="">
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 13);
                            duaaListApi(13);
                          }}
                        >
                          <Nav.Link eventKey="1">Arabi</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          onClick={() => {
                            localStorage.setItem("category_id", 14);
                            duaaListApi(14);
                          }}
                        >
                          <Nav.Link eventKey="2">Urdu</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="">
                      <Tab.Content className="Syllabus_Custom_Tabs">
                        {/* ==== Zaban ==== */}

                        <Tab.Pane eventKey="1">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>

                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() =>
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* <div className="table-wrapper">
                                <h6>Total Records : 34</h6>
                                <table className="display table table-lg table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>Sabaq No.</th>
                                      <th style={{ textAlign: "center" }}>
                                        Sabaq List
                                      </th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td style={{ textAlign: "center" }}>
                                        Sabaq 1
                                      </td>
                                      <td className="action">
                                        <Link
                                          className="icon edit"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_edit} alt="" />
                                        </Link>
                                        <Link
                                          className="icon view"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_eyes} alt="" />
                                        </Link>
                                        <Link
                                          className="icon delete"
                                          href="/FifthYear"
                                          onClick={handleDeleteShow}
                                        >
                                          <img src={ic_delete} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td style={{ textAlign: "center" }}>
                                        Sabaq 2
                                      </td>
                                      <td className="action">
                                        <Link
                                          className="icon edit"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_edit} alt="" />
                                        </Link>
                                        <Link
                                          className="icon view"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_eyes} alt="" />
                                        </Link>
                                        <Link
                                          className="icon delete"
                                          href="/FifthYear"
                                          onClick={handleDeleteShow}
                                        >
                                          <img src={ic_delete} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td style={{ textAlign: "center" }}>
                                        Sabaq 3
                                      </td>
                                      <td className="action">
                                        <Link
                                          className="icon edit"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_edit} alt="" />
                                        </Link>
                                        <Link
                                          className="icon view"
                                          href="/FifthYear"
                                        >
                                          <img src={ic_eyes} alt="" />
                                        </Link>
                                        <Link
                                          className="icon delete"
                                          href="/FifthYear"
                                          onClick={handleDeleteShow}
                                        >
                                          <img src={ic_delete} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="row mt-4">
                                  <div className="col-lg-12">
                                    <ul
                                      className="paginationBttns"
                                      role="navigation"
                                      aria-label="Pagination"
                                    >
                                      <li className="previous paginationDisabled">
                                        <Link
                                          className="previousBttn"
                                        // tabIndex={-1}
                                        // role="button"
                                        // aria-disabled="true"
                                        // aria-label="Previous page"
                                        // rel="prev"
                                        >
                                          Previous
                                        </Link>
                                      </li>
                                      <li className="paginationActive">
                                        <Link
                                          rel="canonical"
                                          role="button"
                                          tabIndex={-1}
                                          aria-label="Page 1 is your current page"
                                          aria-current="page"
                                        >
                                          1
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          rel="next"
                                          role="button"
                                          tabIndex={0}
                                          aria-label="Page 2"
                                        >
                                          2
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          role="button"
                                          tabIndex={0}
                                          aria-label="Page 3"
                                        >
                                          3
                                        </Link>
                                      </li>
                                      <li className="next">
                                        <Link
                                          className="nextBttn"
                                          tabIndex={0}
                                          role="button"
                                          aria-disabled="false"
                                          aria-label="Next page"
                                          rel="next"
                                        >
                                          Next
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </main>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="row jd-main">
                            <main id="cx-main" className="admin-main">
                              <div className="heading-btn-main">
                                <div className="heading-common"></div>
                                <div className="add-btn">
                                  <button onClick={() => handleAddShow("add")}>
                                    Add Sabaq
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="white"
                                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>

                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="table-wrapper">
                                  <h6>Total Records : {duaaList.length}</h6>
                                  <Table
                                    className="display"
                                    striped
                                    bordered
                                    hover
                                    size="lg"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sabaq No.</th>
                                        <th style={{ textAlign: "center" }}>
                                          Sabaq List
                                        </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {duaaList && duaaList.length > 0
                                        ? duaaList
                                          .slice(startIndex, endIndex)
                                          .map((ele, index) => {
                                            return (
                                              <tr key={"duaaL" + index}>
                                                <td>
                                                  {index + 1 + startIndex}
                                                </td>

                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {ele.sabaqName}
                                                </td>
                                                <td className="action">
                                                  <Link
                                                    to="#"
                                                    className="icon edit"
                                                    onClick={() => {
                                                      // handleAddShow();
                                                      setIdEdit("edit");
                                                      setImageWidth({
                                                        ...imageWidth,
                                                        sabaqAudio: "117px",
                                                        Uploade: "117px",
                                                        Tarjama: "117px",
                                                        Description: "117px",
                                                      });
                                                      EditListApi(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_edit}
                                                      alt=""
                                                    />
                                                  </Link>

                                                  <Link
                                                    to="#"
                                                    className="icon view"
                                                    onClick={() => {
                                                      handleViewShow();
                                                      viewDetailshandle(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_eyes}
                                                      alt=""
                                                    />
                                                  </Link>

                                                  <Link
                                                    to="#"
                                                    className="icon delete"
                                                    onClick={() => {
                                                      handleDeleteShow();
                                                      setDeleteId(
                                                        ele.sabaqId
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={ic_delete}
                                                      alt=""
                                                    />
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </Table>
                                  {duaaList && duaaList.length > 0 ? (
                                    <Pagination
                                      changePage={changePage}
                                      pageCount={pageCount}
                                      currentPage={currentPage}
                                    />
                                  ) : (
                                    <div>
                                      <h5
                                        style={{
                                          color: "red",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        Records Not Found
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              )}
                            </main>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {/* ======================= START ADD ========================== */}
      {/* <Modal
        show={show}
        onHide={() => handleClose()}
        size="lg"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> {idEdit === "edit"
                ? "Update Sabaq"
                : "Add Sabaq"}</Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control required type="text" name="sabaqName"
                    value={addSabaq?.sabaqName}
                    onChange={(e) =>
                      setAddSabaq({ ...addSabaq, sabaqName: e.target.value })
                    }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 ">
                <Form.Label> Sabaq Audio</Form.Label>
              </div>
              <div className="col-md-12 mb-3 ">
                <Form.Control
                  type="file"
                  accept="audio/mp3,audio/*;capture=microphone"
                  name="sabaqAudio"
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              <div className="col-md-12 mb-3">
                <Form.Label>Arabic Hadees Text</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  name="sabaqDesc"
                value={addSabaq?.sabaqDesc}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Tarjama.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 ">
                <Form.Label> Arabic Hadees Audio</Form.Label>
              </div>
              <div className="col-md-12 mb-3 ">
                <Form.Control
                  type="file"
                  accept="audio/mp3,audio/*;capture=microphone"
                  name="sabaqAudio"
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Hadees Reference</Form.Label>
                <Form.Control required type="text" name="sabaqName" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 mb-3">
                <Form.Label>Hadees Tarjama Text</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  name="sabaqDesc"
                value={addSabaq?.sabaqDesc}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Tarjama.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 ">
                <Form.Label> Hadees Tarjama Audio</Form.Label>
              </div>
              <div className="col-md-12 mb-3 ">
                <Form.Control
                  type="file"
                  accept="audio/mp3,audio/*;capture=microphone"
                  name="sabaqAudio"
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>
            </div>
            <Link to="#" className={"add-newSabaq-btn btn btn-primary"}>
              + Add Sub Sabaq Details
            </Link>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                Add
              </button>
              <Link to="#" className=" bg-danger">
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal> */}

      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={() => handleAddClose()}
        size="lg"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {idEdit === "edit" ? "Update Sabaq" : "Add Sabaq"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} className="fw-bold" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="sabaqName"
                  value={addSabaq?.sabaqName}
                  onChange={(e) =>
                    setAddSabaq({ ...addSabaq, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              {/* ADDD */}
              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio(Teacher And Student)
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addSabaq?.sabaqAudio === "string"
                          ? addSabaq?.sabaqAudio.substring(
                            addSabaq?.sabaqAudio.length - 15,
                            addSabaq?.sabaqAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaq }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaq: "100%" });
                          setAddSabaq({
                            ...addSabaq,
                            sabaqAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio(Teacher And Student)</Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    src={addSabaq?.sabaqAudio}
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) => {
                      setAddSabaq({
                        ...addSabaq,
                        sabaqAudio: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio(Teacher)
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addSabaq?.sabaqTeacherAudio === "string"
                          ? addSabaq?.sabaqTeacherAudio.substring(
                            addSabaq?.sabaqTeacherAudio.length - 15,
                            addSabaq?.sabaqTeacherAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaq }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaq: "100%" });
                          setAddSabaq({
                            ...addSabaq,
                            sabaqTeacherAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio(Teacher)</Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    src={addSabaq?.sabaqAudio}
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) => {
                      setAddSabaq({
                        ...addSabaq,
                        sabaqTeacherAudio: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {/* sabaqicon */}
              {/* 
              {idEdit == "edit" ? (
                <>
                <label>Sabaq Icon</label>
                  <MDBRow>
                    <MDBCol lg="1" md="1" className="mb-4">
                      <img
                        src={
                          !addSabaq?.sabaqIcon
                            ? ""
                            : ApiConfig.FILE_BASE_URL +
                              addSabaq?.sabaqIcon.length
                            ? ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon
                            : ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon &&
                              URL.createObjectURL(
                                ApiConfig.FILE_BASE_URL + addSabaq?.sabaqIcon
                              )
                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                  <div className="col-md-12 mb-3">
                      <Form.Label>Update Sabaq Icon</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="updateSabaqIcon"
                        onChange={(e) => {
                          setAddSabaq({
                            ...addSabaq,
                            sabaqIcon: e.target.files[0],
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Icon
                      </Form.Control.Feedback>
                    </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Icon</Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    // accept="audio/mp3,audio/*;capture=microphone"
                    accept="image/*"
                    name="sabaqAudio"
                    src={addSabaq?.sabaqIcon}
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) => {
                      setAddSabaq({
                        ...addSabaq,
                        sabaqIcon: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Icon
                  </Form.Control.Feedback>
                </div>
              )}
 */}
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Description</Form.Label>
                <Form.Control
                  // required
                  as="textarea"
                  rows={3}
                  name="sabaqDesc"
                  value={addSabaq?.sabaqDesc}
                  onChange={(e) =>
                    setAddSabaq({ ...addSabaq, sabaqDesc: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Tarjama.
                </Form.Control.Feedback>
              </div>
              {/* <div className="col-md-12 mb-3">
                  <Form.Label>Description Audio</Form.Label>
                  <Form.Control
                    type="file"
                    name="sabaqDescAudio"
                    // value={addSabaq?.sabaqDescAudio}
                    onChange={(e) =>
                      setAddSabaq({
                        ...addSabaq,
                        sabaqDescAudio: e.target.files[0],
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div> */}
              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Description Audio(Teacher And Student)
                      </label>
                    </div>
                    <div
                      className={
                        imageWidth === "100%" ? "invisible" : "col-md-6 "
                      }
                    >
                      <Form.Label>
                        {typeof addSabaq?.sabaqDescAudio === "string"
                          ? addSabaq?.sabaqDescAudio.substring(
                            addSabaq?.sabaqDescAudio.length - 15,
                            addSabaq?.sabaqDescAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.des }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, des: "100%" });
                          setAddSabaq({
                            ...addSabaq,
                            sabaqDescAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description Audio(Teacher And Student) </Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqDescAudio"
                    src={addSabaq?.sabaqAudio}
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) => {
                      setAddSabaq({
                        ...addSabaq,
                        sabaqDescAudio: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Description Audio(Teacher)
                      </label>
                    </div>
                    <div
                      className={
                        imageWidth === "100%" ? "invisible" : "col-md-6 "
                      }
                    >
                      <Form.Label>
                        {typeof addSabaq?.sabaqTeacherDesc === "string"
                          ? addSabaq?.sabaqTeacherDesc.substring(
                            addSabaq?.sabaqTeacherDesc.length - 15,
                            addSabaq?.sabaqTeacherDesc.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.des }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, des: "100%" });
                          setAddSabaq({
                            ...addSabaq,
                            sabaqTeacherDesc: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description Audio(Teacher) </Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqTeacherDesc"
                    src={addSabaq?.sabaqTeacherDesc}
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) => {
                      setAddSabaq({
                        ...addSabaq,
                        sabaqTeacherDesc: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Description Reference</Form.Label>
                <Form.Control
                  // required
                  type="text"
                  name="sabaqName"
                  value={addSabaq?.sabaqDescReference}
                  onChange={(e) =>
                    setAddSabaq({
                      ...addSabaq,
                      sabaqDescReference: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              {addSabaq?.subSabaqs &&
                addSabaq?.subSabaqs.map((item, index) => {
                  console.log("item of Form>>>> : ", item);
                  return (
                    <div
                      className="col-md-12 sub-group-main"
                      key={"noorani" + index}
                    >
                      <div className="row align-items-end">
                        <h6 className="fw-bold">
                          Sub Sabaq No. <span>{index + 1}</span>
                        </h6>
                        <div className="col-md-12 mb-3">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            value={item?.subSabaqTitle}
                            onChange={(e) => {
                              setAddSabaq({
                                ...addSabaq,
                                subSabaqs: addSabaq.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqTitle: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Audio File.
                          </Form.Control.Feedback>
                        </div>
                        {/* <div className="col-md-12 mb-3">
                            <Form.Label>Title Audio</Form.Label>
                            <Form.Control
                              type="file"
                              // value={item?.titleAudioPath}
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                            ...item,
                                            titleAudioPath: e.target.files[0],
                                          }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Choose Audio File.
                            </Form.Control.Feedback>
                          </div> */}

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Title Audio(Teacher And Student)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.titleAudioPath === "string"
                                    ? item?.titleAudioPath.substring(
                                      item?.titleAudioPath.length - 15,
                                      item?.titleAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.Title }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      Title: "100%",
                                    });
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              titleAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>Title Audio(Teacher And Student) </Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          titleAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Title Audio(Teacher)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.titleTeacherAudioPath === "string"
                                    ? item?.titleTeacherAudioPath.substring(
                                      item?.titleTeacherAudioPath.length - 15,
                                      item?.titleTeacherAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.Title }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      Title: "100%",
                                    });
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              titleTeacherAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>Title Audio(Teacher And Student) </Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          titleTeacherAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={item?.subSabaqDesc}
                            onChange={(e) => {
                              setAddSabaq({
                                ...addSabaq,
                                subSabaqs: addSabaq.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqDesc: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Tarjama.
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Description Audio(Teacher And Student)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.descAudioPath === "string"
                                    ? item?.descAudioPath.substring(
                                      item?.descAudioPath.length - 15,
                                      item?.descAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.subdesc }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subdesc: "100%",
                                    });
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              descAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>
                              {" "}
                              Sub Sabaq Description Audio(Teacher And Student){" "}
                            </Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          descAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Description Audio(Teacher )
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.teacherDescAudioPath === "string"
                                    ? item?.teacherDescAudioPath.substring(
                                      item?.teacherDescAudioPath.length - 15,
                                      item?.teacherDescAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.subdesc }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subdesc: "100%",
                                    });
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              teacherDescAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>
                              {" "}
                              Sub Sabaq Description Audio(Teacher){" "}
                            </Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          teacherDescAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Desc Reference</Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            value={item?.descReference}
                            onChange={(e) => {
                              setAddSabaq({
                                ...addSabaq,
                                subSabaqs: addSabaq.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        descReference: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Sub Sabaq Desc Reference
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Tarjama</Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            value={item?.tarjama}
                            onChange={(e) => {
                              setAddSabaq({
                                ...addSabaq,
                                subSabaqs: addSabaq.subSabaqs.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        tarjama: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Sub Sabaq Tarjama
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Tarjama Audio(Teacher And Student)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.tarjamaAudioPath === "string"
                                    ? item?.tarjamaAudioPath.substring(
                                      item?.tarjamaAudioPath.length - 15,
                                      item?.tarjamaAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.subdesc }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subdesc: "100%",
                                    });
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              tarjamaAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sub Sabaq Tarjama Audio
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>
                              {" "}
                              Sub Sabaq Tarjama Audio(Teacher And Student){" "}
                            </Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          tarjamaAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sub Sabaq Tarjama Audio
                            </Form.Control.Feedback>
                          </div>
                        )}
                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Tarjama Audio(Teacher)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.teacherTarjamaAudioPath === "string"
                                    ? item?.teacherTarjamaAudioPath.substring(
                                      item?.teacherTarjamaAudioPath.length - 15,
                                      item?.teacherTarjamaAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.subdesc }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subdesc: "100%",
                                    });
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              teacherTarjamaAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sub Sabaq Tarjama Audio
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label>
                              {" "}
                              Sub Sabaq Tarjama Audio(Teacher){" "}
                            </Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              onChange={(e) => {
                                setAddSabaq({
                                  ...addSabaq,
                                  subSabaqs: addSabaq.subSabaqs.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          teacherTarjamaAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sub Sabaq Tarjama Audio
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center mb-3 ">
                          {category_id == "4" ||
                            "9" ||
                            "10" ||
                            "12" ||
                            "13" ? null : (
                            <>
                              <div className="">
                                <h6 className="fw-bold ">Sabaq Mashq </h6>
                              </div>
                              <div className="plus-btn text-end">
                                <Link
                                  to="#"
                                  className={
                                    // idEdit
                                    // ? "pe-none btn  btn-secondary"
                                    // :
                                    "btn btn-success"
                                  }
                                  onClick={() => {
                                    setAddSabaq({
                                      ...addSabaq,
                                      subSabaqs: addSabaq.subSabaqs.map(
                                        (item, i) => {
                                          if (i === index) {
                                            let mashqs = [...item.mashqs];
                                            mashqs.push({
                                              mashq: "",
                                              mashqAudioPath: "",
                                            });
                                            return {
                                              ...item,
                                              mashqs,
                                            };
                                          } else return item;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  Add+
                                </Link>
                              </div>
                              <hr />

                              {item.mashqs &&
                                item.mashqs.map((innerItem, innerIndex) => {
                                  return (
                                    <div
                                      className="row align-items-end justify-mashq-between"
                                      key={"masq" + index}
                                    >
                                      <div className="col-md-5 mb-3">
                                        <Form.Label> Mashq Arbic</Form.Label>
                                        <Form.Control
                                          type="text"
                                          // value={innerItem?.mashq}
                                          // value={innerItem?.mashq}
                                          value={innerItem?.mashq}
                                          onChange={(e) => {
                                            setAddSabaq({
                                              ...addSabaq,
                                              subSabaqs: addSabaq.subSabaqs.map(
                                                (innerItem, innerIndex1) => {
                                                  return index === innerIndex1
                                                    ? {
                                                      ...innerItem,
                                                      mashqs:
                                                        innerItem.mashqs.map(
                                                          (
                                                            innerItem2,
                                                            innerIndex2
                                                          ) => {
                                                            return innerIndex ===
                                                              innerIndex2
                                                              ? {
                                                                ...innerItem2,
                                                                mashq:
                                                                  e.target
                                                                    .value,
                                                              }
                                                              : innerItem2;
                                                          }
                                                        ),
                                                    }
                                                    : innerItem;
                                                }
                                              ),
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please Choose Audio File.
                                        </Form.Control.Feedback>
                                      </div>

                                      {/* <div className="col-md-5 mb-3">
                                    <Form.Label>Audio</Form.Label>
                                    <Form.Control
                                      type="file"
                                      // value={innerItem?.mashqAudioPath}
                                      onChange={(e) => {
                                        setAddSabaq({
                                          ...addSabaq,
                                          subSabaqs: addSabaq.subSabaqs.map(
                                            (innerItem, innerIndex1) => {
                                              return index === innerIndex1
                                                ? {
                                                    ...innerItem,
                                                    mashqs:
                                                      innerItem.mashqs.map(
                                                        (
                                                          innerItem2,
                                                          innerIndex2
                                                        ) => {
                                                          return innerIndex ===
                                                            innerIndex2
                                                            ? {
                                                                ...innerItem2,
                                                                mashqAudioPath:
                                                                  e.target
                                                                    .files[0],
                                                              }
                                                            : innerItem2;
                                                        }
                                                      ),
                                                  }
                                                : innerItem;
                                            }
                                          ),
                                        });
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Choose Audio File.
                                    </Form.Control.Feedback>
                                  </div> */}

                                      {idEdit ? (
                                        <>
                                          <div className="row d-flex">
                                            <div className="col-md-12">
                                              <label htmlFor="" className="key">
                                                Mashq Audio
                                              </label>
                                            </div>
                                            <div
                                              className={
                                                imageWidth === "100%"
                                                  ? "invisible"
                                                  : "col-md-6 "
                                              }
                                            >
                                              <Form.Label>
                                                {typeof innerItem?.mashqAudioPath ===
                                                  "string"
                                                  ? innerItem?.mashqAudioPath.substring(
                                                    innerItem?.mashqAudioPath
                                                      .length - 15,
                                                    innerItem?.mashqAudioPath
                                                      .length
                                                  )
                                                  : ""}
                                              </Form.Label>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                              <Form.Control
                                                type="file"
                                                accept="audio/mp3,audio/*;capture=microphone"
                                                name="sabaqAudio"
                                                style={{
                                                  width: imageWidth.mashq,
                                                }}
                                                //  value={addSabaq?.sabaqAudio}
                                                onChange={(e) => {
                                                  setImageWidth({
                                                    ...imageWidth,
                                                    mashq: "100%",
                                                  });
                                                  setAddSabaq({
                                                    ...addSabaq,
                                                    subSabaqs:
                                                      addSabaq.subSabaqs.map(
                                                        (
                                                          innerItem,
                                                          innerIndex1
                                                        ) => {
                                                          return index ===
                                                            innerIndex1
                                                            ? {
                                                              ...innerItem,
                                                              mashqs:
                                                                innerItem.mashqs.map(
                                                                  (
                                                                    innerItem2,
                                                                    innerIndex2
                                                                  ) => {
                                                                    return innerIndex ===
                                                                      innerIndex2
                                                                      ? {
                                                                        ...innerItem2,
                                                                        mashqAudioPath:
                                                                          e
                                                                            .target
                                                                            .files[0],
                                                                      }
                                                                      : innerItem2;
                                                                  }
                                                                ),
                                                            }
                                                            : innerItem;
                                                        }
                                                      ),
                                                  });
                                                }}
                                              />

                                              <Form.Control.Feedback type="invalid">
                                                Please Enter Sabaq Name
                                              </Form.Control.Feedback>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="col-md-12 mb-3">
                                          <Form.Label> Mashq Audio</Form.Label>
                                          <Form.Control
                                            type="file"
                                            accept="audio/mp3,audio/*;capture=microphone"
                                            onChange={(e) => {
                                              setAddSabaq({
                                                ...addSabaq,
                                                subSabaqs:
                                                  addSabaq.subSabaqs.map(
                                                    (
                                                      innerItem,
                                                      innerIndex1
                                                    ) => {
                                                      return index ===
                                                        innerIndex1
                                                        ? {
                                                          ...innerItem,
                                                          mashqs:
                                                            innerItem.mashqs.map(
                                                              (
                                                                innerItem2,
                                                                innerIndex2
                                                              ) => {
                                                                return innerIndex ===
                                                                  innerIndex2
                                                                  ? {
                                                                    ...innerItem2,
                                                                    mashqAudioPath:
                                                                      e
                                                                        .target
                                                                        .files[0],
                                                                  }
                                                                  : innerItem2;
                                                              }
                                                            ),
                                                        }
                                                        : innerItem;
                                                    }
                                                  ),
                                              });
                                            }}
                                          />

                                          <Form.Control.Feedback type="invalid">
                                            Please Enter Sabaq Name
                                          </Form.Control.Feedback>
                                        </div>
                                      )}

                                      <div className="col-2 mb-3 minus-btn">
                                        <Link
                                          to="#"
                                          className={
                                            item.mashqs.length > 1
                                              ? "btn btn-danger"
                                              : "btn btn-secondary invisible"
                                          }
                                          // onClick={() =>
                                          //   setAddsabaqMashq(AddsabaqMashq - 1)
                                          // }
                                          onClick={() => {
                                            if (item.mashqs.length > 1) {
                                              item.mashqs.splice(innerIndex, 1);

                                              const copySubDeatails = [
                                                ...item.mashqs,
                                              ];
                                            }

                                            // const copySubDeatails = [...addSabaq.subSabaqs];
                                            // copySubDeatails.sli
                                          }}
                                        //                                       onClick={() => {
                                        // let mashqList = [...item.mashqs]
                                        // mashqList.splice(innerIndex, 1)

                                        //                                       }}

                                        //   onClick={() => {
                                        // if (addSabaq.subSabaqs.length > 1) {
                                        //   addSabaq.subSabaqs.splice(index, 1);
                                        //   const copySubDeatails = [...addSabaq.subSabaqs];
                                        //   copySubDeatails.splice(index, 1);
                                        //   setAddSabaq({
                                        //     ...addSabaq,
                                        //     subSabaqs: copySubDeatails,
                                        //   });
                                        // }

                                        //
                                        >
                                          -
                                        </Link>
                                      </div>
                                    </div>
                                  );
                                })}

                              <hr />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="cmb-3 minus-btn text-end">
                        <Link
                          to="#"
                          className={
                            addSabaq.subSabaqs.length > 1
                              ? "btn btn-danger"
                              : "btn btn-secondary invisible"
                          }
                          // onClick={() => setAddNewGroup(AddNewGroup - 1)}
                          onClick={() => {
                            if (addSabaq.subSabaqs.length > 1) {
                              // addSabaq.subSabaqs.splice(index, 1);
                              const copySubDeatails = [...addSabaq.subSabaqs];
                              copySubDeatails.splice(index, 1);
                              setAddSabaq({
                                ...addSabaq,
                                subSabaqs: copySubDeatails,
                              });
                            }

                            // const copySubDeatails = [...addSabaq.subSabaqs];
                            // copySubDeatails.sli
                          }}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}

              <Link
                to="#"
                className={
                  // idEdit
                  // ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                  // :
                  "add-newSabaq-btn btn btn-primary"
                }
                // onClick={() => setAddNewGroup(AddNewGroup + 1)}
                onClick={() => {
                  let sabak = [
                    ...addSabaq.subSabaqs,

                    {
                      subSabaqTitle: "",
                      titleAudioPath: "",
                      subSabaqDesc: "",
                      descAudioPath: "",
                      descReference: "",
                      tarjamaAudioPath: "",
                      titleTeacherAudioPath:"",
                      teacherDescAudioPath:"",
                      teacherTarjamaAudioPath:"",
                      tarjama: "",
                      mashqs: [
                        {
                          mashq: "",
                          mashqAudioPath: "",
                        },
                      ],
                    },
                  ];
                  setAddSabaq({
                    ...addSabaq,
                    subSabaqs: sabak,
                  });
                }}
              >
                + Add Sub Sabaq Details
              </Link>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setAddSabaq({
                    sabaqName: "",
                    sabaqAudio: "",
                    sabaqDesc: "",
                    sabaqDescAudio: "",
                    sabaqTeacherAudio:"",
                    sabaqTeacherDesc:"",
                    subSabaqs: [
                      {
                        subSabaqTitle: "",
                        titleAudioPath: "",
                        subSabaqDesc: "",
                        descAudioPath: "",
                        descReference: "",
                        tarjamaAudioPath: "",
                        titleTeacherAudioPath:"",
                        teacherDescAudioPath:"",
                        teacherTarjamaAudioPath:"",
                        tarjama: "",
                        mashqs: [
                          {
                            mashq: "",
                            mashqAudioPath: "",
                          },
                        ],
                      },
                    ],
                  });
                  // setTestEdit(addSabaq)
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= START DELETE ========================== */}
      <Modal
        show={Delete}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-mashq-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              onClick={() => {
                handleDeleteClose();
                SabaqLisDeleteApi();
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= START VIEW ========================== */}

      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="xl"
        aria-labelledby="contained-modal-subSabaqTitle-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sabaq Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.SabaqNo}
                  </label>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                {/* <div>
                    <label htmlFor="" className="key">
                      Sabaq Icon
                    </label>
                  </div>
                  <div>
                    <MDBRow>
                      <MDBCol lg="2" md="3" className="mb-4">
                        <img
                          src={
                            !viewDetails?.sabaqIcon
                              ? ""
                              : ApiConfig.FILE_BASE_URL +
                                viewDetails?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + viewDetails?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL +
                                  viewDetails?.sabaqIcon &&
                                URL.createObjectURL(
                                  ApiConfig.FILE_BASE_URL +
                                    viewDetails?.sabaqIcon
                                )
                          }
                          className="img-fluid rounded value"
                          alt=""
                        />
                      </MDBCol>
                    </MDBRow>
                  </div> */}
              </div>

              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio(Teacher And Student)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqAudio ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`}
                        // src={viewDetails?.sabaqAudio}
                        type="audio/ogg"
                      />
                      <source
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`}
                        // src={viewDetails?.sabaqAudio}
                        type="audio/mpeg"
                      />
                      Your browser does not support the mashqAudioPath element.
                    </audio>
                  ) : ("")}

                </div>
              </div>
              <div className="col-md-6 mb-3"></div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio(Teacher)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqTeacherAudio ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherAudio}`}
                        // src={viewDetails?.sabaqAudio}
                        type="audio/ogg"
                      />
                      <source
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherAudio}`}
                        // src={viewDetails?.sabaqAudio}
                        type="audio/mpeg"
                      />
                      Your browser does not support the mashqAudioPath element.
                    </audio>
                  ) : ("")}

                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Description
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqDesc}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Description Audio(Teacher And Student)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqDescAudio ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        // src={viewDetails?.sabaqDescAudio}
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqDescAudio}`}
                        type="audio/ogg"
                      />
                      <source
                        // src={viewDetails?.sabaqDescAudio}
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqDescAudio}`}
                        type="audio/mpeg"
                      />
                      Your browser does not support the mashqAudioPath element.
                    </audio>
                  ) : ("")}

                </div>
              </div>
              <div className="col-md-6 mb-3"></div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Description Audio(Teacher)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqTeacherDesc ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        // src={viewDetails?.sabaqDescAudio}
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherDesc}`}
                        type="audio/ogg"
                      />
                      <source
                        // src={viewDetails?.sabaqDescAudio}
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherDesc}`}
                        type="audio/mpeg"
                      />
                      Your browser does not support the mashqAudioPath element.
                    </audio>
                  ) : ("")}

                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Description Reference
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqDescReference}
                  </label>
                </div>
              </div>
              {viewDetails.subSabaqs && viewDetails.subSabaqs.length > 0
                ? viewDetails?.subSabaqs.map((element, index) => {
                  return (
                    <div
                      className="sub-group-main row mb-3"
                      key={"testkey" + index}
                    >
                      <h6 className="fw-bold mb-4">
                        Sub Sabaq No {index + 1}
                      </h6>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Title
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {element?.subSabaqTitle}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Title Audio(Teacher And Student)
                          </label>
                        </div>
                        <div>
                          {element?.titleAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${element?.titleAudioPath}`}
                                // src={element?.titleAudioPath}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${element?.titleAudioPath}`}
                                // src={element?.titleAudioPath}
                                type="audio/mpeg"
                              />
                              Your browser does not support the mashqAudioPath
                              element.
                            </audio>
                          ) : ("")}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Title Audio(Teacher)
                          </label>
                        </div>
                        <div>
                          {element?.titleTeacherAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${element?.titleTeacherAudioPath}`}
                                // src={element?.titleAudioPath}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${element?.titleTeacherAudioPath}`}
                                // src={element?.titleAudioPath}
                                type="audio/mpeg"
                              />
                              Your browser does not support the mashqAudioPath
                              element.
                            </audio>
                          ) : ("")}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Description
                          </label>
                        </div>
                        <div className="mb-2">
                          <label htmlFor="" className="value">
                            {element?.subSabaqDesc}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Description Audio(Teacher And Student)
                          </label>
                        </div>
                        <div>
                          {element?.descAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${element?.descAudioPath}`}
                                // src={element?.descAudioPath}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${element?.descAudioPath}`}
                                // src={element?.descAudioPath}
                                type="audio/mpeg"
                              />
                              Your browser does not support the mashqAudioPath
                              element.
                            </audio>
                          ) : ("")}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Description Audio(Teacher)
                          </label>
                        </div>
                        <div>
                          {element?.teacherDescAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${element?.teacherDescAudioPath}`}
                                // src={element?.descAudioPath}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${element?.teacherDescAudioPath}`}
                                // src={element?.descAudioPath}
                                type="audio/mpeg"
                              />
                              Your browser does not support the mashqAudioPath
                              element.
                            </audio>
                          ) : ("")}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {element?.tarjama}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama Audio(Teacher And Student)
                          </label>
                        </div>
                        <div>
                          {element?.tarjamaAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${element?.tarjamaAudioPath}`}
                                // src={element?.tarjamaAudioPath}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${element?.tarjamaAudioPath}`}
                                // src={element?.descAudioPath}
                                type="audio/mpeg"
                              />
                              Your browser does not support the mashqAudioPath
                              element.
                            </audio>
                          ) : ("")}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama Audio(Teacher)
                          </label>
                        </div>
                        <div>
                          {element?.teacherTarjamaAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${element?.teacherTarjamaAudioPath}`}
                                // src={element?.tarjamaAudioPath}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${element?.teacherTarjamaAudioPath}`}
                                // src={element?.descAudioPath}
                                type="audio/mpeg"
                              />
                              Your browser does not support the mashqAudioPath
                              element.
                            </audio>
                          ) : ("")}

                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Desc Reference
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {element?.descReference}
                          </label>
                        </div>
                      </div>

                      <h6
                        className="fw-bold mb-4 "
                        style={{ textDecoration: "underline" }}
                      >
                      </h6>
                      {element?.mashqs && element?.mashqs.length > 0
                        ? element?.mashqs.map((sub, index) => {
                          return (
                            <>
                              <div
                                key={"test" + index}
                                className="col-md-6 mb-3"
                              >
                                <div>
                                  <label htmlFor="" className="key">
                                    Mashq Arbic
                                  </label>
                                </div>
                                <div>
                                  <label htmlFor="" className="value">
                                    {sub?.mashq}
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-6 mb-3">
                                <div>
                                  <label htmlFor="" className="key">
                                    Mashq Audio
                                  </label>
                                </div>
                                <div>
                                  {sub?.mashqAudioPath ? (
                                    <audio
                                      controls
                                      style={{
                                        width: "100%",
                                        height: "38px",
                                      }}
                                    >
                                      <source
                                        src={`${FILE_BASE_URL}${sub?.mashqAudioPath}`}
                                        type="audio/ogg"
                                      />
                                      <source
                                        src={`${FILE_BASE_URL}${sub?.mashqAudioPath}`}
                                        type="audio/mpeg"
                                      />
                                      Your browser does not support the
                                      mashqAudioPath element.
                                    </audio>
                                  ) : ("")}

                                </div>
                              </div>
                            </>
                          );
                        })
                        : ""}
                    </div>
                  );
                })
                : ""}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link
                to="#"
                className="bg-danger"
                onClick={() => {
                  handleViewClose();
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ======================= END VIEW ========================== */}
    </main>
  );
};

export default FifthYear;
