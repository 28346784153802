import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Table from "react-bootstrap/Table";

import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import SurahFatiha from "../assets/SurahFatiha.mp3";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "./TostMsg";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig, { FILE_BASE_URL } from "../api/ApiConfig";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";



const HifzESurah = ({ key }) => {

  let module_id;
  let course_yr_id;
  let category_id;

  if (typeof window !== undefined) {
    module_id = localStorage.getItem("module_id");
    course_yr_id = localStorage.getItem("course_yr_id");
    category_id = localStorage.getItem("category_id");
  }

  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [idEdit, setIdEdit] = useState("");
  const [viewDetails, setViewDetails] = useState({});
  const [imageWidth, setImageWidth] = useState({
    sabaq: "100%",
    des: "100%",
  });
  const [addAyyat, setAddAyyat] = useState({
    sabaqName: "",
    sabaqAudio: "",
    teacherNameAudioPath: "",
    sabaqIcon: [],
    ayatDetails: [
      {
        ayat: "",
        ayatAudioPath: "",
        teacherAyatAudioPath: "",
        ayatNo: "",
        waqf1: "",
        waqf2: "",
        waqf3: "",
      },
    ],
  });

  console.log("addAyyat", addAyyat);
  // const pagination = () => {
  //   $(document).ready(function () {
  //     let table;
  //     if ($.fn.dataTable.isDataTable("#table_id")) {
  //       table = $("#table_id").DataTable();
  //     } else {
  //       table = $("#table_id").DataTable({
  //         ordering: false,

  //         language: {
  //           search: "",
  //           searchPlaceholder: "Search...",
  //           sLengthMenu: "Entries per page _MENU_",
  //         },
  //       });
  //     }
  //   });
  // };
  const [AddNewGroup, setAddNewGroup] = useState(0);

  const [add, setAdd] = useState(false);
  const handleAddClose = () => {
    setAdd(false);
    setIdEdit("");
    setAddAyyat({
      sabaqName: "",
      sabaqAudio: "",
      teacherNameAudioPath: "",
      sabaqIcon: [],
      ayatDetails: [
        {
          ayat: "",
          ayatAudioPath: "",
          teacherAyatAudioPath: "",
          ayatNo: "",
          waqf1: "",
          waqf2: "",
          waqf3: "",
        },
      ],
    });
  };
  const handleAddShow = () => setAdd(true);
  const [deleteId, setDeleteId] = useState("");
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [saveBotton, setSaveBotton] = useState(false);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [validated, setValidated] = useState(false);
  const [hifzlist, setHifzlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(hifzlist?.length / 15);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addAyyatApi();
    }

    setValidated(true);
  };

  const addAyyatApi = () => {
    handleAddClose();
    setLoader(true);

    const body = {
      categoryId: 2,
      moduleId: module_id,
      courseYrId: (module_id === 1 || module_id === 2) && course_yr_id,
      userId: Number(adminData.user_id),
      surahName: addAyyat.sabaqName ? addAyyat.sabaqName : "",
      sabaqNo: lasatSabaqId + 1,
      surahId: addAyyat.SabaqId,
      sabaqIcon: addAyyat.sabaqIcon
    };
    //all dataa paylodade

    let payLoadFormData = new FormData();

    payLoadFormData.append("moduleId", module_id);
    payLoadFormData.append("courseYrId", course_yr_id);
    payLoadFormData.append("categoryId", category_id);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("surahName", body.surahName);
    payLoadFormData.append("sabaqIcon", body.sabaqIcon ? body.sabaqIcon : "");
    idEdit === "edit"
      ? payLoadFormData.append("surahId", body.surahId)
      : payLoadFormData.append("sabaqNo", body.sabaqNo);
    let newSubaqAudio = [];
    newSubaqAudio.push(addAyyat.sabaqAudio);
    newSubaqAudio.map((ele) => payLoadFormData.append("surahNameAudio", ele ? ele : ""));
    let newteacherSurahNameAudio = [];
    newteacherSurahNameAudio.push(addAyyat.teacherNameAudioPath);
    newteacherSurahNameAudio.map((ele) => payLoadFormData.append("teacherSurahNameAudio", ele ? ele : ""));

    addAyyat.ayatDetails.map((ele) =>
      payLoadFormData.append("ayahAudio", ele.ayatAudioPath ? ele.ayatAudioPath : "")
    );
    addAyyat.ayatDetails.map((ele) =>
      payLoadFormData.append("teacherAyahAudio", ele.teacherAyatAudioPath ? ele.teacherAyatAudioPath : "")
    );
    let ayaahs = addAyyat.ayatDetails.map((ele, index) => {
      return {
        ayat: ele.ayat,
        ayahAudio: ele.ayatAudioPath == "" ? "no file" : "file name",
        teacherAyahAudio: ele.teacherAyatAudioPath == "" ? "no file" : "file name",
        ayatNo: ele.ayatNo,
        waqf1: ele.waqf1,
        waqf2: ele.waqf2,
        waqf3: ele.waqf3,
        // ayatNo: index + 1,
      };
    });
    let ayaahsEdit = addAyyat.ayatDetails.map((ele, index) => {
      return {
        ayat: ele.ayat,
        ayahAudio:typeof ele.ayatAudioPath == "object" ? "updated" : "not updated",
        teacherAyahAudio: typeof ele.teacherAyatAudioPath == "" ? "updated" : "not updated",
        waqf1: ele.waqf1,
        waqf2: ele.waqf2,
        waqf3: ele.waqf3,
        ayatNo: ele.ayatNo,
        ayatId: ele.ayatId,
      };
    });
    idEdit === "edit"
      ? payLoadFormData.append("ayaahs", JSON.stringify(ayaahsEdit))
      : payLoadFormData.append("ayaahs", JSON.stringify(ayaahs));

    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_SURAH,
        payLoadFormData
      ).then((res) => {
        setLoader(false);
        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);
          HafizListApi();
          setSaveBotton(false);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      : multipartPostCallWithErrorResponse(
        ApiConfig.ADD_SURAH,
        payLoadFormData
        // payLoadFormDataNew
      )
        .then((res) => {
          setLoader(false);
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            HafizListApi();
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        });
  };
  useEffect(() => {
    HafizListApi();
  }, [key]);
  const HafizListApi = () => {
    const apiUrl = (module_id == 1 || module_id == 2)
      ? ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${2}`
      : ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${2}`;
    simpleGetCallWithErrorResponse(apiUrl)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setHifzlist(res.json?.data.allSabaq);
          setLsatSabaq(
            res.json?.data.allSabaq.length

          );
          setAddAyyat({
            sabaqName: "",
            sabaqAudio: "",
            sabaqIcon: "",

            ayatDetails: [
              {
                ayat: "",
                ayatAudioPath: "",
                ayatNo: "",
                waqf1: "",
                waqf2: "",
                waqf3: "",
              },
            ],
          });
        } else {
          // ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };
  //
  const hifzLisDeleteApi = () => {
    const body = {
      categoryId: 2,
      sabaqId: deleteId,
    };

    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_SURAH,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        if (res.json && res.json?.success === true) {
          ToastMsg("success", res.json.message);
          HafizListApi();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  //
  const EditListApi = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.VIEW_HIFZE_SURAH + `sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setAddAyyat(res.json.data);
          setIdEdit("edit");
          handleAddShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const viewListApi = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.VIEW_HIFZE_SURAH + `sabaqId=${id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Hifz-E-Surah</label>
        </div>
        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Sabaq
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {idEdit === "edit" ? "Update  Hifz-e-Surah" : "Add Hifz-e-Surah"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form Validate validated={validated} className="fw-bold" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label >Surah Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={addAyyat?.sabaqName}
                  onChange={(e) =>
                    setAddAyyat({ ...addAyyat, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>


              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio(Teacher And Student)
                      </label>
                    </div>
                    <div
                      className={
                        imageWidth === "100%" ? "invisible" : "col-md-6 "
                      }
                    >
                      <Form.Label>
                        {typeof addAyyat?.sabaqAudio === "string"
                          ? addAyyat?.sabaqAudio.substring(
                            addAyyat?.sabaqAudio.length - 15,
                            addAyyat?.sabaqAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        // name="sabaqAudio"
                        style={{ width: imageWidth.sabaq }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaq: "100%" });
                          setAddAyyat({
                            ...addAyyat,
                            sabaqAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio(Teacher And Student) </Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"

                    // value={addAyyat?.sabaqAudio}
                    onChange={(e) =>
                      setAddAyyat({
                        ...addAyyat,
                        sabaqAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio(Teacher)
                      </label>
                    </div>
                    <div
                      className={
                        imageWidth === "100%" ? "invisible" : "col-md-6 "
                      }
                    >
                      <Form.Label>
                        {typeof addAyyat?.teacherNameAudioPath === "string"
                          ? addAyyat?.teacherNameAudioPath.substring(
                            addAyyat?.teacherNameAudioPath.length - 15,
                            addAyyat?.teacherNameAudioPath.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        // name="sabaqAudio"
                        style={{ width: imageWidth.sabaq }}
                        //  value={addSabaq?.sabaqAudio}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaq: "100%" });
                          setAddAyyat({
                            ...addAyyat,
                            teacherNameAudioPath: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio(Teacher) </Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"

                    // value={addAyyat?.sabaqAudio}
                    onChange={(e) =>
                      setAddAyyat({
                        ...addAyyat,
                        teacherNameAudioPath: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {/* {idEdit ? ( */}
              {/* <div> */}
              {/* <label>Sabaq Icon</label>
                  <MDBRow>
                    <MDBCol lg="1" md="1" xm="1" className="mb-4">
                      <img
                        src={
                          !addAyyat?.sabaqIcon
                            ? ""
                            : ApiConfig.FILE_BASE_URL + addAyyat?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + addAyyat?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL + addAyyat?.sabaqIcon &&
                              URL.createObjectURL(ApiConfig.FILE_BASE_URL + addAyyat?.sabaqIcon)

                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                  <div className="col-md-12 mb-3">
                      <Form.Label>Update Sabaq Icon</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="updateSabaqIcon"
                        onChange={(e) => {
                          setAddAyyat({
                            ...addAyyat,
                            sabaqIcon: e.target.files[0],
                          })
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Icon
                      </Form.Control.Feedback>
                    </div> */}
              {/* </div> */}
              {/* // ) : ( */}
              {/* // <div className="col-md-12 mb-3"> */}
              {/* <Form.Label>Sabaq Icon</Form.Label> */}
              {/* <Form.Control */}
              {/* // required */}
              {/* // type="file" */}
              {/* // accept="audio/mp3,audio/*;capture=microphone" */}
              {/* accept="image/*" */}
              {/* name="sabaqAudio" */}
              {/* src={addAyyat?.sabaqIcon} */}
              {/* //  / value={addSabaq?.sabaqAudio} */}
              {/* onChange={(e) => {
                      setAddAyyat({
                        ...addAyyat,
                        sabaqIcon: e.target.files[0],
                      })
                    }} */}
              {/* /> */}

              {/* <Form.Control.Feedback type="invalid"> */}
              {/* Please Enter Sabaq Icon */}
              {/* </Form.Control.Feedback> */}
              {/* </div> */}
              {/* )} */}

              {addAyyat.ayatDetails &&
                addAyyat.ayatDetails.map((item, index) => {
                  return (
                    <div
                      className="col-md-12 sub-group-main"
                      key={"testitem" + index}
                    >
                      <div className="row">
                        <h6 className="fw-bold">
                          {/* Aayat No. <span>{index + 1}</span> */}
                        </h6>

                        <div className="col-md-4 fw-bold mb-3">
                          <Form.Label>Aayat No.</Form.Label>
                          <Form.Control
                            maxLength={10}

                            type="text"
                            value={item?.ayatNo}
                            onChange={(e) => {
                              setAddAyyat({
                                ...addAyyat,
                                ayatDetails: addAyyat.ayatDetails.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        ayatNo: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Aayat Number
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-3">
                          <Form.Label>Aayat In Arabic</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={item?.ayat}
                            onChange={(e) => {
                              setAddAyyat({
                                ...addAyyat,
                                ayatDetails: addAyyat.ayatDetails.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        ayat: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Tarjama.
                          </Form.Control.Feedback>
                        </div>





                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Aayat Audio(Teacher And Student)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.ayatAudioPath === "string"
                                    ? item?.ayatAudioPath.substring(
                                      item?.ayatAudioPath.length - 15,
                                      item?.ayatAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.des }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      des: "100%",
                                    });
                                    setAddAyyat({
                                      ...addAyyat,
                                      ayatDetails: addAyyat.ayatDetails.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              ayatAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Aayat Audio(Teacher And Student)</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              // value={addAyyat?.sabaqAudio}
                              onChange={(e) => {
                                setAddAyyat({
                                  ...addAyyat,
                                  ayatDetails: addAyyat.ayatDetails.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          ayatAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Aayat Audio(Teacher)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "invisible"
                                    : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof item?.teacherAyatAudioPath === "string"
                                    ? item?.teacherAyatAudioPath.substring(
                                      item?.teacherAyatAudioPath.length - 15,
                                      item?.teacherAyatAudioPath.length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="sabaqAudio"
                                  style={{ width: imageWidth.des }}
                                  //  value={addSabaq?.sabaqAudio}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      des: "100%",
                                    });
                                    setAddAyyat({
                                      ...addAyyat,
                                      ayatDetails: addAyyat.ayatDetails.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              teacherAyatAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Aayat Audio(Teacher)</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              // value={addAyyat?.sabaqAudio}
                              onChange={(e) => {
                                setAddAyyat({
                                  ...addAyyat,
                                  ayatDetails: addAyyat.ayatDetails.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          teacherAyatAudioPath: e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-4 mb-3">
                          <Form.Label>Waqf 1</Form.Label>
                          <Form.Control
                            maxLength={10}

                            type="text"
                            value={item?.waqf1}
                            onChange={(e) => {
                              setAddAyyat({
                                ...addAyyat,
                                ayatDetails: addAyyat.ayatDetails.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        waqf1: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Audio File.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-3">
                          <Form.Label>Waqf 2</Form.Label>
                          <Form.Control
                            maxLength={10}
                            type="text"
                            value={item?.waqf2}
                            onChange={(e) => {
                              setAddAyyat({
                                ...addAyyat,
                                ayatDetails: addAyyat.ayatDetails.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        waqf2: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Audio File.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-3">
                          <Form.Label>Waqf 3</Form.Label>
                          <Form.Control
                            maxLength={10}

                            type="text"
                            value={item?.waqf3}
                            onChange={(e) => {
                              setAddAyyat({
                                ...addAyyat,
                                ayatDetails: addAyyat.ayatDetails.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        waqf3: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Audio File.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="text-end">
                        <Link
                          to="#"
                          // className="btn btn-danger"
                          // onClick={() => setAddNewGroup(AddNewGroup - 1)}

                          className={
                            addAyyat.ayatDetails.length > 1
                              ? "btn btn-danger"
                              : "btn btn-secondary invisible"
                          }
                          // onClick={() => setAddNewGroup(AddNewGroup - 1)}
                          onClick={() => {
                            if (addAyyat.ayatDetails.length > 1) {
                              // addAyyat.ayatDetails.splice(index, 1);
                              const copySubDeatails = [...addAyyat.ayatDetails];
                              copySubDeatails.splice(index, 1);
                              setAddAyyat({
                                ...addAyyat,
                                ayatDetails: copySubDeatails,
                              });
                            }

                            // const copySubDeatails = [...addSabaq.subsabaqDetails];
                            // copySubDeatails.sli
                          }}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}

              <Link
                to="#"
                className="add-newSabaq-btn btn btn-primary"
                // {
                //   idEdit
                //     ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                //     : "add-newSabaq-btn btn btn-primary"
                // }
                // onClick={() => setAddNewGroup(AddNewGroup + 1)}
                onClick={() => {
                  let ayat = [
                    ...addAyyat.ayatDetails,

                    {
                      ayat: "",
                      ayatAudioPath: "",
                      waqf1: "",
                      waqf2: "",
                      waqf3: "",
                    },
                  ];
                  setAddAyyat({
                    ...addAyyat,
                    ayatDetails: ayat,
                  });
                }}
              >
                + Add Sub Sabaq Details
              </Link>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit" disabled={saveBotton}>
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setAddAyyat({
                    sabaqName: "",

                    ayatDetails: [
                      {
                        ayat: "",
                        ayatAudioPath: "",
                        waqf1: "",
                        waqf2: "",
                        waqf3: "",
                      },
                    ],
                  });
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"

              onClick={() => {
                handleDeleteClose()
                hifzLisDeleteApi()
              }}

            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Hifz-e-Surah Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.SabaqNo}
                  </label>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                {/* <div>
                    <label htmlFor="" className="key">
                      Sabaq Icon
                    </label>
                  </div>
                  <div>
                    <MDBRow>
                      <MDBCol lg="2" md="3" className="mb-4">
                        <img
                          src={
                            !viewDetails?.sabaqIcon
                              ? ""
                              : ApiConfig.FILE_BASE_URL +
                                viewDetails?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + viewDetails?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL +
                                  viewDetails?.sabaqIcon &&
                                URL.createObjectURL(
                                  ApiConfig.FILE_BASE_URL +
                                    viewDetails?.sabaqIcon
                                )
                          }
                          className="img-fluid rounded value"
                          alt=""
                        />
                      </MDBCol>
                    </MDBRow>
                  </div> */}
              </div>

              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqName}
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio(Teacher And Student)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqAudio ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        // src={viewDetails?.sabaqAudio}
                        src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`}
                        type="audio/ogg" />
                      <source src={viewDetails?.sabaqAudio} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio(Teacher)
                  </label>
                </div>
                <div>
                  {viewDetails?.teacherNameAudioPath ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source
                        // src={viewDetails?.sabaqAudio}
                        src={`${FILE_BASE_URL}${viewDetails?.teacherNameAudioPath}`}
                        type="audio/ogg" />
                      <source src={viewDetails?.teacherNameAudioPath} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              {viewDetails.ayatDetails &&
                viewDetails.ayatDetails?.length > 0 &&
                viewDetails.ayatDetails.map((ele, index) => {
                  return (
                    <div
                      className="sub-group-main row mb-3"
                      key={"testi" + index}
                    >
                      <h6 className="fw-bold mb-4">
                        Aayat No.<span>{ele?.ayatNo}</span>{" "}
                      </h6>
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="value">
                            {ele?.ayat}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Aayat Audio (Teacher And Student) 
                          </label>
                        </div>
                        <div>
                          {
                            ele?.ayatAudioPath ? (
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  // src={ele?.ayatAudioPath} 
                                  src={`${FILE_BASE_URL}${ele?.ayatAudioPath}`}
                                  type="audio/ogg" />
                                <source
                                  src={ele?.ayatAudioPath}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            ) : (
                              ""
                            )
                          }

                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Aayat Audio (Teacher) 
                          </label>
                        </div>
                        <div>
                          {
                            ele?.teacherAyatAudioPath ? (
                              <audio
                                controls
                                style={{ width: "100%", height: "38px" }}
                              >
                                <source
                                  // src={ele?.ayatAudioPath} 
                                  src={`${FILE_BASE_URL}${ele?.teacherAyatAudioPath}`}
                                  type="audio/ogg" />
                                <source
                                  src={ele?.teacherAyatAudioPath}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            ) : (
                              ""
                            )
                          }

                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Waqf 1
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele?.waqf1}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Waqf 2
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele?.waqf2}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Waqf 3
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele?.waqf3}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}
      {loader ? (
        <FormLoader />
      ) : (
        <div className="table-wrapper">
          <h6>Total Records : {hifzlist.length}</h6>
          <Table className="display" striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Surah List</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {hifzlist && hifzlist.length > 0
                ? hifzlist.slice(startIndex, endIndex).map((ele, index) => {
                  return (
                    <tr key={"ele" + index}>
                      <td>{index + 1 + startIndex}</td>

                      <td style={{ textAlign: "left" }}>{ele.sabaqName}</td>
                      <td className="action">
                        <Link
                          to="#"
                          className="icon edit"
                          onClick={() => {
                            // handleAddShow();
                            setIdEdit("edit");
                            setImageWidth({
                              ...imageWidth,
                              sabaq: "117px",
                              des: "117px",
                              Title: "117px",
                              subdesc: "117px",
                              mashq: "117px",
                            });
                            EditListApi(ele.sabaqId);
                          }}
                        >
                          <img src={ic_edit} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon view"
                          onClick={() => viewListApi(ele.sabaqId)}
                        >
                          <img src={ic_eyes} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon delete"
                          onClick={() => {
                            handleDeleteShow();
                            setDeleteId(ele.sabaqId);
                          }}
                        >
                          <img src={ic_delete} alt="" />
                        </Link>
                      </td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </Table>
          {hifzlist && hifzlist.length > 0 ? (
            <Pagination
              changePage={changePage}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          ) : (
            <div>
              <h5 style={{ color: "red", textAlign: "center" }}>
                {" "}
                Records Not Found
              </h5>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default HifzESurah;
