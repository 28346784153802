import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig, { FILE_BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./TostMsg";
import { Pagination } from "./Pagination";
import FormLoader from "./FormLoader";
const Namaaz = ({ key }) => {

  let module_id;
  let course_yr_id;
  let category_id;

  if (typeof window !== undefined) {
    module_id = localStorage.getItem("module_id");
    course_yr_id = localStorage.getItem("course_yr_id");
    category_id = localStorage.getItem("category_id");
  }

  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [lasatSabaqId, setLsatSabaq] = useState("");
  const [saveBotton, setSaveBotton] = useState(false);

  const [viewDetails, setViewDetails] = useState([]);
  const [imageWidth, setImageWidth] = useState({
    sabaqAudio: "100%",
    Uploade: "100%",
    tarjamaAudio: "100%",
    Description: "100%",
    subSabaqTitle: "100%",
    arabicAudio: "100%",
  });
  const [addNamaz, setAddNamaz] = useState({
    sabaqName: "",
    sabaqDesc: "",
    sabaqIcon: [],
    sabaqDescAudio: "",
    sabaqNameAudio: "",
    sabaqDescReference: "",
    sabaqTeacherAudio:'',
    sabaqTeacherDesc:"",
    subSabaq: [
      {
        subSabaqTitle: "",
        subSabaqDesc: "",
        subSabaqTitleAudio: "",
        subSabaqDescAudio: "",
        subSabaqTarjamaAudio: "",
        subSabaqTarjama: "",
        subSabaqDescReference: "",
        teacherTarjamaAudioPath:"",
        titleTeacherAudioPath:"",
        teacherDescAudioPath:"",

      },
    ],
  });
  const [idEdit, setIdEdit] = useState("");

  const [add, setAdd] = useState(false);
  const handleAddClose = () => {
    setAdd(false)
    setIdEdit("");
    setAddNamaz({
      sabaqName: "",
      sabaqDesc: "",
      sabaqDescAudio: "",
      sabaqIcon: [],
      sabaqNameAudio: "",
      sabaqDescReference: "",
      sabaqTeacherAudio:'',
      sabaqDescReference: "",

      subSabaq: [
        {
          subSabaqTitle: "",
          subSabaqDesc: "",
          subSabaqTitleAudio: "",
          subSabaqDescAudio: "",
          subSabaqTarjamaAudio: "",
          subSabaqTarjama: "",
          subSabaqDescReference: "",
          teacherTarjamaAudioPath:"",
          titleTeacherAudioPath:"",
          teacherDescAudioPath:"",
        },
      ],
    });
    setAdd(false);
  };
  const handleAddShow = () => setAdd(true);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [viewModal, setView] = useState(false);
  const handleViewClose = () => setView(false);
  const handleViewShow = () => setView(true);

  const [validated, setValidated] = useState(false);
  const [namazList, setNamazList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 15;
  const endIndex = startIndex + 15;
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(namazList?.length / 15);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addNamazqApi();
    }

    setValidated(true);
  };


  // add
  const addNamazqApi = () => {
    setSaveBotton(true);
    handleAddClose();
    setLoader(true);
    const body = {
      moduleId: module_id,
      courseYrId: (module_id === 1 || module_id === 2) && course_yr_id,
      categoryId: 7,
      userId: Number(adminData.user_id),
      sabaqNo: lasatSabaqId,
      sabaqName: addNamaz.sabaqName ? addNamaz.sabaqName : "",
      sabaqDesc: addNamaz.sabaqDesc ? addNamaz.sabaqDesc : "",
      sabaqDescReference: addNamaz.sabaqDescReference ? addNamaz.sabaqDescReference : "",
      sabaqId: addNamaz.SabaqId,
      sabaqIcon: addNamaz.sabaqIcon,
    };

    //all dataa paylodade

    let payLoadFormData = new FormData();
    payLoadFormData.append("moduleId", body.moduleId);
    payLoadFormData.append("courseYrId", course_yr_id);
    payLoadFormData.append("categoryId", body.categoryId);
    payLoadFormData.append("userId", body.userId);
    payLoadFormData.append("sabaqNo", body.sabaqNo);
    payLoadFormData.append("sabaqIcon", body.sabaqIcon ? body.sabaqIcon : "");
    payLoadFormData.append("sabaqName", body.sabaqName);
    payLoadFormData.append("sabaqDesc", body.sabaqDesc);
    payLoadFormData.append("sabaqDescReference", body.sabaqDescReference);
    payLoadFormData.append("sabaqId", body.sabaqId);
    let subSabaq = addNamaz.subSabaq.map((ele, index) => {
      return {
        descReference: ele.descReference ? ele.descReference : "",
        subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
        subSabaqNo: index + 1,
        subSabaqTarjama: ele.subSabaqTarjama ? ele.subSabaqTarjama : "",
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",

        subSabaqDescReference: ele.subSabaqDescReference ? ele.subSabaqDescReference : "",
        //  arabic: ele.arabic,
        subSabaqTitleAudio:
          ele.subSabaqTitleAudio == "" ? "no file" : "file name",
        subSabaqDescAudio:
          ele.subSabaqDescAudio == "" ? "no file" : "file name",
        subSabaqTarjamaAudio:
          ele.subSabaqTarjamaAudio == "" ? "no file" : "file name",
        //  arabicAudio:ele.arabicAudio=="" ?"no file":"file name",
        subSabaqTitleTeacherAudio:
        ele.titleTeacherAudioPath == "" ? "no file" : "file name",
        subSabaqDescTeacherAudio:
        ele.teacherDescAudioPath == "" ? "no file" : "file name",
        subSabaqTarjamaTeacherAudio:
        ele.teacherTarjamaAudioPath == "" ? "no file" : "file name",
      };
    });
    let subSabaqEdit = addNamaz.subSabaq.map((ele, index) => {
      return {
        subSabaqDescReference: ele.subSabaqDescReference ? ele.subSabaqDescReference : "",

        subSabaqDesc: ele.subSabaqDesc ? ele.subSabaqDesc : "",
        subSabaqId: ele.subSabaqId,
        // subSabaqNo: ele.subSabaqNo,
        subSabaqNo: index + 1,

        subSabaqTarjama: ele.subSabaqTarjama ? ele.subSabaqTarjama : "",
        subSabaqTitle: ele.subSabaqTitle ? ele.subSabaqTitle : "",
        //  arabic: ele.arabic,
        subSabaqTitleAudio:
          typeof ele.subSabaqTitleAudio == "object" ? "updated" : "not updated",
        subSabaqDescAudio:
          typeof ele.subSabaqDescAudio == "object" ? "updated" : "not updated",
        subSabaqTarjamaAudio:
          typeof ele.subSabaqTarjamaAudio == "object"
            ? "updated"
            : "not updated",
        //  arabicAudio:typeof(ele.arabicAudio)=="string" ? "not updated" : "updated",
        subSabaqTitleTeacherAudio:
        typeof ele.titleTeacherAudioPath == "object" ? "updated" : "not updated",
        subSabaqDescTeacherAudio:
        typeof ele.teacherDescAudioPath == "object" ? "updated" : "not updated",
        subSabaqTarjamaTeacherAudio:
        typeof ele.teacherTarjamaAudioPath == "object"
          ? "updated"
          : "not updated",
      };
    });
    idEdit === "edit"
      ? payLoadFormData.append("subSabaq", JSON.stringify(subSabaqEdit))
      : payLoadFormData.append("subSabaq", JSON.stringify(subSabaq));

    let newSubaqAudio = [];
    newSubaqAudio.push(addNamaz.sabaqNameAudio);
    newSubaqAudio.map((ele) => payLoadFormData.append("sabaqNameAudio", ele ? ele : ""));

    let newSubaqTeacherAudio = [];
    newSubaqTeacherAudio.push(addNamaz.sabaqTeacherAudio);
    newSubaqTeacherAudio.map((ele) => payLoadFormData.append("sabaqNameTeacherAudio", ele ? ele : ""));

    let newSubaqTeacherDescAudio = [];
    newSubaqTeacherDescAudio.push(addNamaz.sabaqTeacherDesc);
    newSubaqTeacherDescAudio.map((ele) => payLoadFormData.append("sabaqDescTeacherAudio", ele ? ele : ""));
    //
    let newSubaqAudio2 = [];
    newSubaqAudio2.push(addNamaz.sabaqDescAudio);
    newSubaqAudio2.map((ele) => payLoadFormData.append("sabaqDescAudio", ele ? ele : ""));

    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqDescAudio", ele.subSabaqDescAudio ? ele.subSabaqDescAudio : "")
    );
    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqTarjamaAudio", ele.subSabaqTarjamaAudio ? ele.subSabaqTarjamaAudio : "")
    );
    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqTitleAudio", ele.subSabaqTitleAudio ? ele.subSabaqTitleAudio : "")
    );

    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqTitleTeacherAudio", ele.titleTeacherAudioPath ? ele.titleTeacherAudioPath : "")
    );
    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqDescTeacherAudio", ele.teacherDescAudioPath ? ele.teacherDescAudioPath : "")
    );
    addNamaz.subSabaq.map((ele) =>
      payLoadFormData.append("subSabaqTarjamaTeacherAudio", ele.teacherTarjamaAudioPath ? ele.teacherTarjamaAudioPath : "")
    );
    // addNamaz.subSabaq.map((ele) =>
    //   payLoadFormData.append(
    //     "arabicAudio",
    //     ele.arabicAudio
    //   )
    // );

    // setIsLoading(true);
    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_SABAQ_SECTION,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            namazListApi();
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : multipartPostCallWithErrorResponse(
        ApiConfig.ADD_SABAQ_SECTION,
        payLoadFormData
        // payLoadFormDataNew
      )
        .then((res) => {
          if (res.json && res.json?.success === true) {
            ToastMsg("success", res.json.message);
            namazListApi();
            setSaveBotton(false);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        });
  };

  //
  useEffect(() => {
    namazListApi();
  }, [key]);
  const namazListApi = () => {
    const apiUrl = (module_id == 1 || module_id == 2)
      ? ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&courseYrId=${course_yr_id}&categoryId=${7}`
      : ApiConfig.GET_ALL_SABAQ + `moduleId=${module_id}&categoryId=${7}`;
    // setIsLoading(true);

    simpleGetCallWithErrorResponse(apiUrl)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setNamazList(res.json.data?.allSabaq);
          setLsatSabaq(res.json?.data.allSabaq.length);
          // pagination();
          // ToastMsg("success", res.json.message);
        } else {
          // ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const namazLisDeleteApi = () => {
    const body = {
      categoryId: 7,

      sabaqId: deleteId,
    };
    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.DELETE_SABAQ_SECION,

      JSON.stringify({
        ...body,
      })
    )
      .then((res) => {
        setLoader(false);
        if (res.json && res.json?.success === true) {
          // setSabaqList(res.json.data);
          namazListApi();
          handleDeleteClose();
          ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  //
  const EditListApi = (id) => {
    // setIsLoading(true);

    simpleGetCallWithErrorResponse(ApiConfig.VIEW_SABAQ_SECTION + `sabaqId=${id}&categoryId=${category_id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          // ToastMsg("success", res.json.message);
          let data = res.json?.data;
          setAddNamaz({
            SabaqId: data?.SabaqId,
            sabaqName: data?.sabaqName,
            sabaqDesc: data?.sabaqDesc,
            sabaqDescAudio: data?.sabaqDescAudio,
            sabaqIcon: data?.sabaqIcon,
            sabaqNameAudio: data?.sabaqAudio,
            sabaqDescReference: data?.sabaqDescReference,
            sabaqTeacherAudio:data?.sabaqTeacherAudio,
            sabaqTeacherDesc:data?.sabaqTeacherDesc,
            subSabaq:
              data &&
              data.subSabaqs.map((ele) => {
                return {
                  subSabaqTitle: ele.subSabaqTitle,
                  subSabaqTitleAudio: ele.titleAudioPath,
                  subSabaqDesc: ele.subSabaqDesc,
                  subSabaqDescAudio: ele.descAudioPath,
                  subSabaqTarjama: ele.tarjama,
                  subSabaqTarjamaAudio: ele.tarjamaAudioPath,
                  subSabaqDescReference: ele.descReference,
                  subSabaqId: ele.subSabaqId,
                  subSabaqNo: ele.subSabaqNo,
                  titleTeacherAudioPath:ele.titleTeacherAudioPath,
                  teacherDescAudioPath:ele.teacherDescAudioPath,
                  teacherTarjamaAudioPath:ele.teacherTarjamaAudioPath
                };
              }),
          });
          handleAddShow();
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  //view the
  const viewDetailshandle = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.VIEW_SABAQ_SECTION + `sabaqId=${id}&categoryId=${category_id}`)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.success === true) {
          setViewDetails(res.json.data);
          handleViewShow();
          // ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Namaaz</label>
        </div>
        <div className="add-btn">
          <button onClick={handleAddShow}>
            Add Namaz
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
            </svg>
          </button>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={() => handleAddClose()}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleAddClose}>
          {/* <Modal.Title>Add Namaaz</Modal.Title> */}
          <Modal.Title>
            {idEdit === "edit" ? "Update  Namaaz" : "Add Namaz"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} className="fw-bold" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={addNamaz?.sabaqName}
                  onChange={(e) =>
                    setAddNamaz({ ...addNamaz, sabaqName: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>


              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio(Teacher And Student)
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqNameAudio === "string"
                          ? addNamaz?.sabaqNameAudio.substring(
                            addNamaz?.sabaqNameAudio.length - 15,
                            addNamaz?.sabaqNameAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaqAudio }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaqAudio: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqNameAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio(Teacher And Student)</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqNameAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}

              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Audio(Teacher )
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqTeacherAudio === "string"
                          ? addNamaz?.sabaqTeacherAudio.substring(
                            addNamaz?.sabaqTeacherAudio.length - 15,
                            addNamaz?.sabaqTeacherAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqAudio"
                        style={{ width: imageWidth.sabaqAudio }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, sabaqAudio: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqTeacherAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Audio(Teacher )</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqAudio"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqTeacherAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
              {/* sabaqIcon */}
              {/*{idEdit ? (
                <div>
                  <MDBRow>
                    <MDBCol lg="1" md="1" className="mb-4">
                      <img
                        src={
                          !addNamaz?.sabaqIcon
                            ? ""
                            : ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon.length
                              ? ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon
                              : ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon &&
                              URL.createObjectURL(ApiConfig.FILE_BASE_URL + addNamaz?.sabaqIcon)

                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                  <div className="col-md-12 mb-3">
                      <Form.Label>Update Sabaq Icon</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="updateSabaqIcon"
                        onChange={(e) => {
                          setAddNamaz({
                            ...addNamaz,
                            sabaqIcon: e.target.files[0],
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Icon
                      </Form.Control.Feedback>
                    </div>
                </div>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Icon</Form.Label>
                  <Form.Control
                    // required
                    type="file"
                    // accept="audio/mp3,audio/*;capture=microphone"
                    accept="image/*"
                    name="sabaqAudio"
                    src={addNamaz?.sabaqIcon}
                    //  / value={addNamaz?.sabaqAudio}
                    onChange={(e) => {
                      setAddNamaz({
                        ...addNamaz,
                        sabaqIcon: e.target.files[0],
                      });
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Icon
                  </Form.Control.Feedback>
                </div>
              )}*/}


              <div className="col-md-12 mb-3">
                <Form.Label>Sabaq Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={addNamaz?.sabaqDesc}
                  onChange={(e) =>
                    setAddNamaz({ ...addNamaz, sabaqDesc: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Sabaq Name
                </Form.Control.Feedback>
              </div>

              {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Description Audio(Teacher And Student)
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqDescAudio === "string"
                          ? addNamaz?.sabaqDescAudio.substring(
                            addNamaz?.sabaqDescAudio.length - 15,
                            addNamaz?.sabaqDescAudio.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqDescAudio"
                        style={{ width: imageWidth.Uploade }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, Uploade: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqDescAudio: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description Audio(Teacher And Student)</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqImagePath"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqDescAudio: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}
                 {idEdit ? (
                <>
                  <div className="row d-flex">
                    <div className="col-md-12">
                      <label htmlFor="" className="key">
                        Sabaq Description Audio(Teacher)
                      </label>
                    </div>
                    <div
                      className={imageWidth === "100%" ? "d-none" : "col-md-6 "}
                    >
                      <Form.Label>
                        {typeof addNamaz?.sabaqTeacherDesc === "string"
                          ? addNamaz?.sabaqTeacherDesc.substring(
                            addNamaz?.sabaqTeacherDesc.length - 15,
                            addNamaz?.sabaqTeacherDesc.length
                          )
                          : ""}
                      </Form.Label>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <Form.Control
                        type="file"
                        accept="audio/mp3,audio/*;capture=microphone"
                        name="sabaqDescAudio"
                        style={{ width: imageWidth.Uploade }}
                        onChange={(e) => {
                          setImageWidth({ ...imageWidth, Uploade: "100%" });
                          setAddNamaz({
                            ...addNamaz,
                            sabaqTeacherDesc: e.target.files[0],
                          });
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-3">
                  <Form.Label>Sabaq Description Audio(Teacher)</Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/mp3,audio/*;capture=microphone"
                    name="sabaqImagePath"
                    //  / value={addSabaq?.sabaqAudio}
                    onChange={(e) =>
                      setAddNamaz({
                        ...addNamaz,
                        sabaqTeacherDesc: e.target.files[0],
                      })
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>
              )}

              <div className="col-md-12 mb-3">
                <Form.Label> Sabaq Reference</Form.Label>
                <Form.Control
                  type="text"
                  value={addNamaz?.sabaqDescReference}
                  onChange={(e) =>
                    setAddNamaz({
                      ...addNamaz,
                      sabaqDescReference: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Reference
                </Form.Control.Feedback>
              </div>

              {addNamaz.subSabaq &&
                addNamaz.subSabaq.map((elementInArray, index) => {
                  return (
                    <div
                      className="col-md-12 sub-group-main"
                      key={"string" + index}
                    >
                      <div className="row">
                        <h6 className="fw-bold">Sub Sabaq Details</h6>
                        <div className="col-md-12 mb-3">
                          <Form.Label>
                            Sub Sabaq Title <span>{index + 1}</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.subSabaqTitle}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqTitle: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Title Audio(Teacher And Student )
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.subSabaqTitleAudio ===
                                    "string"
                                    ? elementInArray?.subSabaqTitleAudio.substring(
                                      elementInArray?.subSabaqTitleAudio
                                        .length - 15,
                                      elementInArray?.subSabaqTitleAudio
                                        .length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.subSabaqTitle }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subSabaqTitle: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subSabaqTitleAudio:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq Title Audio(Teacher And Student )</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          subSabaqTitleAudio:
                                            e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}

{idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Title Audio(Teacher )
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.titleTeacherAudioPath ===
                                    "string"
                                    ? elementInArray?.titleTeacherAudioPath.substring(
                                      elementInArray?.titleTeacherAudioPath
                                        .length - 15,
                                      elementInArray?.titleTeacherAudioPath
                                        .length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.subSabaqTitle }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      subSabaqTitle: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              titleTeacherAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq Title Audio(Teacher )</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          titleTeacherAudioPath:
                                            e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label> Sub Sabaq Reference</Form.Label>
                          <Form.Control
                            type="text"
                            value={elementInArray?.subSabaqDescReference}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqDescReference: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Reference
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Tarjama</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={elementInArray?.subSabaqTarjama}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqTarjama: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Tarjama.
                          </Form.Control.Feedback>
                        </div>

                        {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Tarjama Audio(Teacher And Student)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.subSabaqTarjamaAudio ===
                                    "string"
                                    ? elementInArray?.subSabaqTarjamaAudio.substring(
                                      elementInArray?.subSabaqTarjamaAudio
                                        .length - 15,
                                      elementInArray?.subSabaqTarjamaAudio
                                        .length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.tarjamaAudio }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      tarjamaAudio: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              subSabaqTarjamaAudio:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq Tarjama Audio(Teacher And Student)</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          subSabaqTarjamaAudio:
                                            e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                                 {idEdit ? (
                          <>
                            <div className="row d-flex">
                              <div className="col-md-12">
                                <label htmlFor="" className="key">
                                  Sub Sabaq Tarjama Audio(Teacher)
                                </label>
                              </div>
                              <div
                                className={
                                  imageWidth === "100%" ? "d-none" : "col-md-6 "
                                }
                              >
                                <Form.Label>
                                  {typeof elementInArray?.teacherTarjamaAudioPath ===
                                    "string"
                                    ? elementInArray?.teacherTarjamaAudioPath.substring(
                                      elementInArray?.teacherTarjamaAudioPath
                                        .length - 15,
                                      elementInArray?.teacherTarjamaAudioPath
                                        .length
                                    )
                                    : ""}
                                </Form.Label>
                              </div>
                              <div className="col-md-12 mb-3 ">
                                <Form.Control
                                  type="file"
                                  accept="audio/mp3,audio/*;capture=microphone"
                                  name="subSabaqTitleAudio"
                                  style={{ width: imageWidth.tarjamaAudio }}
                                  onChange={(e) => {
                                    setImageWidth({
                                      ...imageWidth,
                                      tarjamaAudio: "100%",
                                    });
                                    setAddNamaz({
                                      ...addNamaz,
                                      subSabaq: addNamaz.subSabaq.map(
                                        (item, innerIndex) => {
                                          return index === innerIndex
                                            ? {
                                              ...item,
                                              teacherTarjamaAudioPath:
                                                e.target.files[0],
                                            }
                                            : item;
                                        }
                                      ),
                                    });
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Sabaq Name
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-3">
                            <Form.Label> Sub Sabaq Tarjama Audio(Teacher)</Form.Label>
                            <Form.Control
                              type="file"
                              accept="audio/mp3,audio/*;capture=microphone"
                              name="sabaqImagePath"
                              //  / value={addSabaq?.sabaqAudio}
                              onChange={(e) => {
                                setAddNamaz({
                                  ...addNamaz,
                                  subSabaq: addNamaz.subSabaq.map(
                                    (item, innerIndex) => {
                                      return index === innerIndex
                                        ? {
                                          ...item,
                                          teacherTarjamaAudioPath:
                                            e.target.files[0],
                                        }
                                        : item;
                                    }
                                  ),
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Sabaq Name
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <Form.Label>Sub Sabaq Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={elementInArray?.subSabaqDesc}
                            onChange={(e) => {
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: addNamaz.subSabaq.map(
                                  (item, innerIndex) => {
                                    return index === innerIndex
                                      ? {
                                        ...item,
                                        subSabaqDesc: e.target.value,
                                      }
                                      : item;
                                  }
                                ),
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Aqaaid Description.
                          </Form.Control.Feedback>
                        </div>
                        <>
                          {idEdit ? (
                            <>
                              <div className="row d-flex">
                                <div className="col-md-12">
                                  <label htmlFor="" className="key">
                                    Sub Sabaq Description Audio(Teacher And Student)
                                  </label>
                                </div>
                                <div
                                  className={
                                    imageWidth === "100%"
                                      ? "d-none"
                                      : "col-md-6 "
                                  }
                                >
                                  <Form.Label>
                                    {typeof elementInArray?.subSabaqDescAudio ===
                                      "string"
                                      ? elementInArray?.subSabaqDescAudio.substring(
                                        elementInArray?.subSabaqDescAudio
                                          .length - 15,
                                        elementInArray?.subSabaqDescAudio
                                          .length
                                      )
                                      : ""}
                                  </Form.Label>
                                </div>
                                <div className="col-md-12 mb-3 ">
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    name="sabaqImagePath"
                                    style={{ width: imageWidth.Description }}
                                    onChange={(e) => {
                                      setImageWidth({
                                        ...imageWidth,
                                        Description: "100%",
                                      });
                                      setAddNamaz({
                                        ...addNamaz,
                                        subSabaq: addNamaz.subSabaq.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                subSabaqDescAudio:
                                                  e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12 mb-3">
                              <Form.Label>
                                Sub Sabaq Description Audio(Teacher And Student)
                              </Form.Label>
                              <Form.Control
                                type="file"
                                accept="audio/mp3,audio/*;capture=microphone"
                                name="sabaqImagePath"
                                //  / value={addSabaq?.sabaqAudio}
                                onChange={(e) => {
                                  setAddNamaz({
                                    ...addNamaz,
                                    subSabaq: addNamaz.subSabaq.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                            ...item,
                                            subSabaqDescAudio:
                                              e.target.files[0],
                                          }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          )}

                          {idEdit ? (
                            <>
                              <div className="row d-flex">
                                <div className="col-md-12">
                                  <label htmlFor="" className="key">
                                    Sub Sabaq Description Audio(Teacher)
                                  </label>
                                </div>
                                <div
                                  className={
                                    imageWidth === "100%"
                                      ? "d-none"
                                      : "col-md-6 "
                                  }
                                >
                                  <Form.Label>
                                    {typeof elementInArray?.teacherDescAudioPath ===
                                      "string"
                                      ? elementInArray?.teacherDescAudioPath.substring(
                                        elementInArray?.teacherDescAudioPath
                                          .length - 15,
                                        elementInArray?.teacherDescAudioPath
                                          .length
                                      )
                                      : ""}
                                  </Form.Label>
                                </div>
                                <div className="col-md-12 mb-3 ">
                                  <Form.Control
                                    type="file"
                                    accept="audio/mp3,audio/*;capture=microphone"
                                    name="sabaqImagePath"
                                    style={{ width: imageWidth.Description }}
                                    onChange={(e) => {
                                      setImageWidth({
                                        ...imageWidth,
                                        Description: "100%",
                                      });
                                      setAddNamaz({
                                        ...addNamaz,
                                        subSabaq: addNamaz.subSabaq.map(
                                          (item, innerIndex) => {
                                            return index === innerIndex
                                              ? {
                                                ...item,
                                                teacherDescAudioPath:
                                                  e.target.files[0],
                                              }
                                              : item;
                                          }
                                        ),
                                      });
                                    }}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Sabaq Name
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-12 mb-3">
                              <Form.Label>
                                Sub Sabaq Description Audio(Teacher)
                              </Form.Label>
                              <Form.Control
                                type="file"
                                accept="audio/mp3,audio/*;capture=microphone"
                                name="sabaqImagePath"
                                //  / value={addSabaq?.sabaqAudio}
                                onChange={(e) => {
                                  setAddNamaz({
                                    ...addNamaz,
                                    subSabaq: addNamaz.subSabaq.map(
                                      (item, innerIndex) => {
                                        return index === innerIndex
                                          ? {
                                            ...item,
                                            teacherDescAudioPath:
                                              e.target.files[0],
                                          }
                                          : item;
                                      }
                                    ),
                                  });
                                }}
                              />

                              <Form.Control.Feedback type="invalid">
                                Please Enter Sabaq Name
                              </Form.Control.Feedback>
                            </div>
                          )}
                        </>
                      </div>
                      <div className="text-end">
                        <Link
                          to="#"
                          // className="btn btn-danger" onClick={() => setAddNewGroup(AddNewGroup - 1)}
                          className={
                            addNamaz.subSabaq.length > 1
                              ? "btn btn-danger"
                              : "btn btn-secondary invisible"
                          }
                          onClick={() => {
                            if (addNamaz.subSabaq.length > 1) {
                              // addNamaz.subSabaq.splice(index, 1);
                              const copySubDeatails = [...addNamaz.subSabaq];
                              copySubDeatails.splice(index, 1);
                              setAddNamaz({
                                ...addNamaz,
                                subSabaq: copySubDeatails,
                              });
                            }


                          }}
                        >
                          Remove
                        </Link>
                      </div>
                    </div>
                  );
                })}

              <Link
                to="#"
                className={
                  // idEdit
                  //   ? "add-newSabaq-btn invisible btn btn-secondary pe-none"
                  //   :
                  "add-newSabaq-btn btn btn-primary"
                }
                // onClick={() => setAddNewGroup(AddNewGroup + 1)}
                onClick={() => {
                  let namaz = [
                    ...addNamaz.subSabaq,

                    {
                      subSabaqTitle: "",
                      subSabaqTitleAudio: "",
                      subSabaqDesc: "",
                      subSabaqDescAudio: "",
                      titleTeacherAudioPath:"",
                      teacherDescAudioPath:"",
                      teacherTarjamaAudioPath:"",
                      arabic: "",
                      arabicAudio: "",
                      subSabaqTarjama: "",
                      subSabaqTarjamaAudio: "",
                      subSabaqDescReference: "",
                    },
                  ];
                  setAddNamaz({
                    ...addNamaz,
                    subSabaq: namaz,
                  });
                }}
              >
                + Add Sub Sabaq Details
              </Link>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit" disabled={saveBotton}>
                {idEdit === "edit" ? "update" : "Save"}
              </button>
              <Link
                to="#"
                className=" bg-danger"
                onClick={() => {
                  setAddNamaz({
                    sabaqName: "",
                    sabaqDesc: "",
                    sabaqDescAudio: "",
                    sabaqNameAudio: "",
                    inArbic: "",
                    inArbicAudio: "",
                    subSabaqDescReference: "",
                    tarjama: "",
                    tarjamaAudio: "",
                    sabaqDesc: "",
                    sabaqDescAudio: "",
                    subSabaq: [
                      {
                        subSabaqTitle: "",
                        subSabaqTitleAudio: "",
                        subSabaqDesc: "",
                        subSabaqDescAudio: "",
                        arabic: "",
                        arabicAudio: "",
                        subSabaqTarjama: "",
                        subSabaqTarjamaAudio: "",
                        subSabaqDescReference: "",
                      },
                    ],
                  });
                  handleAddClose();
                  setIdEdit("");
                }}
              >
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => {
                handleDeleteClose()
                namazLisDeleteApi()
              }}

            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Namaaz Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq No.
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.SabaqNo}
                  </label>
                </div>
              </div>

              {/* sabaqIcon */}
              <div className="col-md-6 mb-3">
                {/* <div>
                  <label htmlFor="" className="key">
                    Sabaq Icon
                  </label>
                </div>
                <div>
                  <MDBRow>
                    <MDBCol lg="2" md="3" className="mb-4">
                      <img
                        src={
                          !viewDetails?.sabaqIcon
                            ? ""
                            : ApiConfig.FILE_BASE_URL +
                              viewDetails?.sabaqIcon.length
                            ? ApiConfig.FILE_BASE_URL + viewDetails?.sabaqIcon
                            : ApiConfig.FILE_BASE_URL +
                                viewDetails?.sabaqIcon &&
                              URL.createObjectURL(
                                ApiConfig.FILE_BASE_URL + viewDetails?.sabaqIcon
                              )
                        }
                        className="img-fluid rounded value"
                        alt=""
                      />
                    </MDBCol>
                  </MDBRow>
                </div> */}
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq Name
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqName}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio(Teacher And Student)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqAudio ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`} type="audio/ogg" />
                      <source src={`${FILE_BASE_URL}${viewDetails?.sabaqAudio}`} type="audio/mpeg" />
                    </audio>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <div className="col-md-6 mb-3"></div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq Audio(Teacher)
                  </label>
                </div>
                <div>
                  {viewDetails?.sabaqTeacherAudio ? (
                    <audio controls style={{ width: "100%", height: "38px" }}>
                      <source src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherAudio}`} type="audio/ogg" />
                      <source src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherAudio}`} type="audio/mpeg" />
                    </audio>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq  Description
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqDesc}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq  Description Audio(Teacher And Student)
                  </label>
                </div>
                <div>
                  {
                    viewDetails?.sabaqDescAudio ? (
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqDescAudio}`}
                          type="audio/ogg"
                        />
                        <source
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqDescAudio}`}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      ""

                    )
                  }

                </div>
              </div>
              <div className="col-md-6 mb-3"></div>
              <div className="col-md-6 mb-3">
                <div className="mb-2">
                  <label htmlFor="" className="key">
                    Sabaq  Description Audio(Teacher And Student)
                  </label>
                </div>
                <div>
                  {
                    viewDetails?.sabaqTeacherDesc ? (
                      <audio controls style={{ width: "100%", height: "38px" }}>
                        <source
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherDesc}`}
                          type="audio/ogg"
                        />
                        <source
                          src={`${FILE_BASE_URL}${viewDetails?.sabaqTeacherDesc}`}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      ""

                    )
                  }

                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Sabaq  Reference
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {viewDetails?.sabaqDescReference}
                  </label>
                </div>
              </div>
              <h5 className="fw-bold">Sub Sabaq Details</h5>

              {viewDetails &&
                viewDetails.subSabaqs &&
                viewDetails.subSabaqs.map((ele, index) => {
                  return (
                    <div className="sub-group-main row" key={"keyle" + index}>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq  Title {ele.subSabaqNo}
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele.subSabaqTitle}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Title Audio(Teacher And Student)
                          </label>
                        </div>
                        <div>
                          {ele.titleAudioPath ? (
                            <audio controls style={{ width: "100%", height: "38px" }}>
                              <source src={`${FILE_BASE_URL}${ele.titleAudioPath}`} type="audio/ogg" />
                              <source src={`${FILE_BASE_URL}${ele.titleAudioPath}`} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Title Audio(Teacher)
                          </label>
                        </div>
                        <div>
                          {ele.titleTeacherAudioPath ? (
                            <audio controls style={{ width: "100%", height: "38px" }}>
                              <source src={`${FILE_BASE_URL}${ele.titleTeacherAudioPath}`} type="audio/ogg" />
                              <source src={`${FILE_BASE_URL}${ele.titleTeacherAudioPath}`} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value" style={{ maxWidth: "100%", overflowY: "hidden" }}>
                            {ele?.tarjama}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama Audio(Teacher And Student)
                          </label>
                        </div>
                        <div>
                          {ele?.tarjamaAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${ele?.tarjamaAudioPath}`}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${ele?.tarjamaAudioPath}`}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            ""
                          )}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Tarjama Audio(Teacher)
                          </label>
                        </div>
                        <div>
                          {ele?.teacherTarjamaAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source
                                src={`${FILE_BASE_URL}${ele?.teacherTarjamaAudioPath}`}
                                type="audio/ogg"
                              />
                              <source
                                src={`${FILE_BASE_URL}${ele?.teacherTarjamaAudioPath}`}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                          ) : (
                            ""
                          )}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Description
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele?.subSabaqDesc}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Description Audio(Teacher And Student)
                          </label>
                        </div>
                        <div>
                          {ele?.descAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source src={`${FILE_BASE_URL}${ele.descAudioPath}`} type="audio/ogg" />
                              <source src={`${FILE_BASE_URL}${ele.descAudioPath}`} type="audio/mpeg" />
                            </audio>
                          ) : (
                            ""
                          )}

                        </div>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-2">
                          <label htmlFor="" className="key">
                            Sub Sabaq Description Audio(Teacher)
                          </label>
                        </div>
                        <div>
                          {ele?.teacherDescAudioPath ? (
                            <audio
                              controls
                              style={{ width: "100%", height: "38px" }}
                            >
                              <source src={`${FILE_BASE_URL}${ele.teacherDescAudioPath}`} type="audio/ogg" />
                              <source src={`${FILE_BASE_URL}${ele.teacherDescAudioPath}`} type="audio/mpeg" />
                            </audio>
                          ) : (
                            ""
                          )}

                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div>
                          <label htmlFor="" className="key">
                            Sub Sabaq Reference
                          </label>
                        </div>
                        <div>
                          <label htmlFor="" className="value">
                            {ele?.descReference}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}
      {loader ? (
        <FormLoader />
      ) : (
        <div className="table-wrapper">
          <h6>Total Records : {namazList.length}</h6>
          <Table className="display" striped bordered hover size="lg">
            <thead className="text-center">
              <tr>
                <th>Sabaq No.</th>
                <th
                // style={{ textAlign: "left" }}
                >Sabaq List</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {namazList && namazList.length > 0
                ? namazList.slice(startIndex, endIndex).map((ele, index) => {
                  return (
                    <tr key={"newsr" + index}>
                      <td>{index + 1 + startIndex}</td>

                      <td style={{ textAlign: "left" }}>{ele.sabaqName}</td>
                      <td className="action">
                        <Link
                          to="#"
                          className="icon edit"
                          onClick={() => {
                            // handleAddShow();
                            setIdEdit("edit");
                            setImageWidth({
                              ...imageWidth,
                              sabaqAudio: "117px",
                              Uploade: "117px",
                              tarjamaAudio: "117px",
                              Description: "117px",
                              subSabaqTitle: "117px",
                              arabicAudio: "117px",
                            });
                            EditListApi(ele.sabaqId);
                          }}
                        >
                          <img src={ic_edit} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon view"
                          onClick={() => viewDetailshandle(ele.sabaqId)}
                        >
                          <img src={ic_eyes} alt="" />
                        </Link>
                        <Link
                          to="#"
                          className="icon delete"
                          onClick={() => {
                            handleDeleteShow();
                            setDeleteId(ele.sabaqId);
                          }}
                        >
                          <img src={ic_delete} alt="" />
                        </Link>
                      </td>
                    </tr>
                  );
                })
                : ""}
            </tbody>
          </Table>
          {namazList && namazList.length > 0 ? (
            <Pagination
              changePage={changePage}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          ) : (
            <div>
              <h5 style={{ color: "red", textAlign: "center" }}>
                {" "}
                Records Not Found
              </h5>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Namaaz;
