import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import ic_sidemenu_toggle from "../assets/images/ic_sidemenu_toggle.svg";
import { useMediaQuery } from "react-responsive";
import Deeniyat_logo from "../assets/images/logo_big.png";
import deeniyatAppicon from "../assets/images/logo_small.png";

import ic_dashboard from "../assets/images/ic_dashboard.svg";

import Aqaaid from "../assets/images/Aqaaid.svg";
import Aqaaid_active from "../assets/images/Aqaaid_active.svg";

import Seerat from "../assets/images/Seerat.svg";
import Ramzan from "../assets/images/Ramzan.svg";
import Ramzan_Active from "../assets/images/Ramzan_Active.svg";
import Seerat_active from "../assets/images/Seerat_active.svg";
import Noraani_Qaaidah from "../assets/images/Noraani_Qaaidah.svg";
import Noraani_Qaaidah_active from "../assets/images/Noraani_Qaaidah_active.svg";
import ic_setting from "../assets/images/ic_setting.svg";
import ic_setting_active from "../assets/images/ic_setting_active.svg";
import ic_ds60 from "../assets/images/ds60.png";

import Dua_Sunnat from "../assets/images/Dua_Sunnat.svg";
import Dua_Sunnat_active from "../assets/images/Dua_Sunnat_active.svg";
import Hifz_e_Surah from "../assets/images/Hifz_e_Surah.svg";
import Hifz_e_Surah_active from "../assets/images/Hifz_e_Surah_active.svg";
import Namaaz from "../assets/images/Namaaz.svg";
import Namaaz_active from "../assets/images/Namaaz_active.svg";
import Masaail from "../assets/images/Masaail.svg";
import Masaail_active from "../assets/images/Masaail_active.svg";
import Umrah_Hajj from "../assets/images/Umrah_Hajj.svg";
import Umrah_Hajj_active from "../assets/images/Umrah_Hajj_active.svg";

import { AppContext } from "../context/AppContext";
import { simpleGetCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "../pages/TostMsg";

const Sidebar = () => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  let module_id;

  if (typeof window !== undefined) {
    module_id = localStorage.getItem("module_id");
  }

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    return () => {};
  }, [isMobile]);

  const { sidebar, setSidebar, Dark } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const [setting, setsetting] = useState(false);
  const [ds60, setDs60] = useState(false);
  const [ds40, setDs40] = useState(false);
  const [shortCourse, setshortCourse] = useState(false);

  return (
    <main className="cx-sidebar">
      <div
        className={
          sidebar ? "cx-sidebar-wrapper " : "cx-sidebar-wrapper sidebarActive"
        }
      >
        <aside>
          <div className="logo-main">
            {sidebar ? (
              <div className="big">
                <img
                  src={Deeniyat_logo}
                  alt=""
                  onClick={() => navigate("/Dashboard")}
                />
              </div>
            ) : (
              <div className="small">
                <img
                  src={deeniyatAppicon}
                  alt=""
                  onClick={() => navigate("/Dashboard")}
                />
              </div>
            )}
          </div>

          <div className="left-arrow"></div>

          <div className="menus-main">
            <div className="menus-items" id="arrow-double">
              <div
                className={sidebar ? "text-end " : "doubleArrowActive"}
                onClick={handleSidebar}
                id="arrow-inner"
              >
                <img src={ic_sidemenu_toggle} alt="" />
              </div>
            </div>

            <div
              className={
                currentRoute === "/Dashboard"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Dashboard" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/dashboard" className="me-2">
                      {currentRoute === "/Dashboard" ? (
                        <img src={ic_dashboard} alt="" />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#707070"
                            d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                          />
                        </svg>
                      )}
                    </Link>
                    Dashboard
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Dashboard" ? (
                      <img src={ic_dashboard} alt="" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#16173b"
                          d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            {/*     <div
              className={
                currentRoute === "/NoraaniQaaidah"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/NoraaniQaaidah" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/NoraaniQaaidah" className="me-2">
                      {currentRoute === "/NoraaniQaaidah" ? (
                        <img src={Noraani_Qaaidah_active} alt="" />
                      ) : (
                        <img src={Noraani_Qaaidah} alt="" />
                      )}
                    </Link>
                    Noraani Qaaidah
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/NoraaniQaaidah" ? (
                      <img src={Noraani_Qaaidah_active} alt="" />
                    ) : (
                      <img src={Noraani_Qaaidah} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/HifzESurah"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/HifzESurah" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/HifzESurah" className="me-2">
                      {currentRoute === "/HifzESurah" ? (
                        <img src={Hifz_e_Surah_active} alt="" />
                      ) : (
                        <img src={Hifz_e_Surah} alt="" />
                      )}
                    </Link>
                    Hifz-e-Surah
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/HifzESurah" ? (
                      <img src={Hifz_e_Surah_active} alt="" />
                    ) : (
                      <img src={Hifz_e_Surah} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/DuaSunnat"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/DuaSunnat" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/DuaSunnat" className="me-2">
                      {currentRoute === "/DuaSunnat" ? (
                        <img src={Dua_Sunnat_active} alt="" />
                      ) : (
                        <img src={Dua_Sunnat} alt="" />
                      )}
                    </Link>
                    Dua Sunnat
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/DuaSunnat" ? (
                      <img src={Dua_Sunnat_active} alt="" />
                    ) : (
                      <img src={Dua_Sunnat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Aqaaid"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Aqaaid" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Aqaaid" className="me-2">
                      {currentRoute === "/Aqaaid" ? (
                        <img src={Aqaaid_active} alt="" />
                      ) : (
                        <img src={Aqaaid} alt="" />
                      )}
                    </Link>
                    Aqaaid
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Aqaaid" ? (
                      <img src={Dua_Sunnat_active} alt="" />
                    ) : (
                      <img src={Dua_Sunnat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Masaail"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Masaail" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Masaail" className="me-2">
                      {currentRoute === "/Masaail" ? (
                        <img src={Masaail_active} alt="" />
                      ) : (
                        <img src={Masaail} alt="" />
                      )}
                    </Link>
                    Masaail
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Masaail" ? (
                      <img src={Masaail_active} alt="" />
                    ) : (
                      <img src={Masaail} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/UmrahHajj"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/UmrahHajj" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/UmrahHajj" className="me-2">
                      {currentRoute === "/UmrahHajj" ? (
                        <img src={Umrah_Hajj_active} alt="" />
                      ) : (
                        <img src={Umrah_Hajj} alt="" />
                      )}
                    </Link>
                    Umrah & Hajj
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/UmrahHajj" ? (
                      <img src={Umrah_Hajj_active} alt="" />
                    ) : (
                      <img src={Umrah_Hajj} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Namaaz"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Namaaz" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Namaaz" className="me-2">
                      {currentRoute === "/Namaaz" ? (
                        <img src={Namaaz_active} alt="" />
                      ) : (
                        <img src={Namaaz} alt="" />
                      )}
                    </Link>
                    Namaaz
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Namaaz" ? (
                      <img src={Namaaz_active} alt="" />
                    ) : (
                      <img src={Namaaz} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Seerat"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Seerat" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Seerat" className="me-2">
                      {currentRoute === "/Seerat" ? (
                        <img src={Seerat_active} alt="" />
                      ) : (
                        <img src={Seerat} alt="" />
                      )}
                    </Link>
                    Seerat
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Seerat" ? (
                      <img src={Seerat_active} alt="" />
                    ) : (
                      <img src={Seerat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Ramzan"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Ramzan" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Ramzan" className="me-2">
                      {currentRoute === "/Ramzan" ? (
                        <img src={Ramzan_Active} alt="" />
                      ) : (
                        <img src={Ramzan} alt="" />
                      )}
                    </Link>
                    Ramzan
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Ramzan" ? (
                      <img src={Ramzan_Active} alt="" />
                    ) : (
                      <img src={Ramzan} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div> */}

            <div
              className={
                (currentRoute === "/FirstYear" || 
                currentRoute === "/SecondYear" || 
                currentRoute === "/ThirdYear" || 
                currentRoute === "/FourthYear" || 
                currentRoute === "/FifthYear" || 
                currentRoute === "/") && module_id == 1
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setDs60(!ds60)}
              >
                {sidebar ? (
                  <div
                    className={"menu-name"}
                    onClick={() => {
                      localStorage.setItem("module_id", 1);
                    }}
                  >
                    <Link to="#" className="me-2">
                      {currentRoute === "/FirstYear" || currentRoute === "/" ? (
                        <img src={ic_ds60} alt="" />
                      ) : (
                        <img src={ic_ds60} alt="" />
                      )}
                    </Link>
                    Deeniyat Syllabus <br />
                    <span className="sub_title">(Daily 60 Minutes)</span>
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/FirstYear" || currentRoute === "/" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>
            {(sidebar && ds60) == true ? (
              <div className="sub-menu-main">
                <Link to="/FirstYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 1);
                      localStorage.setItem("course_year_module_one", "First");
                      localStorage.setItem("module_id", 1);
                    }}
                    className={
                      (currentRoute === "/FirstYear") && module_id == 1
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    1st Year
                  </div>
                </Link>
                <Link to="/SecondYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 2);
                      localStorage.setItem("course_year_module_one", "Second");
                      localStorage.setItem("module_id", 1);
                    }}
                    className={
                      (currentRoute === "/SecondYear") && module_id == 1
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    2nd Year
                  </div>
                </Link>
                <Link to="/ThirdYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 3);
                      localStorage.setItem("course_year_module_one", "Third");
                      localStorage.setItem("module_id", 1);
                    }}
                    className={
                      (currentRoute === "/ThirdYear") && module_id == 1
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    3rd Year
                  </div>
                </Link>
                <Link to="/FourthYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 4);
                      localStorage.setItem("course_year_module_one", "Fourth");
                      localStorage.setItem("module_id", 1);
                    }}
                    className={
                      (currentRoute === "/FourthYear") && module_id == 1
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    4th Year
                  </div>
                </Link>
                <Link to="/FifthYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 5);
                      localStorage.setItem("course_year_module_one", "Fifth");
                      localStorage.setItem("module_id", 1);
                    }}
                    className={
                      (currentRoute === "/FifthYear") && module_id == 1
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    5th Year
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                (currentRoute === "/FirstYear" || 
                currentRoute === "/SecondYear" || 
                currentRoute === "/ThirdYear" || 
                currentRoute === "/FourthYear" || 
                currentRoute === "/FifthYear" || 
                currentRoute === "/SixthYear" ||
                currentRoute === "/") &&
                module_id == 2
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setDs40(!ds40)}
              >
                {sidebar ? (
                  <div
                    className={"menu-name"}
                    onClick={() => {
                      localStorage.setItem("module_id", 2);
                    }}
                  >
                    <Link to="#" className="me-2">
                      {currentRoute === "/FirstYear" || currentRoute === "/" ? (
                        <img src={ic_ds60} alt="" />
                      ) : (
                        <img src={ic_ds60} alt="" />
                      )}
                    </Link>
                    Deeniyat Syllabus <br />
                    <span className="sub_title">(Daily 40 Minutes)</span>
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/FirstYear" || currentRoute === "/" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>
            {(sidebar && ds40) == true ? (
              <div className="sub-menu-main">
                <Link to="/FirstYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 6);
                      localStorage.setItem("module_id", 2);
                    }}
                    className={
                      (currentRoute === "/FirstYear") && module_id == 2
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    1st Year
                  </div>
                </Link>
                <Link to="/SecondYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 7);
                      localStorage.setItem("module_id", 2);
                    }}
                    className={
                      (currentRoute === "/SecondYear") && module_id == 2
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    2nd Year
                  </div>
                </Link>
                <Link to="/ThirdYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 8);
                      localStorage.setItem("module_id", 2);
                    }}
                    className={
                      (currentRoute === "/ThirdYear") && module_id == 2
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    3rd Year
                  </div>
                </Link>
                <Link to="/FourthYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 9);
                      localStorage.setItem("module_id", 2);
                    }}
                    className={
                      (currentRoute === "/FourthYear") && module_id == 2
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    4th Year
                  </div>
                </Link>
                <Link to="/FifthYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 10);
                      localStorage.setItem("module_id", 2);
                    }}
                    className={
                      (currentRoute === "/FifthYear") && module_id == 2
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    5th Year
                  </div>
                </Link>
                <Link to="/SixthYear" className="sub-menu-item">
                  <div
                    onClick={() => {
                      localStorage.setItem("course_yr_id", 11);
                      localStorage.setItem("module_id", 2);
                    }}
                    className={
                      (currentRoute === "/SixthYear") && module_id == 2
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    6th Year
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                currentRoute === "/ShortCourse"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="/ShortCourse"
                className="icon-menu-name"
                // onClick={() => setDs60(!ds60)}
              >
                {sidebar ? (
                  <div
                    className={"menu-name"}
                    onClick={() => {
                      localStorage.setItem("module_id", 3);
                      localStorage.setItem("course_yr_id", "")
                    }}
                  >
                    <Link to="#" className="me-2">
                      {currentRoute === "/ShortCourse" ? (
                        <img src={ic_ds60} alt="" />
                      ) : (
                        <img src={ic_ds60} alt="" />
                      )}
                    </Link>
                    Short Course <br />
                    <span className="sub_title">(For All)</span>
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/ShortCourse" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/QKEleven" ||
                currentRoute === "/QKThirteen" ||
                currentRoute === "/QKFifteen" ||
                currentRoute === "/Surah_page" ||
                currentRoute === "/Reciter"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setshortCourse(!shortCourse)}
              >
                {sidebar ? (
                  <div
                    className={"menu-name"}
                    onClick={() => {
                      localStorage.setItem("module_id", 4);
                    }}
                  >
                    <Link to="#" className="me-2">
                      {currentRoute === "/FirstYear" ? (
                        <img src={ic_ds60} alt="" />
                      ) : (
                        <img src={ic_ds60} alt="" />
                      )}
                    </Link>
                    Quraan-e-Kareem
                    <br />
                    <span className="sub_title">(11, 13, 15 Line)</span>
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/FirstYear" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>
            {(sidebar && shortCourse) == true ? (
              <div className="sub-menu-main">
                <Link to="/Reciter" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Reciter"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    {currentRoute === "/Reciter" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                    Reciter
                    <br />
                  </div>
                </Link>

                <Link to="/Surah" className="sub-menu-item">
                  <div
                    onClick={() => localStorage.setItem("textType", "11")}
                    className={
                      currentRoute === "/Surah" 
                      // ||
                      // currentRoute === "/Surah_page"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    {currentRoute === "/Surah" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                    Surah <br />
                    {/* <span className="side_sub_title">(11 Line)</span> */}
                  </div>
                </Link>

                <Link to="/QKEleven" className="sub-menu-item">
                  <div
                    onClick={() => localStorage.setItem("category_id", "1")}
                    className={
                      currentRoute === "/QKEleven" 
                      // ||
                      // currentRoute === "/Surah_page"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    {currentRoute === "/QKEleven" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                    Quraan-e-Kareem <br />
                    <span className="side_sub_title">(11 Line)</span>
                  </div>
                </Link>


                <Link to="/QKThirteen" className="sub-menu-item">
                  <div
                    onClick={() => localStorage.setItem("category_id", "2")}
                    className={
                      currentRoute === "/QKThirteen"
                      //  ||
                      // currentRoute === "/Surah_page"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    {currentRoute === "/QKThirteen" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                    Quraan-e-Kareem <br />
                    <span className="side_sub_title">(13 Line)</span>
                  </div>
                </Link>


                <Link to="/QKFifteen" className="sub-menu-item">
                  <div
                    onClick={() => localStorage.setItem("category_id", "3")}
                    className={
                      currentRoute === "/QKFifteen"
                      //  ||
                      // currentRoute === "/Surah_page"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    {currentRoute === "/QKFifteen" ? (
                      <img src={ic_ds60} alt="" />
                    ) : (
                      <img src={ic_ds60} alt="" />
                    )}
                    Quraan-e-Kareem <br />
                    <span className="side_sub_title">(15 Line)</span>
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                currentRoute === "/GoogleAnalytics" ||
                currentRoute === "/PushNotification"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setsetting(!setting)}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="#" className="me-2">
                      {currentRoute === "/GoogleAnalytics" ||
                      currentRoute === "/PushNotification" ? (
                        <img src={ic_setting_active} alt="" />
                      ) : (
                        <img src={ic_setting} alt="" />
                      )}
                    </Link>
                    Setting
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/GoogleAnalytics" ||
                    currentRoute === "/PushNotification" ? (
                      <img src={ic_setting_active} alt="" />
                    ) : (
                      <img src={ic_setting} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>
            {(sidebar && setting) == true ? (
              <div className="sub-menu-main">
                {/* <Link to="/GoogleAnalytics" className="sub-menu-item">
                  <div className={ currentRoute === "/GoogleAnalytics" ? "sub-menu active-sub-menu" : "sub-menu"}>Google Analytics</div>
                </Link> */}

                <Link to="/PushNotification" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/PushNotification"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    Push Notification
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </aside>
      </div>
    </main>
  );
};

export default Sidebar;
